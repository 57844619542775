<script>
import { ref, computed, onMounted, inject, watch } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'commonMetafieldsHeader',
  props: {
    window: Object,
  },
  components: {
  },
  setup(props, { emit }) {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const route = useRoute(); // vue-router의 현재 route 정보 접근
 
    onMounted(async () => {
      

    });
    return {

    };
  }
}
</script>


<template>
  <div>
    
  </div>
</template>
