import { ref } from "vue";

export const countries = ref([
  { code: "GH", name: "가나" },
  { code: "GT", name: "과테말라" },
  // { code: "GE", name: "구루지아" },
  { code: "GR", name: "그리스" },
  { code: "NG", name: "나이지리아" },
  { code: "ZA", name: "남아프리카 공화국" },
  { code: "NL", name: "네덜란드" },
  { code: "NP", name: "네팔" },
  { code: "NZ", name: "뉴질랜드" },
  { code: "TW", name: "대만" },
  { code: "KR", name: "대한민국" },
  { code: "DK", name: "덴마크" },
  // { code: "DO", name: "도미니카" },
  { code: "DE", name: "독일" },
  { code: "LA", name: "라오스" },
  { code: "RU", name: "러시아" },
  { code: "LB", name: "레바논" },
  { code: "RO", name: "루마니아" },
  { code: "MY", name: "말레이시아" },
  { code: "MX", name: "멕시코" },
  { code: "MA", name: "모로코" },
  { code: "MZ", name: "모잠비크" },
  { code: "ME", name: "몬테네그로" },
  { code: "MN", name: "몽골" },
  { code: "US", name: "미국" },
  { code: "MM", name: "미얀마" },
  { code: "BD", name: "방글라데시" },
  { code: "VN", name: "베트남" },
  { code: "BE", name: "벨기에" },
  { code: "BY", name: "벨라루스" },
  // { code: "BO", name: "볼리비아" },
  { code: "BG", name: "불가리아" },
  { code: "BR", name: "브라질" },
  { code: "BN", name: "브루나이" },
  { code: "SA", name: "사우디아라비아" },
  { code: "RS", name: "세르비아" },
  { code: "SD", name: "수단" },
  { code: "LK", name: "스리랑카" },
  { code: "SE", name: "스웨덴" },
  { code: "CH", name: "스위스" },
  { code: "ES", name: "스페인" },
  { code: "SK", name: "슬로바키아" },
  { code: "SG", name: "싱가포르" },
  { code: "AE", name: "아랍에미리트" },
  { code: "AR", name: "아르헨티나" },
  { code: "AZ", name: "아제르바이잔" },
  { code: "DZ", name: "알제리" },
  { code: "AO", name: "앙골라" },
  { code: "EC", name: "에콰도르" },
  { code: "ET", name: "에티오피아" },
  { code: "GB", name: "영국" },
  { code: "OM", name: "오만" },
  { code: "AT", name: "오스트리아" },
  { code: "JO", name: "요르단" },
  { code: "UZ", name: "우즈베키스탄" },
  { code: "UA", name: "우크라이나" },
  { code: "IQ", name: "이라크" },
  { code: "IR", name: "이란" },
  { code: "IL", name: "이스라엘" },
  { code: "EG", name: "이집트" },
  { code: "IT", name: "이탈리아" },
  { code: "IN", name: "인도" },
  { code: "ID", name: "인도네시아" },
  { code: "JP", name: "일본" },
  { code: "CN", name: "중국" },
  { code: "CZ", name: "체코" },
  { code: "CL", name: "칠레" },
  { code: "CM", name: "카메룬" },
  { code: "KZ", name: "카자흐스탄" },
  { code: "QA", name: "카타르" },
  { code: "KH", name: "캄보디아" },
  { code: "CA", name: "캐나다" },
  { code: "KE", name: "케냐" },
  { code: "CR", name: "코스타리카" },
  { code: "CI", name: "코트디부아르" },
  { code: "CO", name: "콜롬비아" },
  { code: "KW", name: "쿠웨이트" },
  { code: "HR", name: "크로아티아" },
  { code: "KG", name: "키르기스스탄" },
  { code: "TZ", name: "탄자니아" },
  { code: "TH", name: "태국" },
  { code: "TR", name: "터키" },
  { code: "PA", name: "파나마" },
  { code: "PY", name: "파라과이" },
  { code: "PK", name: "파키스탄" },
  { code: "PE", name: "페루" },
  { code: "PT", name: "포르투갈" },
  { code: "PL", name: "폴란드" },
  { code: "FR", name: "프랑스" },
  { code: "FI", name: "핀란드" },
  { code: "PH", name: "필리핀" },
  { code: "HU", name: "헝가리" },
  { code: "AU", name: "호주" },
  { code: "HK", name: "홍콩" },
]);
