<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from "vue";
import { useRoute, useRouter } from 'vue-router';
import country from '@/assets/js/bisatCountry'
import field from '@/assets/js/bisatField'

export default defineComponent({
  name: "preMatchEventRequestCheck",
  setup() {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject("axios");
    const route = useRoute(); // vue-router의 현재 route 정보 접근
    const router = useRouter(); // 라우터 인스턴스를 가져오기
    const eventId = computed(() => route.params.eventId);

    const attendance = ref(false)
    const insertStep = ref(false)
    const done = ref(false)
    const insertUserInfo = ref({
      p_ptd_list: [], // 초기값을 빈 배열로 설정
      product: '',
    })
    const handleSubmit = () => {
      if (attendance.value === 'Y') {
        insertStep.value = true
      } else {
        postOngoingData()
      }
    }

    // 이벤트 정보
    const eventInfo = ref({})
    const fetchEventInfo = async () => {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value
        });

        const url = `/event/read_piece_without_auth?${queryParams.toString()}`;

        const response = await axios.get(url);
        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          eventInfo.value = response.data.piece_event; // 이벤트 리스트를 저장합니다.
        } else {
          alert('데이터를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('데이터를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }
    // 이벤트 날짜 불러오기
    const eventDateList = ref([])
    const fetchEventDateList = async () => {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value
        });

        const url = `/prematching/read_pre_piece_to_date_list_without_auth?${queryParams.toString()}`;

        const response = await axios.get(url);
        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          eventDateList.value = response.data.p_ptd_list; // 이벤트 리스트를 저장합니다.
        } else {
          alert('데이터를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('데이터를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }
    // 이벤트 품목 불러오기
    const eventProductList = ref([])
    const fetchEventProductList = async () => {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value
        });

        const url = `/event/read_piece_to_product_list_without_auth?${queryParams.toString()}`;

        const response = await axios.get(url);
        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          eventProductList.value = response.data.product_list; // 이벤트 리스트를 저장합니다.
        } else {
          alert('데이터를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('데이터를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    const isScriptLoaded = ref(false); // 스크립트 로드 상태 확인
    const isForeignCompany = ref(false); // 해외주소인 경우
    const countrySelect = country.map(item => ({
      label: item.label,
      value: item.code
    }))
    const fieldSelect = field.map(item => ({
      label: item.label,
      value: item.code
    }))
    const positionSelect = [
      { label: '사원', value: 'staff' },
      { label: '대리', value: 'assistant_manager' },
      { label: '과장', value: 'manager' },
      { label: '차장', value: 'deputy_general_manager' },
      { label: '부장', value: 'general_manager' },
      { label: '이사', value: 'director' },
      { label: '상무', value: 'managing_director' },
      { label: '전무', value: 'executive_director' },
      { label: '부사장', value: 'vice_president' },
      { label: '사장 / 대표', value: 'president_ceo' },
      { label: '기타(직접 입력)', value: 'other' },
    ];
    const selectedPosition = ref('')
    // 선택한 값이 변경될 때 처리 로직
    const updatePositionInfo = computed(() => {
      // 선택한 포지션 데이터 찾기
      const selected = positionSelect.find((item) => item.value === selectedPosition.value);

      if (selected) {
        if (selected.value === 'other') {
          // '기타' 선택 시 초기화
          insertUserInfo.value.position = '';
          insertUserInfo.value.position_en = '';
        } else {
          // 국문과 영문 데이터를 각각 업데이트
          insertUserInfo.value.position = selected.label; // 국문
          insertUserInfo.value.position_en = selected.value; // 영문
        }
      } else {
        // 선택된 값이 없을 경우 초기화
        insertUserInfo.value.position = '';
        insertUserInfo.value.position_en = '';
      }
    });

    // 스크립트 로드 함수
    const loadScript = () => {
      return new Promise((resolve, reject) => {
        if (document.getElementById('daum-postcode-script')) {
          // 이미 스크립트가 로드된 경우
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.id = 'daum-postcode-script';
        script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
        script.onload = () => resolve();
        script.onerror = () => reject(new Error('Failed to load Daum Postcode script'));
        document.body.appendChild(script);
      });
    };

    // 주소 검색 함수
    const loadPostcode = async () => {
      try {
        // 스크립트가 로드되지 않았다면 로드
        if (!isScriptLoaded.value) {
          isScriptLoaded.value = true;
          console.log('Daum Postcode script loaded');
        }

        // Daum Postcode API 사용 코드 작성
        new window.daum.Postcode({
          oncomplete: function (data) {
            console.log(data);
            insertUserInfo.value.company_address = data.address
            // alert(`주소: ${data.address}`);
          },
        }).open();
      } catch (error) {
        console.error(error.message);
      }
    };

    const loading = ref('');
    // 번역 함수
    const translateText = async (args, text) => {
      // 로딩 시작
      loading.value = args;

      // GET 요청을 위한 URL과 파라미터 구성
      const url = '/prematching/get_translate_word_without_auth';
      const params = { word: text };
      try {
        if (text) {
          // 비동기 GET 요청 수행
          const response = await axios.get(url, { params });

          // 요청 성공 여부 확인
          if (response.data.result === true) {
            const translatedWord = response.data.translated_word;

            // args 값에 따라 다른 필드 업데이트
            if (args === 'product_detail') {
              insertUserInfo.value.product_detail_en = translatedWord;
            } else if (args === 'position') {
              insertUserInfo.value.position_en = translatedWord;
            }
          } else {
            console.error('Translation failed:', response.data.message || 'Unknown error');
          }
        } else {
          alert("번역할 텍스트를 입력해주세요.")
        }
      } catch (error) {
        // 에러 처리
        console.error('An error occurred during translation:', error.message);
      } finally {
        // 로딩 종료
        loading.value = '';
      }
    };

    // 사업자등록번호 포맷
    const formatBusinessNumber = (event) => {
      let value = event.target.value.replace(/\D/g, ''); // 숫자만 남기기
      if (value.length > 6) {
        value = value.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
      } else if (value.length > 3) {
        value = value.replace(/(\d{3})(\d{2})/, '$1-$2');
      }
      insertUserInfo.value.business_number = value;
    }
    // 사업자등록번호 포맷
    const formatCorporationNumber = (event) => {
      let value = event.target.value.replace(/\D/g, ''); // 숫자만 남기기
      if (value.length > 6) {
        value = value.replace(/(\d{6})(\d{7})/, '$1-$2'); // xxxxxx-xxxxxxx 형식으로 변경
      }
      insertUserInfo.value.corporation_number = value;
    };


    const isFormValid = computed(() => {
      return (
        insertUserInfo.value.p_ptd_list.length > 0 &&
        insertUserInfo.value.company &&
        insertUserInfo.value.ceo_name &&
        insertUserInfo.value.company_address &&
        insertUserInfo.value.company_phone &&
        insertUserInfo.value.business_number &&
        insertUserInfo.value.corporation_number &&
        insertUserInfo.value.company_industry &&
        insertUserInfo.value.category &&
        insertUserInfo.value.product &&
        insertUserInfo.value.product_detail &&
        insertUserInfo.value.full_name &&
        insertUserInfo.value.email &&
        insertUserInfo.value.mobile &&
        insertUserInfo.value.is_privacy_agreed
      );
    });

    const validateForm = () => {
      const errors = [];
      if (!isFormValid.value) errors.push("모든 필수값을 입력해야 합니다.");
      if (errors.length > 0) {
        alert(errors.join("\n"));
        return false;
      }
      alert("모든 값이 입력되었습니다. 제출을 진행합니다.");
      return true;
    };


    // 데이터 submit
    const postOngoingData = async () => {
      if (validateForm()) {
        try {
          const response = await axios.post(
            "/prematching/create_pre_account_without_auth",
            {
              piece_event_id: eventId.value,
              email: insertUserInfo.value.email,
              p_ptd_id_list: insertUserInfo.value.p_ptd_list,
              account_type: "SELLER",
              ceo_name: insertUserInfo.value.ceo_name,
              full_name: insertUserInfo.value.full_name,
              full_name_en: insertUserInfo.value.full_name_en,
              position: insertUserInfo.value.position,
              position_en: insertUserInfo.value.position_en,
              contact_email: insertUserInfo.value.email,
              mobile: insertUserInfo.value.mobile,
              company: insertUserInfo.value.company,
              company_en: insertUserInfo.value.company_en,
              company_phone: insertUserInfo.value.company_phone,
              company_address: insertUserInfo.value.company_address,
              company_industry: insertUserInfo.value.company_industry,
              business_number: insertUserInfo.value.business_number,
              corporation_number: insertUserInfo.value.corporation_number,
              category: insertUserInfo.value.category,
              product: insertUserInfo.value.product,
              product_detail: insertUserInfo.value.product_detail,
              product_detail_en: insertUserInfo.value.product_detail_en,
              is_privacy_agreed: insertUserInfo.value.is_privacy_agreed ? 'Y' : 'N',
            }
          );
          console.log("응답 데이터:", response.data);
          alert("제출되었습니다.");
          done.value = true;
        } catch (error) {
          console.error("에러 발생:", error.response || error);
          alert("제출에 실패했습니다. 다시 시도해주세요.");
        }
      }
    };


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      await fetchEventInfo()
      await fetchEventProductList()
      fetchEventDateList().then(() => {
        loadScript();
      });
    });

    return {
      eventInfo,
      attendance,
      insertStep,
      insertUserInfo,
      handleSubmit,

      eventDateList,
      loadPostcode,
      isForeignCompany,
      countrySelect,
      fieldSelect,
      positionSelect,
      selectedPosition,
      updatePositionInfo,
      eventProductList,

      translateText,
      loading,
      formatBusinessNumber,
      formatCorporationNumber,
      isFormValid,
      postOngoingData,
      done
    };
  },
});
</script>

<template>
  <div id="preMatchEventRequestCheck" class="no_header back_light_gray2">
    <div class="cont_wrap">
      <div class="logo">
        <router-link to="/bis/dashboard">
          <img src="@/assets/img/common/bis_logo.svg" />
        </router-link>
      </div>
      <div class="content ds_flex dir_column al_start gap32">
        <template v-if="!insertStep">
          <p class="size_20 b_700">상담회 참가 여부를 제출해주세요.</p>
          <div class="event_info ds_flex dir_column al_start gap12">
            <p class="b_700">{{ eventInfo.piece_event_title }}</p>
            <div class="ds_flex al_center gap6">
              <p><i class="icon-date size_12 sub_text b_700"></i></p>
              <p class="size_14 secondary_text b_500">{{ eventInfo.start_date }} ~ {{ eventInfo.end_date }}</p>
            </div>
            <div class="ds_flex al_center gap6">
              <p><i class="icon-location size_12 sub_text b_700"></i></p>
              <p class="size_14 secondary_text b_500">{{ eventInfo.place }}</p>
            </div>
          </div>
          <div class="answer_wrap">
            <p class="b_700">상담회에 참가하시나요?</p>
            <div class="select_wrap">
              <div class="fake_radio">
                <label class="ds_flex gap12 al_center">
                  <input type="radio" value="Y" v-model="attendance" />
                  <p class="b_700">네, 참가합니다</p>
                </label>
              </div>
              <div class="fake_radio">
                <label class="ds_flex gap12 al_center">
                  <input type="radio" value="N" v-model="attendance" />
                  <p class="b_700">아니오, 불참합니다</p>
                </label>
              </div>
            </div>
          </div>
          <p class="block_main_btn" :class="{ 'active': attendance }" @click="handleSubmit">
            {{ attendance === 'Y' ? '다음' : '제출하기' }}
          </p>
        </template>
        <template v-else-if="insertStep && !done">
          <p class="icon_box" @click="insertStep = false">
            <i class="icon-down_arrow rotate90 size_14 sub_text"></i><span class="size_14 sub_text">뒤로가기</span>
          </p>
          <div class="ds_flex dir_column gap12">
            <p class="size_20 b_700">참가 신청하기</p>
            <p class="size_14 sub_text">작성하신 신청서는 검토 후 승인 여부를 문자로 안내드립니다. 입력하신 정보가 불충분하거나 부정확한 경우 신청이 반려될 수 있으니, 정확하고
              꼼꼼히 작성해주세요!</p>
          </div>
          <div class="input_wrap">
            <section>
              <p class="sec_title">상담회 참여 일자를 선택하세요.</p>
              <div class="select_wrap">
                <label class="ds_flex gap8 al_center" v-for="item in eventDateList" :key="item.p_ptd_id">
                  <label :for="`selectDate_${item.p_ptd_id}`" class="fake_checkbox"
                    :class="{ 'active': insertUserInfo.p_ptd_list.includes(item.p_ptd_id) }">
                    <i v-if="insertUserInfo.p_ptd_list.includes(item.p_ptd_id)" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" :id="`selectDate_${item.p_ptd_id}`" :value="item.p_ptd_id"
                    v-model="insertUserInfo.p_ptd_list" />
                  <label class="function_name b_700" :for="`selectDate_${item.p_ptd_id}`">{{ item.date }}</label>
                </label>
              </div>
            </section>
            <section>
              <p class="sec_title">기업 정보를 입력하세요.</p>
              <div class="ds_flex dir_column gap12">
                <label class="size_14 secondary_text">기업명 <span class="main_text">*</span></label>
                <div class="fake_textbox w100">
                  <p class="secondary_text flex1">국문</p>
                  <input class="flex6" type="text" v-model="insertUserInfo.company" placeholder="국문 기업명" />
                </div>
                <div class="fake_textbox w100">
                  <p class="secondary_text flex1">영문</p>
                  <input class="flex6" type="text" v-model="insertUserInfo.company_en" placeholder="영문 기업명" />
                </div>
              </div>
              <div class="ds_flex dir_column gap12">
                <label class="size_14 secondary_text">대표자명 <span class="main_text">*</span></label>
                <input class="fake_textbox w100" type="text" v-model="insertUserInfo.ceo_name" placeholder="대표자명" />
              </div>
              <div class="ds_flex dir_column gap12">
                <div class="ds_flex jus_between">
                  <label class="size_14 secondary_text">기업 주소 <span class="main_text">*</span></label>
                  <label class="ds_flex al_center" @click="insertUserInfo.company_address = ''">
                    <label for="isForeignCompany" class="fake_checkbox" :class="{ 'active': isForeignCompany }">
                      <i v-if="isForeignCompany" class="icon-checked"></i>
                    </label>
                    <input type="checkbox" id="isForeignCompany" v-model="isForeignCompany" />
                    <label class="size_14 secondary_text" for="isForeignCompany">해외지역</label>
                  </label>
                </div>
                <template v-if="isForeignCompany">
                  <v-select :options="countrySelect" label="label" :reduce="item => item.value" :clearable="false"
                    class="w100" v-model="insertUserInfo.company_address" placeholder="국가" />
                </template>
                <template v-else>
                  <input class="fake_textbox w100" type="text" v-model="insertUserInfo.company_address"
                    @click="loadPostcode" placeholder="도로명 주소" readonly />
                </template>
                <input class="fake_textbox w100" type="text" v-model="insertUserInfo.company_address_etc"
                  placeholder="상세 주소" />
              </div>
              <div class="ds_flex dir_column gap12">
                <label class="size_14 secondary_text">기업 전화번호 <span class="main_text">*</span></label>
                <input class="fake_textbox w100" type="text" v-model="insertUserInfo.company_phone"
                  placeholder="기업 전화번호" />
              </div>
              <div class="ds_flex dir_column gap12">
                <label class="size_14 secondary_text">사업자등록번호(10자리)<span class="main_text">*</span></label>
                <input class="fake_textbox w100" type="text" v-model="insertUserInfo.business_number"
                  @input="formatBusinessNumber" placeholder="-제외 10자리" maxlength="12" />
              </div>
              <div class="ds_flex dir_column gap12">
                <label class="size_14 secondary_text">법인등록번호(13자리)<span class="main_text">*</span></label>
                <input class="fake_textbox w100" type="text" v-model="insertUserInfo.corporation_number"
                  @input="formatCorporationNumber" placeholder="-제외 13자리" maxlength="14" />
              </div>
              <div class="ds_flex dir_column gap12">
                <label class="size_14 secondary_text">업종<span class="main_text">*</span></label>
                <input class="fake_textbox w100" type="text" v-model="insertUserInfo.company_industry"
                  placeholder="사업자등록증상 업종" />
              </div>
              <div class="ds_flex dir_column gap12">
                <label class="size_14 secondary_text">관심분야<span class="main_text">*</span></label>
                <v-select :options="fieldSelect" label="label" :reduce="item => item.value" :clearable="false"
                  class="w100" v-model="insertUserInfo.category" placeholder="관심분야" />
              </div>
            </section>
            <section>
              <p class="sec_title">상담회 참여 품목</p>
              <div class="ds_flex dir_column gap12">
                <label class="size_14 secondary_text">상담품목군 <span class="main_text">*</span></label>
                <select v-model="insertUserInfo.product">
                  <option value="">품목군을 선택해주세요.</option>
                  <option v-for="item in eventProductList" :key="item.product" :value="item.product">{{ item.product }}
                  </option>
                </select>
              </div>
              <div class="ds_flex dir_column gap12">
                <div>
                  <label class="size_14 secondary_text">세부 상담 품목명 <span class="main_text">*</span></label>
                  <p class="size_12 sub_text">브랜드명이나 실제 상품명이 아닌 품목명을 기재해주세요.<br />작성 예시) 고추장, 라면, </p>
                </div>
                <div class="fake_textbox w100">
                  <p class="secondary_text flex1">국문</p>
                  <input class="flex5" type="text" v-model="insertUserInfo.product_detail" placeholder="국문 품목명" />
                  <p class="main_text size_14 b_700"
                    @click="translateText('product_detail', insertUserInfo.product_detail)">
                    <span v-if="loading === 'product_detail'">° ° °</span>
                    <span v-else>번역하기</span>
                  </p>
                </div>
                <div class="fake_textbox w100">
                  <p class="secondary_text flex1">영문</p>
                  <input class="flex6" type="text" v-model="insertUserInfo.product_detail_en" placeholder="영문 품목명" />
                </div>
              </div>
            </section>
            <section>
              <p class="sec_title">담당자 정보</p>
              <div class="ds_flex dir_column gap12">
                <label class="size_14 secondary_text">이름 <span class="main_text">*</span></label>
                <div class="fake_textbox w100">
                  <p class="secondary_text flex1">국문</p>
                  <input class="flex6" type="text" v-model="insertUserInfo.full_name" placeholder="국문이름" />
                </div>
                <div class="fake_textbox w100">
                  <p class="secondary_text flex1">영문</p>
                  <input class="flex6" type="text" v-model="insertUserInfo.full_name_en"
                    placeholder="예:)Hong GilDong" />
                </div>
              </div>
              <div class="ds_flex dir_column gap12">
                <label class="size_14 secondary_text">직급/직책<span class="main_text">*</span></label>
                <v-select :options="positionSelect" label="label" :reduce="item => item.value" :clearable="false"
                  @change="updatePositionInfo" class="w100" v-model="selectedPosition" placeholder="직책" />
                <template v-if="selectedPosition === 'other'">
                  <div class="fake_textbox w100">
                    <p class="secondary_text flex1">국문</p>
                    <input class="flex5" type="text" v-model="insertUserInfo.position" placeholder="국문직책" />
                    <p class="main_text size_14 b_700" @click="translateText('position', insertUserInfo.position)">
                      <span v-if="loading === 'position'">° ° °</span>
                      <span v-else>번역하기</span>
                    </p>
                  </div>
                  <div class="fake_textbox w100">
                    <p class="secondary_text flex1">영문</p>
                    <input class="flex6" type="text" v-model="insertUserInfo.position_en" placeholder="영문직책" />
                  </div>
                </template>
              </div>
              <div class="ds_flex dir_column gap12">
                <label class="size_14 secondary_text">이메일<span class="main_text">*</span></label>
                <input class="fake_textbox w100" type="text" v-model="insertUserInfo.email" placeholder="담당자 이메일" />
              </div>
              <div class="ds_flex dir_column gap12">
                <label class="size_14 secondary_text">연락처<span class="main_text">*</span></label>
                <input class="fake_textbox w100" type="text" v-model="insertUserInfo.mobile" placeholder="담당자 전화번호" />
              </div>
            </section>
            <section class="ds_flex dir_column gap12">
              <div>
                <label for="is_privacy_agreed" class="fake_checkbox"
                  :class="{ 'active': insertUserInfo.is_privacy_agreed }">
                  <i v-if="insertUserInfo.is_privacy_agreed" class="icon-checked"></i>
                </label>
                <input type="checkbox" id="is_privacy_agreed" v-model="insertUserInfo.is_privacy_agreed" />
                <label class="size_14 secondary_text" for="is_privacy_agreed">개인정보 활용동의</label>
              </div>
              <p class="size_12 sub_text">이용 약관 텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트</p>
            </section>
            <p class="block_main_btn active_btn" :class="{ 'active': isFormValid }" @click="postOngoingData">제출하기</p>
          </div>
        </template>
        <template v-else-if="insertStep && done">
          <!-- 제출완료 화면 작업 필요 -->
          <div class="w100">
            제출이 완료되었습니다.
            <a class="block_cont_btn active_btn" href="/">메인으로 이동</a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
