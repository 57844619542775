<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'

export default defineComponent({
  props: {
    showEventQrModal: Boolean,
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');
    const eventId = computed(() => store.state.eventStore.eventId);


    const closeModals = () => {
      emit('close');
    }

    watch(() => props.showEventQrModal, (newVal) => {
      if (newVal) {
        fetchPieceEventQr();
      }
    });

    const qrCodeImage = ref('');
    // FETCH PIECE EVENT QR
    async function fetchPieceEventQr() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
        });
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/event/read_qr?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          qrCodeImage.value = response.data.qr;
        } else {
          alert('행사의 QR을 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('행사의 QR을 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }
    });

    return {
      closeModals,
      qrCodeImage
    }
  }
});
</script>

<template>
  <div id="eventSettingQrModal">
    <div class="overlay" v-if="showEventQrModal"></div>
    <div class="modal" v-if="showEventQrModal">
      <p class="size_22 b_700">상담회 QR 코드</p>
      <p class="icon_box close" @click="closeModals">
        <i class="icon-delete sub_text"></i>
      </p>
      <img :src="qrCodeImage" />
    </div>
  </div>
</template>
