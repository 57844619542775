import { ref } from "vue";

export const itemGroups = ref([
  "건설·건축 자재",
  "군수·방산",
  "금융",
  "농수산품·식료품",
  "문화·컨텐츠",
  "바이오·의료기기",
  "생활용품·소형 가전",
  "선박·조선",
  "섬유·패션",
  "일반 기계·부품",
  "자동차·부품",
  "전자·전기 제품",
  "화장품·뷰티",
  "화학·플라스틱",
  "IT·소프트웨어",
  "기타",
]);
