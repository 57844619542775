<script>
import { ref, onMounted, computed, inject } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';
import country from '@/assets/js/bisatCountry'
import preMatchScheduleRequestModal from '@/components/admin/preMatchScheduleRequestModal.vue';

export default {
  name: 'preMatchScheduleRequest',
  components: {
    Pagination,
    subHeader,
    tabHeader,
    preMatchScheduleRequestModal
  },
  props: {
    event: Object
  },
  setup(props) {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const userName = computed(() => store.state.eventStore.userName);
    const userType = computed(() => store.state.userStore.accountType);
    const eventId = computed(() => store.state.eventStore.eventId);
    const { selectDate, handleSelectDate } = props.event;
    const token = ref('');
    const activeSub = 'setting';
    const activeHeadTab = 'preMatch';

    // PAGINATION 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수
    const totalPages = computed(() => Math.ceil(requestScheduleListTotal.value / itemsPerPage.value));
    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
      }
    }

    // FUNCTION BAR
    const eventDateList = ref([]);
    // STEP1. 이벤트 날짜 리스트 받아오기
    async function fetchDate() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value
        });

        const url = `/schedule/read_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateList.value = response.data.date_list;
          if (!selectDate.value) {
            selectDate.value = eventDateList.value[0];
          }
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    // 사전주선 정보 불러오기
    const preArrangedChance = ref(0);
    async function fetchPreMatchData() {
      try {
        const response = await axios.get('/prematching/read_prematching', {
          params: { piece_event_id: eventId.value },
          headers: { Authorization: `Bearer ${token.value}` },
        });

        if (response.data.result === true) {
          preArrangedChance.value = response.data.prematching_data.pre_arranged_chance;
        } else {
          console.log("이벤트 로드 실패");
        }
      } catch (error) {
        console.error("사전주선 데이터 불러오기 오류:", error);
      }
    }

    async function fetchScheduleRequestList() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value
        });

        const url = `/prematching/read_prematching_request_list?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          filteredRequestScheduleList.value = response.data.p_request_list;
          if (!selectDate.value) {
            selectDate.value = eventDateList.value[0];
          }
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올때 오류:', error);
      }
    }

    const searchBy = ref('name_or_email')
    const searchText = ref('')

    const selectedStatuses = ref([])

    const requestScheduleListTotal = ref(3)
    const filteredRequestScheduleList = ref([])
    const getCountryLabel = (value) => {
      const selectedCountry = country.find(item => item.code === value);
      return selectedCountry ? selectedCountry.label : value;
    };

    const formatCompanyList = (companyList) => {
      if (!companyList) return ""; // 값이 없으면 빈 문자열 반환

      const companies = companyList.split(","); // 쉼표 기준으로 분리
      return companies.length > 1
        ? `${companies[0]} 외 ${companies.length - 1}건`
        : companies[0];
    };


    // Modal
    const requestUserModal = ref(false)
    const requestUserInfo = ref([])
    const openModal = (requestUser) => {
      requestUserInfo.value = {requestUser, selectDate: selectDate.value};
      requestUserModal.value = true
    }
    const closeAllModals = () => {
      requestUserModal.value = false;
    };


    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        token.value = store.state.userStore.token;
        await fetchDate()
        await fetchPreMatchData()
        await fetchScheduleRequestList()
      }
    });
    return {
      isAuthenticated,
      userName,
      userType,
      activeSub,
      tabHeader,
      activeHeadTab,
      currentPage,
      totalPages,
      handleClickPage,
      eventDateList,
      selectDate,
      handleSelectDate,

      searchText,
      searchBy,
      selectedStatuses,

      requestUserModal,
      requestUserInfo,
      openModal,
      closeAllModals,

      filteredRequestScheduleList,
      getCountryLabel,
      preArrangedChance,
      formatCompanyList
    };
  },
}

</script>

<template>
  <div id="preMatchScheduleRequest">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
        <p class="inline_cont_btn active_btn">신청 마감하기</p>
      </div>
      <div class="tables_wrap">
        <div class="function_bar">
          <div class="row">
            <select v-model="selectDate" @change="handleSelectDate()">
              <option v-for="(item, index) in eventDateList" :key="index" :value="item">{{ item }}</option>
            </select>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box" @click="" v-if="userType !== 'C1' && userType !== 'C2'"><i class="icon-download"></i>
              </p>
              <div class="fake_textbox">
                <select v-model="searchBy">
                  <option value="name_or_email">이름/이메일</option>
                  <option value="company">회사명</option>
                  <option value="mobile">연락처</option>
                </select>
                <input type="text" v-model="searchText" placeholder="검색어 입력" @keyup.enter="onSearch()" />
                <template v-if="searchText != ''">
                  <i class="icon-delete secondary_text cs_pointer" @click="searchText = '';"></i>
                </template>
                <template v-else>
                  <i class="icon-search secondary_text cs_pointer"></i>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class=" ds_flex gap8 al_center">
              <label class="size_14 secondary_text">상태 보기</label>
              <div class="status_wrap ds_flex gap10">
                <div class="ds_flex al_center">
                  <label for="checkbox-approve-all" class="fake_checkbox"
                    :class="{ 'active': selectedStatuses.includes('approveAll') }">
                    <i v-if="selectedStatuses.includes('approveAll')" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="checkbox-approve-all" value="approveAll" v-model="selectedStatuses" />
                  <label for="checkbox-approve-all" :class="{ 'active': selectedStatuses.includes('approveAll') }">모두
                    승인</label>
                </div>
                <div class="ds_flex al_center">
                  <label for="checkbox-approve-some" class="fake_checkbox"
                    :class="{ 'active': selectedStatuses.includes('approveSome') }">
                    <i v-if="selectedStatuses.includes('approveSome')" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="checkbox-approve-some" value="approveSome" v-model="selectedStatuses" />
                  <label for="checkbox-approve-some" :class="{ 'active': selectedStatuses.includes('approveSome') }">일부
                    승인</label>
                </div>
                <div class="ds_flex al_center">
                  <label for="checkbox-not-approve" class="fake_checkbox"
                    :class="{ 'active': selectedStatuses.includes('notApprove') }">
                    <i v-if="selectedStatuses.includes('notApprove')" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="checkbox-not-approve" value="notApprove" v-model="selectedStatuses" />
                  <label for="checkbox-not-approve"
                    :class="{ 'active': selectedStatuses.includes('notApprove') }">미승인</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table_wrap">
          <table class="table_view">
            <colgroup>
              <col style="width: 35%">
              <col style="width: 10%">
              <col style="width: 20%">
              <col style="width: 15%">
              <col style="width: 10%">
            </colgroup>
            <thead>
              <tr>
                <th>바이어</th>
                <th>국가</th>
                <th>상담품목군/품목</th>
                <th>매칭 신청한 셀러</th>
                <th class="t_center">승인 현황</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="filteredRequestScheduleList.length > 0">
                <tr v-for="requestSchedule in filteredRequestScheduleList" :key="requestSchedule.pta_id"
                  @click="openModal(requestSchedule)">
                  <td class="name">
                    <strong>{{ requestSchedule.buyer_company }}</strong>
                    <br />
                    <span>{{ requestSchedule.buyer_full_name }}</span>
                  </td>
                  <td class="name">
                    <span>{{ getCountryLabel(requestSchedule.buyer_country) }}</span>
                  </td>
                  <td class="">
                    <span>{{ requestSchedule.buyer_product }}/{{ requestSchedule.buyer_product_detail }}</span>
                  </td>
                  <td>
                    <span>{{ formatCompanyList(requestSchedule.request_company_list) }}</span>
                  </td>
                  <td class="status t_center">{{ requestSchedule.approved_request_count }}/{{ requestSchedule.total_request_count
                    }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>정보 없음</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
      <pre-match-schedule-request-modal :request-user-modal="requestUserModal" :request-user-info="requestUserInfo"
        @close="closeAllModals" :scheduleDates="event.scheduleDates"></pre-match-schedule-request-modal>
    </div>
  </div>
</template>
