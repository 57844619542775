<script>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import country from '@/assets/js/bisatCountry'
import { useToast } from 'vue-toastification';

export default {
  name: 'preMatchingExtraInfo',
  components: {
  },
  props: {
    user: Object,
    event: Object,
  },
  setup(props) {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const router = useRouter(); // useRouter를 사용하여 router 인스턴스를 가져옵니다.
    const toast = useToast(); // Toast 호출 함수
    const isAuthenticated = ref(false);
    const token = ref('');
    const { userName, userId, formattedUserType, userType, accountStatus, userCompany } = props.user;
    const { selectDate, scheduleDate, eventId, masterId } = props.event;

    const companyInfo = ref({
      company_explain: '',
      keyword: [],
      target_country: []
    })
    const recommendKeywordSection = ref(false)
    const recommendKeyword = ref([])

    const countrySelect = country.map(item => ({
      label: item.label,
      value: item.code
    }))

    const uploadedFileName = ref(''); // 업로드된 파일명을 저장할 ref
    const loading = ref(false)
    const showInputs = ref(false)

    const handleFileUpload = (event) => {
      loading.value = true
      const file = event.target.files[0]; // 업로드된 파일 객체 가져오기
      if (file) {
        uploadedFileName.value = file.name; // 업로드된 파일명 설정
        uploadCompanyFile(file)
      }
    };
    const handleFileDelete = () => {
      uploadedFileName.value = '';
      document.getElementById('file-upload').value = ''; // 파일 입력 필드 값 초기화
    };

    // 사용자 정보에 파일 업로드 API, 리턴받은 파일 패스는 1시간만 유효
    const uploadCompanyFile = async (file) => {
      try {
        if (!file) {
          console.error('No file selected.');
          return;
        }

        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(`/file/account?account_id=${userId.value}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          }
        });

        // console.log('Upload response:', response.data);
        if (response.data.result === true) {
          companyInfo.value.company_file = response.data.file_path
          await companyIntroductionAnalyze(response.data.file_path)
          getPresignedUrl(response.data.file_path)
        } else {
          alert('저장하지 못했습니다.');
        }

      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };

    // 업로드한 파일의 s3 패스 받아오기, 로드 할때마다 새로운 파일 패스로 교체
    const presignedUrl = ref([])
    async function getPresignedUrl(filePath) {
      try {
        // axios를 사용하여 이벤트 상세 정보를 가져옵니다.
        const response = await axios.get('/file/presigned_url', {
          params: {
            file_path: filePath
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });
        if (response.data.result === true) {
          presignedUrl.value = response.data.file_info
        }

      } catch (error) {
        console.error('파일 경로를 가져오지 못했습니다.:', error);
      }
    }

    // 업로드한 파일패스로 회사소개와 키워드 추출하는 API
    async function companyIntroductionAnalyze(filePath) {
      try {
        const response = await axios.post(
          '/file/company_introduction_analyze',
          {}, // POST 요청의 body는 비워두고, params를 사용
          {
            params: { file_path: filePath },
            headers: {
              Authorization: `Bearer ${token.value}`,
            }
          }
        );

        if (response.data.result === true) {
          companyInfo.value.company_explain = response.data.summary
          recommendKeyword.value = response.data.keywords
          companyInfo.value.keyword = [
            ...companyInfo.value.keyword,
            ...response.data.keywords.slice(0, 3)  // 0, 1, 2번 인덱스 키워드
          ];
          loading.value = false
          showInputs.value = true
        }
      } catch (error) {
        console.error('파일 경로를 가져오지 못했습니다.:', error);
        toast.error("파일에서 정보를 추출할 수 없습니다. \n직접 입력해 주세요.")
        showInputs.value = true
      } finally {
      }
    }

    // s3에서 파일 삭제 API 
    const deleteEventFile = async () => {
      if (confirm("삭제하시겠습니까?")) { // 사용자가 확인을 누른 경우에만 실행
        try {
          const response = await axios.delete(`/file/account?account_id=${userId.value}`, {
            headers: {
              Authorization: `Bearer ${token.value}`
            }
          });
          if (response.data.result === true) {
            alert("삭제되었습니다.");
            router.go(0);
          } else {
            console.log('Delete failed:', response.data.result);
          }
        } catch (error) {
          console.error('Delete failed:', error);
        }
      }
    };


    const newKeyword = ref(''); // 🔹 사용자가 입력하는 키워드

    // 특정 키워드가 `existingKeywords` 또는 `keywordArray`에 포함되는지 확인
    const isActive = (item) => {
      return companyInfo.value.keyword.includes(item);
    };

    // 추천 키워드 추가
    const addKeyword = (item) => {
      if (!companyInfo.value.keyword.includes(item)) {
        companyInfo.value.keyword.push(item);
      }
    };

    // 사용자가 직접 입력한 키워드 추가
    const addCustomKeyword = () => {
      if (newKeyword.value.trim() && !companyInfo.value.keyword.includes(newKeyword.value.trim())) {
        companyInfo.value.keyword.push(newKeyword.value.trim());
        newKeyword.value = ''; // 입력 필드 초기화
      }
    };

    // 키워드 삭제 함수
    const removeKeyword = (item) => {
      const index = companyInfo.value.keyword.indexOf(item);
      if (index !== -1) {
        companyInfo.value.keyword.splice(index, 1);
      }
    };

    // 제출 기능------------------------------------
    // validation 항목들 컴퓨팅
    const isFormValid = computed(() => {
      return (
        companyInfo.value.company_file &&
        companyInfo.value.company_explain &&
        companyInfo.value.keyword &&
        companyInfo.value.target_country
      );
    });

    // validation T/F에 따라 이벤트 분기
    const validateForm = () => {
      const errors = [];
      if (!isFormValid.value) errors.push("모든 필수값을 입력해야 합니다.");
      if (errors.length > 0) {
        alert(errors.join("\n"));
        return false;
      }
      return true;
    };

    let p_account_id = '';
    async function fetchPreMatchData() {
      try {
        // 첫 번째 API 호출
        const response1 = await axios.get('/prematching/read_pre_account_id_list', {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 결과 확인
        if (response1.data.result === true) {
          // piece_event_id가 eventId.value와 같은 항목 필터링
          const matchingAccount = response1.data.p_account_id_list.find(
            (item) => item.piece_event_id === eventId.value
          );
          // 매칭된 p_account_id가 있을 경우
          if (matchingAccount) {
            p_account_id = matchingAccount.p_account_id;
          }
        } else {
          console.log('이벤트 리스트 로드 실패');
        }
      } catch (error) {
        console.error('API 호출 중 오류 발생:', error);
      }
    }

    // 데이터 submit
    const postOngoingData = async () => {
      if (validateForm()) {
        try {
          const response = await axios.put(
            "/prematching/update_pre_account",
            {
              piece_event_id: eventId.value,
              p_account_id: p_account_id,
              company_file: companyInfo.value.company_file,
              company_explain: companyInfo.value.company_explain,
              keyword: companyInfo.value.keyword.join(', '),
              target_country: companyInfo.value.target_country.join(', '),
            },
            {
              headers: {
                Authorization: `Bearer ${token.value}`, // 인증이 필요하면 추가
              },
            }
          );
          console.log("응답 데이터:", response.data);
          alert("제출되었습니다.");
          router.push({ name: 'preMatchingWrap' });
        } catch (error) {
          console.error("에러 발생:", error.response?.data || error);
          alert("제출에 실패했습니다. 다시 시도해주세요.");
        }
      }
    };

    // 신규 회원의 경우로 일단 임시저장 off
    async function fetchUserInfo() {
      try {
        const queryParams = new URLSearchParams({
          account_id: userId.value,
          account_type: "SELLER",
          piece_event_id: eventId.value
        });
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_account_detail_info_for_bis?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result == true) {
          companyInfo.value.product = response.data.info.product
          companyInfo.value.product_detail = response.data.info.product_detail ? response.data.info.product_detail : '-'
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }


    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        await fetchUserInfo()
        fetchPreMatchData()
      }
    });

    return {
      userCompany,
      scheduleDate,
      companyInfo,
      recommendKeywordSection,
      recommendKeyword,
      countrySelect,
      postOngoingData,
      isFormValid,
      uploadedFileName,
      handleFileUpload,
      handleFileDelete,
      presignedUrl,
      deleteEventFile,
      loading,
      newKeyword,
      isActive,
      addKeyword,
      addCustomKeyword,
      removeKeyword,
      showInputs
    };
  },
};
</script>

<template>
  <div id="preMatchingExtraInfo">
    <div class="overlay" v-if="loading">
      <div class="loader"></div>
    </div>
    <div class="page_title ds_flex dir_column gap12">
      <p class="size_22 b_700">사전 매칭 신청 전에<br />필요한 사항을 꼭 확인해주세요.</p>
      <p class="description size_14 sub_text">정확한 바이어 추천을 위해, 아래 사항에 정확히 응답해 주세요.<br />답변 내용을 통해 최적의 바이어를 추천해드려요.</p>
    </div>
    <div class="user_info_event ds_flex dir_column gap10">
      <p class="b_600">{{ userCompany }}</p>
      <div class="ds_flex jus_between">
        <p class="secondary_text size_14">상담회 참여 일자</p>
        <p class="size_14 b_600"><template v-for="item, index in scheduleDate" :key="index">{{ item }}<br /></template>
        </p>
      </div>
      <div class="ds_flex jus_between">
        <p class="secondary_text size_14">상담 품목</p>
        <p class="size_14 b_600">{{ companyInfo.product }}({{ companyInfo.product_detail }})</p>
      </div>
    </div>
    <div class="user_info_preMatch">
      <p class="b_700">기업 및 제품을 소개할 내용을 추가하세요.</p>
      <div class="field_wrap">
        <div class="ds_flex dir_column gap8">
          <p class="size_14 secondary_text">기업 소개서 <span class="require">*</span></p>
          <p class="size_12 b_500 secondary_text">파일을 업로드하면 주요 키워드와 회사 소개를 자동으로 분석하여 추출합니다.<br />(최대 2분 소요)</p>
        </div>
        <div class="file_wrap">
          <template v-if="!presignedUrl.presigned_url">
            <label v-if="!uploadedFileName" for="file-upload">
              <div class="t_center file_label ds_flex dir_column gap8">
                <i class="icon-upload size_20 main_text"></i>
                <p class="size_14 main_text b_700">기업 소개서 첨부하기</p>
                <p class="size_12 sub_text">docx, pdf (50mb이하) 파일 첨부 가능</p>
              </div>
            </label>
            <input id="file-upload" type="file" @change="handleFileUpload" />
          </template>
          <template v-else>
            <div class="file_label">
              <a :href="presignedUrl.presigned_url" class="inline_cont_btn size_14 main_text">{{
                presignedUrl.file_name }}</a>
              <p @click="deleteEventFile" class="inline_cont_btn cancel_btn"><i class="icon-delete"></i></p>
            </div>
          </template>
        </div>
      </div>
      <template v-if="showInputs">
        <div class="field_wrap">
          <div class="ds_flex dir_column gap8">
            <p class="size_14 secondary_text">기업 키워드 <span class="require">*</span>(최소 3개)</p>
            <p class="size_12 b_500 secondary_text">기업을 대표하는 키워드를 입력해주세요. 주요 제품, 서비스, 기술 등을 간단히 표현하면 바이어와의 매칭 정확도가 높아져요.
            </p>
          </div>
          <div class="recommend_company_keyword" v-if="recommendKeyword.length > 0">
            <div class="ds_flex jus_between al_start">
              <p class="size_14 b_600 SELLER_text">비샛 AI가 추출한 키워드</p>
              <p class="size_12 main_text b_600">전체 추가하기</p>
            </div>
            <div class="slot_wrap ds_flex gap8">
              <p class="slot" v-for="item in recommendKeyword" :key="item" :class="{ active: isActive(item) }">
                {{ item }} <i class="icon-plus" v-if="!isActive(item)" @click="addKeyword(item)"></i></p>
            </div>
          </div>
          <div class="input_wrap">
            <div class="fake_textbox w100">
              <input type="text" class="flex4" v-model="newKeyword" @keyup.enter="addCustomKeyword"
                placeholder="예: 친환경 포장재, 스마트 제조 기술" />
              <p class="size_14 main_text b_600 flex1 t_right" @click="addCustomKeyword">추가</p>
            </div>
            <div class="slot_wrap ds_flex gap8">
              <p v-for="(item, index) in companyInfo.keyword" :key="index" class="slot">
                {{ item }} <i class="icon-delete sub_text" @click="removeKeyword(item)"></i>
              </p>
            </div>
          </div>
        </div>
        <div class="field_wrap">
          <div class="ds_flex dir_column gap8">
            <p class="size_14 secondary_text">기업 소개 <span class="require">*</span>{{ companyInfo.company_explain.length
              }}</p>
          </div>
          <div class="input_wrap">
            <textarea placeholder="기업에 대한 간략한 소개를 작성하세요. (500자 이내)" v-model="companyInfo.company_explain" class="w100"
              rows="5"></textarea>
          </div>
        </div>
        <div class="field_wrap">
          <div class="ds_flex dir_column gap8">
            <p class="size_14 secondary_text">홈페이지 링크 <span class="require">*</span></p>
          </div>
          <div class="input_wrap">
            <input type="text" placeholder="https://" class="fake_textbox w100"
              v-model="companyInfo.company_homepage" />
          </div>
        </div>
      </template>
    </div>
    <div class="user_info_preMatch">
      <p class="b_700">진출 희망 국가를 선택하세요. (최소 3개)</p>
      <div class="field_wrap">
        <div class="ds_flex dir_column gap8">
          <p class="size_14 secondary_text">희망 국가 <span class="require">*</span>({{ companyInfo.target_country.length
            }}/3)
          </p>
        </div>
        <div class="input_wrap">
          <v-select class="w100" :options="countrySelect" label="label" :reduce="item => item.value" :clearable="false"
            multiple v-model="companyInfo.target_country" placeholder="진출 희망 국가를 선택하세요" />
        </div>
      </div>
    </div>
    <p class="block_main_btn active_btn" :class="{ 'active': isFormValid }" @click="postOngoingData">제출하기</p>
  </div>
</template>
