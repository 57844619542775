<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
import { useToast } from 'vue-toastification';
import country from '@/assets/js/bisatCountry'


export default defineComponent({
  name: "preMatchingMarkedBuyer",
  props: {
    selectDate: String
  },
  setup(props) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const toast = useToast(); // Toast 호출 함수
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref('');
    const token = ref('');

    const closeModals = () => {
      emit('close');
    }

    // 검색 필터 관련
    const searchText = ref('')
    const productList = ref([''])
    const productSelect = ref([''])
    const activeTab = ref('')
    // 품목 불러오기
    const fetchProductList = async () => {
      try {
        // axios를 사용하여 이벤트 상세 정보를 가져옵니다.
        const response = await axios.get('/event/read_piece', {
          params: {
            piece_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        if (response.data.result === true) {
          productList.value = response.data.piece_event.product_list
        } else {
          console.log("품목 로드 실패")
        }
      } catch (error) {
        console.error('이벤트 데이터를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }
    const countryList = ref([])
    const getCountryLabel = (value) => {
      const selectedCountry = country.find(item => item.code === value);
      return selectedCountry ? selectedCountry.label : value;
    };
    const countrySelect = ref([])
    // 국가 불러오기
    async function fetchCountryList() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/event/read_account_country_list', {
          params: {
            piece_event_id: eventId.value,
            account_type: "BUYER"
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          countryList.value = response.data.country_list.map(code => {
            return {
              code,
              label: getCountryLabel(code), // 코드에 해당하는 라벨을 가져옵니다.
            };
          });
        } else {
          console.log("국가 로드 실패");
        }
      } catch (error) {
      }
    }


    const selectedList = ref({
      product: [], // 선택된 품목 리스트
      country: [], // 선택된 국가 리스트
      time: [], // 선택된 시간 리스트
    });
    const isActive = (type, id) => {
      return selectedList.value[type].some(item => item.value === id);
    };
    // 체크박스 변경 시 실행되는 함수
    const handleCheckboxChange = (item, event) => {
      const type = activeTab.value; // 현재 탭 타입
      const selected = selectedList.value[type];

      const newItem = {
        value: item.ptp_id || item.code || item.ptd_id, // 항목의 고유 ID (value)
        label: item.product || item.label || `${item.start_time}-${item.end_time}`  // 항목의 표시 이름 (label)
      };

      if (event.target.checked) {
        // 체크된 경우 객체를 추가 (중복 검사)
        const exists = selected.some(selectedItem => selectedItem.value === newItem.value);
        if (!exists) selected.push(newItem);
      } else {
        // 체크 해제된 경우 객체를 제거
        const index = selected.findIndex(selectedItem => selectedItem.value === newItem.value);
        if (index > -1) selected.splice(index, 1);
      }
    };

    const allSelectedLabels = computed(() => {
      return [
        ...selectedList.value.product.map(item => item.label),
        ...selectedList.value.country.map(item => item.label),
        ...selectedList.value.time.map(item => item.label)
      ];
    });

    const getLabelDisplay = (list) => {
      if (list.length === 0) return ''; // 선택된 항목이 없으면 빈 문자열 반환
      if (list.length === 1) return ` :${list[0].label}`; // 하나만 선택된 경우
      return ` :${list[0].label} 외 ${list.length - 1}개`; // 두 개 이상인 경우
    };

    const productLabelDisplay = computed(() => getLabelDisplay(selectedList.value.product));
    const countryLabelDisplay = computed(() => getLabelDisplay(selectedList.value.country));
    const timeLabelDisplay = computed(() => getLabelDisplay(selectedList.value.time));

    const bookmark = ref(false)
    const handleBookmark = () => {
      bookmark.value = !bookmark.value;
    };

    const availableBuyer = ref(false)

    const eventDateTime = ref([])
    async function fetchDateTime() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: props.selectDate,
          limit: 30,
        });

        const url = `/schedule/read_datetime?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateTime.value = response.data.datetime.map(item => ({
            ...item,
            start_time: item.start_time.slice(0, 5), // HH:MM 형식으로 변환
            end_time: item.end_time.slice(0, 5), // HH:MM 형식으로 변환
          }));
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }


    // 내가 신청한 스케줄 리스트
    const mySchedules = ref([])
    async function fetchMySchedule() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: props.selectDate,
        });

        const url = `/prematching/read_my_pre_piece_to_datetime_list?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          mySchedules.value = response.data.ptd_list
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }

    // 일반 바이어 리스트
    const sortOption = ref('popular');
    const buyerList = ref([])
    async function fetchBuyerSchedule(buyerId) {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/prematching/read_buyer_pre_schedule', {
          params: {
            piece_event_id: eventId.value,
            date: props.selectDate,
            buyer_id: buyerId
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          return response.data.p_schedule_list; // 스케줄 데이터를 반환

        } else {
          console.log("바이어 스케줄 로드 실패")
        }
      } catch (error) {
      }
    }

    async function fetchBuyerList() {
      try {
        const sortMap = {
          popular: { sortBy: 'favorite_count', orderBy: 'DESC' },
          asc: { sortBy: 'buyer_full_name', orderBy: 'ASC' },
          desc: { sortBy: 'buyer_full_name', orderBy: 'DESC' },
        };

        const { sortBy, orderBy } = sortMap[sortOption.value];
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          account_type: 'BUYER',
          sort_by: sortBy,
          order_by: orderBy,
          is_favorite: true,
        });

        // 검색 텍스트가 있는 경우 추가
        if (searchText.value.trim()) {
          queryParams.append('search_by', 'company');
          queryParams.append('search_text', searchText.value.trim());
        }
        // 선택된 품목 필터 추가
        if (selectedList.value.product.length > 0) {
          selectedList.value.product.forEach(product =>
            queryParams.append('product_list[]', product.value)
          );
        }

        // 선택된 국가 필터 추가
        if (selectedList.value.country.length > 0) {
          selectedList.value.country.forEach(country =>
            queryParams.append('country_list[]', country.value)
          );
        }

        // 선택된 시간 필터 추가
        if (selectedList.value.time.length > 0) {
          selectedList.value.time.forEach(time =>
            queryParams.append('ptd_id_list[]', time.value)
          );
        }

        const url = `/prematching/read_account_list_for_matching?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          const accountList = response.data.account_list;
          // 모든 buyer_id에 대해 fetchBuyerSchedule 호출
          const schedulePromises = accountList.map(async buyer => {
            const scheduleResponse = await fetchBuyerSchedule(buyer.account_id);
            return { ...buyer, schedule: scheduleResponse || [] }; // 스케줄 데이터를 추가
          });

          buyerList.value = await Promise.all(schedulePromises);
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }
    watch(availableBuyer, fetchBuyerList);

    // 관심 바이어
    const createFavoriteBuyer = async (buyerId) => {
      const queryParams = new URLSearchParams({
        piece_event_id: eventId.value,
        buyer_id: buyerId,
      });
      const url = `/prematching/create_pre_buyer_favorite?${queryParams.toString()}`;
      try {
        const response = await axios.post(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          toast.success('관심 바이어로 등록했습니다.');
          fetchBuyerList()
        } else {

        }
      } catch (error) {
        console.error(error);
      }
    }

    // 필터링된 바이어 리스트
    const filteredBuyerList = computed(() => {
      if (!availableBuyer.value) {
        return buyerList.value;  // availableBuyer가 false이면 모든 바이어 리스트 반환
      }

      return buyerList.value.map((buyer) => {
        const filteredTimes = eventDateTime.value.filter(
          (time) => !isMine(time, buyer) && !isExist(time, buyer)
        );
        return { ...buyer, filteredTimes };
      }).filter((buyer) => buyer.filteredTimes.length > 0);
    });


    // 특정 시간(time)이 나와 관련된 스케줄인지 확인
    const isMine = (time, buyer) =>
      mySchedules.value.some(
        (schedule) =>
          schedule.ptd_id === time.ptd_id && schedule.buyer_id === buyer.account_id
      );

    // 특정 시간(time)이 이미 존재하는 스케줄인지 확인
    const isExist = (time, buyer) => {
      return (
        mySchedules.value?.some(schedule => schedule.ptd_id === time.ptd_id) ||
        buyer.schedule?.some(schedule => schedule.p_schedule_id != null)
      );
    };


    const requestMatchingModal = ref(false)
    const requestMatchingModalInfo = ref({})
    // 클릭 이벤트 처리
    const handleClick = (buyer, time) => {
      if (!isExist(time, buyer)) {
        requestMatchingModal.value = true
        requestMatchingModalInfo.value = { buyer, time }
      }
    };

    // 매칭 신청하기
    const requestMatching = async (buyerInfo, timeInfo) => {
      try {
        const response = await axios.post('/prematching/create_pre_schedule', {
          piece_event_id: eventId.value,
          ptb_id: buyerInfo.ptb_id,
          ptd_id: timeInfo.ptd_id,
          buyer_id: buyerInfo.account_id,
        }, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          toast.success('매칭이 신청되었습니다.');
          setTimeout(() => {
            router.go(0)
          }, 1000);
        } else {
        }
      } catch (error) {
        console.error(error);
        if (error.response.data.detail === 'already p_schedule') {
          toast.error("이미 선택한 시간에 다른 매칭 스케줄이 있습니다.");
        }
      }
    }

    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        token.value = store.state.userStore.token;
        await fetchProductList();
        await fetchCountryList();
        await fetchBuyerList();
        await fetchDateTime();
        await fetchMySchedule();
      }
    });
    return {
      closeModals,
      bookmark,
      handleBookmark,

      searchText,
      productList,
      countryList,
      productSelect,
      countrySelect,
      activeTab,
      selectedList,
      allSelectedLabels,
      isActive,
      handleCheckboxChange,
      productLabelDisplay,
      countryLabelDisplay,
      timeLabelDisplay,
      buyerList,
      getCountryLabel,
      createFavoriteBuyer,
      requestMatching,
      fetchBuyerList,
      sortOption,

      eventDateTime,
      mySchedules,
      isMine,
      isExist,
      handleClick,
      availableBuyer,
      filteredBuyerList,
      requestMatchingModal,
      requestMatchingModalInfo
    }
  },
});
</script>

<template>
  <div id="preMatchingMarkedBuyer" class="">
    <div class="cont_wrap">
      <div class="list_wrap">
        <div class="search_wrap ds_flex dir_column gap20" ref="defaultList">
          <div class="fake_textbox ds_flex w100">
            <input class="" v-model="searchText" @keyup="fetchBuyerList" placeholder="기업명으로 검색" />
            <i class="icon-search"></i>
          </div>
          <div class="ds_flex gap8 w100 filter_wrap">
            <div class="fake_select" @click="activeTab = 'product'" :class="{ 'active': productLabelDisplay }">
              <p class="select">품목 {{ productLabelDisplay }}<i class="icon-down_arrow size_15"></i></p>
            </div>
            <div class="fake_select" @click="activeTab = 'country'" :class="{ 'active': countryLabelDisplay }">
              <p class="select">국가 {{ countryLabelDisplay }}<i class="icon-down_arrow size_15"></i></p>
            </div>
            <div class="fake_select" @click="activeTab = 'time'" :class="{ 'active': timeLabelDisplay }">
              <p class="select">시간 {{ timeLabelDisplay }}<i class="icon-down_arrow size_15"></i></p>
            </div>
          </div>
          <div>
            <label class="fake_checkbox" for="availableBuyer" :class="{ 'active': availableBuyer }">
              <i v-if="availableBuyer" class="icon-checked"></i>
            </label>
            <input type="checkbox" id="availableBuyer" v-model="availableBuyer" />
            <label for="availableBuyer" class="size_14 secondary_text">신청 가능한 바이어만 보기</label>
          </div>
        </div>
        <div class="buyer_list">
          <div class="top">
            <div class="ds_flex jus_between al_center">
              <p class="b_700">전체 바이어</p>
              <select class="size_12" v-model="sortOption" @change="fetchBuyerList">
                <option value="popular">인기순</option>
                <option value="asc">오름차순</option>
                <option value="desc">내림차순</option>
              </select>
            </div>
          </div>
          <div class="list">
            <template v-if="buyerList.length > 0">
              <div v-for="(buyer, index) in filteredBuyerList" :key="index" class="list_cont">
                <div class="list_cont_top ds_flex jus_between al_center">
                  <p class="size_14 b_700">{{ buyer.company }}</p>
                  <div class="ds_flex gap8 al_center">
                    <b class="size_12 sub_text">{{ buyer.other_fav_count }}</b>
                    <i class="sub_text" @click="createFavoriteBuyer(buyer.account_id)"
                      :class="buyer.favorite ? ['icon-bookmark_on', 'main_text'] : ['icon-bookmark', 'sub_text']">
                    </i>
                  </div>
                </div>
                <div class="list_cont_bottom">
                  <div class="ds_flex gap12 al_center">
                    <p class="badge country">{{ getCountryLabel(buyer.country) }}</p>
                    <p class="badge product">{{ buyer.product }}</p>
                  </div>
                </div>
                <div class="ds_flex gap12">
                  <template v-for="time in eventDateTime" :key="time.ptd_id">
                    <p class="inline_cont_btn size_12" :class="{
                      'already_mine': isMine(time, buyer),
                      'already_exist': isExist(time, buyer) && !isMine(time, buyer)
                    }" :disabled="isMine(time, buyer) || isExist(time, buyer)" @click="handleClick(buyer, time)">
                      <span v-if="isMine(time, buyer)">
                        <i class="icon-checked"></i>
                      </span>
                      {{ time.start_time }}~{{ time.end_time }}
                    </p>
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <p class="t_center secondary_text">바이어가 없습니다.</p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="activeTab || requestMatchingModal"></div>
    <transition name="slide-up">
      <div class="bottom_sheet" v-if="activeTab">
        <div class="sheet_header jus_between">
          <p class="size_20 b_700">필터</p>
          <p class="icon_box" @click="activeTab = ''"><i class="icon-delete"></i></p>
        </div>
        <div class="sheet_content">
          <div class="option_wrap">
            <div class="page_tab_wrap">
              <p class="tab" :class="{ 'active': activeTab === 'product' }" @click="activeTab = 'product'">품목
              </p>
              <p class="tab" :class="{ 'active': activeTab === 'country' }" @click="activeTab = 'country'">국가
              </p>
              <p class="tab" :class="{ 'active': activeTab === 'time' }" @click="activeTab = 'time'">시간</p>
            </div>
            <div class="tab_cont">
              <template v-if="activeTab === 'product'">
                <div class="cont" v-for="item in productList" :key="item.ptp_id">
                  <label class="fake_checkbox" :for="'product' + item.ptp_id"
                    :class="{ 'active': isActive('product', item.ptp_id) }">
                    <i v-if="isActive('product', item.ptp_id)" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" :id="'product' + item.ptp_id" @change="handleCheckboxChange(item, $event)"
                    :checked="isActive('product', item.ptp_id)" />
                  <label :for="'product' + item.ptp_id">{{ item.product }}</label>
                </div>
              </template>
              <template v-else-if="activeTab === 'country'">
                <div class="cont" v-for="item in countryList" :key="item.code">
                  <label class="fake_checkbox" :for="'country' + item.code"
                    :class="{ 'active': isActive('country', item.code) }">
                    <i v-if="isActive('country', item.code)" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" :id="'country' + item.code" @change="handleCheckboxChange(item, $event)"
                    :checked="isActive('country', item.code)" />
                  <label :for="'country' + item.code">{{ item.label }}</label>
                </div>
              </template>
              <template v-else-if="activeTab === 'time'">
                <div class="cont" v-for="item in eventDateTime" :key="item.ptd_id">
                  <label class="fake_checkbox" :for="'time' + item.ptd_id"
                    :class="{ 'active': isActive('time', item.ptd_id) }">
                    <i v-if="isActive('time', item.ptd_id)" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" :id="'time' + item.ptd_id" @change="handleCheckboxChange(item, $event)"
                    :checked="isActive('time', item.ptd_id)" />
                  <label :for="'time' + item.ptd_id">{{ item.start_time }}~{{ item.end_time }}</label>
                </div>
              </template>
            </div>
            <div
              v-if="selectedList.product.length > 0 || selectedList.country.length > 0 || selectedList.time.length > 0"
              class="selected_filter">
              <p class="" v-for="item, index in allSelectedLabels" :key="index">{{ item }}<i class="icon-delete"></i>
              </p>
            </div>
            <p class="block_sub_btn active_btn b_600" @click="fetchBuyerList">필터 적용하기</p>
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide-up">
      <div class="bottom_sheet confirm_modal" v-if="requestMatchingModal">
        <div class="sheet_header jus_between">
        </div>
        <div class="sheet_content">
          <div class="ds_flex gap32 dir_column">
            <p class="size_20 b_700 t_center">매칭을 신청하시겠어요?</p>
            <p class="size_22 b_700 t_center">{{ requestMatchingModalInfo.time.start_time }}~{{
              requestMatchingModalInfo.time.end_time }}</p>
            <div class="list_cont">
              <div class="list_cont_top ds_flex jus_between al_center">
                <p class="size_14 b_700">{{ requestMatchingModalInfo.buyer.company }}</p>
              </div>
              <div class="list_cont_bottom">
                <div class="ds_flex gap12 al_center">
                  <p class="badge country">{{ getCountryLabel(requestMatchingModalInfo.buyer.country) }}</p>
                  <p class="badge product">{{ requestMatchingModalInfo.buyer.product }}</p>
                </div>
              </div>
            </div>
            <div class="btn_wrap ds_flex gap10">
              <p class="inline_sub_btn cancel_btn" @click="requestMatchingModal = false; requestMatchingModalInfo = {}">
                취소
              </p>
              <p class="inline_sub_btn active_btn"
                @click="requestMatching(requestMatchingModalInfo.buyer, requestMatchingModalInfo.time)">신청하기</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>