<script>
import { ref, onMounted, computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';
import preMatchEventRequestModal from '@/components/admin/preMatchEventRequestModal.vue';
import { useToast } from 'vue-toastification';

export default {
  name: 'preMatchEventRequest',
  components: {
    Pagination,
    subHeader,
    tabHeader,
    preMatchEventRequestModal
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const route = useRoute(); // vue-router의 현재 route 정보 접근
    const router = useRouter(); // 라우터 인스턴스를 가져오기
    const toast = useToast(); // Toast 호출 함수
    const eventId = computed(() => store.state.eventStore.eventId);
    const userName = computed(() => store.state.eventStore.userName);
    const userType = computed(() => store.state.userStore.accountType);
    const token = ref('');
    const activeSub = 'setting';
    const activeHeadTab = 'preMatch';

    // PAGINATION 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수
    const totalPages = computed(() => Math.ceil(requestListTotal.value / itemsPerPage.value));
    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
      }
    }

    // FUNCTION BAR
    const searchBy = ref('name_or_email')
    const searchText = ref('')

    const selectAll = ref(false);
    const selectedUser = ref([]);
    const dropDownStatus = ref(false);
    const changeStatusValue = ref(""); // 'approve' 또는 'return'
    const changeAllStatusCheck = ref(false);

    // 전체 선택 / 해제 기능
    const toggleSelectAll = (value) => {
      selectAll.value = value;
      filteredData.value.forEach((request) => {
        request.selected = value;
      });
      updateSelectedUser();
    };

    // 개별 선택 기능
    const handleCheckboxChange = (request) => {
      request.selected = !request.selected;
      updateSelectedUser();
    };

    // 선택된 사용자 업데이트
    const updateSelectedUser = () => {
      // selectedUser 배열 업데이트
      selectedUser.value = filteredData.value
        .filter((request) => request.selected) // selected가 true인 항목만 필터링
        .map((request) => request.p_account_id);    // pta_id를 배열에 저장

      // 모든 항목이 선택되었는지 확인하여 selectAll 상태 업데이트
      selectAll.value = selectedUser.value.length === filteredData.value.length;
    };

    // 선택된 사용자 수 계산
    const selectedUserCount = computed(() => selectedUser.value.length);

    // 선택된 상태
    const selectedStatuses = ref([]);

    // 팝업에서 emit으로 받은 데이터 갱신
    const updateRequestList = (updatedUser) => {
      // const index = requestList.value.findIndex(
      //   (user) => user.p_account_id === updatedUser.p_account_id
      // );
      // if (index !== -1) {
      //   // 변경된 데이터에서 p_atd_list의 날짜만 반영
      //   const updatedAtdList = updatedUser.p_atd_list.map(item => ({ date: item.date }));
      //   requestList.value[index].p_atd_list = updatedAtdList; // 변경된 p_atd_list를 반영
      //   requestList.value[index] = updatedUser; // 나머지 필드도 갱신
      // }
    };

    // 필터링된 데이터
    const filteredData = computed(() => {
      if (selectedStatuses.value.length === 0) return requestList.value;

      return requestList.value.filter((item) => {
        const statusMap = {
          approve: 1,
          return: 2,
          none: 0,
        };
        return selectedStatuses.value.includes(
          Object.keys(statusMap).find(
            (key) => statusMap[key] === item.approval_status
          )
        );
      });
    });

    // 상태별 개수 계산
    const filteredDataCounts = computed(() => {
      const counts = { approve: 0, return: 0, none: 0 };
      requestList.value.forEach((item) => {
        if (item.approval_status === 1) counts.approve += 1;
        if (item.approval_status === 2) counts.return += 1;
        if (item.approval_status === 0) counts.none += 1;
      });
      return counts;
    });


    // 데이터 불러오기
    // 사전주선 기본정보 불러오기
    const preMatchInfo = ref({})
    async function fetchPreMatchDefaultData() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/prematching/read_prematching', {
          params: {
            piece_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          preMatchInfo.value = response.data.prematching_data
          await fetchPreMatchData()
        } else {
          console.log("이벤트 로드 실패")
        }
      } catch (error) {
      }
    }

    const pre_arranged_approved = ref(0)
    // 목표 달성 비율 계산
    const achievementRate = computed(() => {
      if (preMatchInfo.value.pre_arranged_goal === 0) return 0; // 목표가 0일 때 0% 반환
      return ((pre_arranged_approved.value / preMatchInfo.value.pre_arranged_goal) * 100).toFixed(1);
    });

    const requestListTotal = ref(0)
    const requestList = ref([])
    const eachDateCount = ref([])
    async function fetchPreMatchData() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/prematching/read_pre_account_list', {
          params: {
            piece_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          requestList.value = response.data.p_account_list
          requestListTotal.value = response.data.total_count
          eachDateCount.value = response.data.p_ptd_count
          pre_arranged_approved.value = response.data.approval_status_count.APPROVED
        } else {
          console.log("이벤트 로드 실패")
        }
      } catch (error) {
      }
    }


    const handleApproval = async (request, action) => {
      const newStatus = action === 'approve' ? 1 : 2; // 승인: 1, 반려: 2
      const pAccountIds = selectedUser.value.length > 0
        ? selectedUser.value // 선택된 사용자가 있으면, selectedUser를 그대로 사용
        : [request.p_account_id]; // 선택된 사용자가 없으면, 개별 request의 p_account_id 사용
      try {
        const response = await axios.put('/prematching/update_pre_account_approval_status',
          {
            piece_event_id: eventId.value,
            p_account_id_list: pAccountIds,
            approval_status: newStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
            },
          }
        );
        if (response.data.result === true) {
          router.go(0)
        } else {
          console.error('상태 업데이트 실패', response);
        }
      } catch (error) {
        console.error('API 요청 중 오류 발생', error);
      }
    };




    // Modal
    const requestUserModal = ref(false)
    const requestUserInfo = ref([])
    const requestUserInfoId = ref(0)
    const openModal = (requestUser) => {
      requestUserInfoId.value = requestUser.p_account_id;
      requestUserModal.value = true
      router.push({ query: { id: requestUser.p_account_id } });

    }
    const closeAllModals = () => {
      requestUserModal.value = false;
      router.push({ query: {} });

    };


    async function bulkRegisterApprovedSellers() {
      try {
        const response = await axios.post('/prematching/create_pre_to_main_account_bulk', {}, {
          params: {
            piece_event_id: eventId.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        if (response.data.result === true) {
          toast.success('승인된 사용자들을 일괄 등록했습니다.');
        } else {
          console.error('일괄 등록 실패', response);
        }
      } catch (error) {
        console.error('API 요청 중 오류 발생', error);
      }
    }

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        token.value = store.state.userStore.token;
        // 사전주선 셀러목표 불러오기
        await fetchPreMatchDefaultData()
        const userId = route.query.id;
        if (userId) {
          requestUserInfoId.value = userId;
          requestUserModal.value = true;
        }
      }
    });
    return {
      isAuthenticated,
      userName,
      userType,
      activeSub,
      tabHeader,
      activeHeadTab,
      currentPage,
      totalPages,
      handleClickPage,

      searchText,
      searchBy,
      selectedStatuses,
      changeAllStatusCheck,
      dropDownStatus,
      toggleSelectAll,
      handleCheckboxChange,
      updateSelectedUser,
      selectedUserCount,
      selectedUser,
      changeStatusValue,
      selectAll,

      requestUserModal,
      requestUserInfo,
      requestUserInfoId,
      openModal,
      closeAllModals,
      updateRequestList,

      preMatchInfo,
      pre_arranged_approved,
      achievementRate,
      eachDateCount,
      filteredData,
      filteredDataCounts,
      handleApproval,
      bulkRegisterApprovedSellers,
    };
  },
}

</script>

<template>
  <div id="preMatchEventRequest" class="back_light_gray2">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="wide_wrap">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
        <p class="inline_cont_btn" @click="bulkRegisterApprovedSellers">승인된 셀러 일괄 사용자 등록</p>
      </div>
      <div class="tables_wrap">
        <div class="function_bar">
          <div class="row goal">
            <p class="size_20 t_center"><span class="main_text b_700">{{ achievementRate }}%</span> 도달</p>
            <div class="t_center">
              <p class="size_14 secondary_text">목표 셀러</p>
              <p class="size_18 b_700">{{ preMatchInfo.pre_arranged_goal }}개 업체</p>
            </div>
            <div class="t_center">
              <p class="size_14 secondary_text">승인된 셀러</p>
              <p class="size_18 b_700">{{ pre_arranged_approved }}<span class="sub_text b_700">/{{
                preMatchInfo.pre_arranged_goal }}개
                  업체</span></p>
            </div>
          </div>
          <div class="row">
            <p class="b_700">참여 신청 리스트</p>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box" @click="" v-if="userType !== 'C1' && userType !== 'C2'"><i class="icon-download"></i>
              </p>
              <div class="fake_textbox">
                <select v-model="searchBy">
                  <option value="name_or_email">이름/이메일</option>
                  <option value="company">회사명</option>
                  <option value="mobile">연락처</option>
                </select>
                <input type="text" v-model="searchText" placeholder="검색어 입력" @keyup.enter="onSearch()" />
                <template v-if="searchText != ''">
                  <i class="icon-delete secondary_text cs_pointer" @click="searchText = '';"></i>
                </template>
                <template v-else>
                  <i class="icon-search secondary_text cs_pointer"></i>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class=" ds_flex gap8 al_center">
              <label class="size_14 secondary_text">상태 보기</label>
              <div class="status_wrap ds_flex gap10">
                <div class="ds_flex al_center">
                  <label for="checkbox-approve" class="fake_checkbox"
                    :class="{ 'active': selectedStatuses.includes('approve') }">
                    <i v-if="selectedStatuses.includes('approve')" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="checkbox-approve" value="approve" v-model="selectedStatuses" />
                  <label for="checkbox-approve" :class="{ 'active': selectedStatuses.includes('approve') }">
                    승인 {{ filteredDataCounts.approve }}
                  </label>
                </div>

                <!-- 반려 -->
                <div class="ds_flex al_center">
                  <label for="checkbox-return" class="fake_checkbox"
                    :class="{ 'active': selectedStatuses.includes('return') }">
                    <i v-if="selectedStatuses.includes('return')" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="checkbox-return" value="return" v-model="selectedStatuses" />
                  <label for="checkbox-return" :class="{ 'active': selectedStatuses.includes('return') }">
                    반려 {{ filteredDataCounts.return }}
                  </label>
                </div>

                <!-- 미확인 -->
                <div class="ds_flex al_center">
                  <label for="checkbox-none" class="fake_checkbox"
                    :class="{ 'active': selectedStatuses.includes('none') }">
                    <i v-if="selectedStatuses.includes('none')" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="checkbox-none" value="none" v-model="selectedStatuses" />
                  <label for="checkbox-none" :class="{ 'active': selectedStatuses.includes('none') }">
                    미확인 {{ filteredDataCounts.none }}
                  </label>
                </div>
              </div>
              <template v-for="item in eachDateCount" :key="item.p_ptd_id">
                <p class="size_14 secondary_text b_700">{{ item.date }}: <span class="main_text">{{ item.count
                    }}건</span>
                </p>
              </template>
            </div>
            <p class="inline_cont_btn change_all_status" @click="changeAllStatusCheck = true"
              v-if="!changeAllStatusCheck">
              상태 일괄
              변경하기</p>
          </div>
          <div v-if="changeAllStatusCheck" class="ds_flex jus_between al_center change_all_status_info">
            <p class="size_14 secondary_text">상태를 변경할 바이어를 선택하세요.</p>
            <div class="change_all_status_wrap ds_flex al_center gap12">
              <p class="size_14 eqqui_text b_700">{{ selectedUserCount }}명</p>
              <div>
                <p class="inline_cont_btn" @click="dropDownStatus = true">변경할 상태 선택</p>
                <div class="dropdown_status_wrap" v-if="dropDownStatus">
                  <label for="approve" class="ds_flex gap8 al_center dropdown_status_cont"
                    :class="{ 'active': changeStatusValue === 'approve' }">
                    <input type="radio" value="approve" id="approve" name="status" v-model="changeStatusValue" />
                    <span class="size_14">승인</span>
                  </label>
                  <label for="return" class="ds_flex gap8 al_center dropdown_status_cont"
                    :class="{ 'active': changeStatusValue === 'return' }">
                    <input type="radio" value="return" id="return" name="status" v-model="changeStatusValue" />
                    <span class="size_14">반려</span>
                  </label>
                  <div class="btn_wrap ds_flex jus_between al_center">
                    <p class="submit" @click="handleApproval('', changeStatusValue)">변경하기</p>
                  </div>
                </div>
              </div>
              <p @click="changeAllStatusCheck = false" class="size_12 sub_text pointer">취소</p>
            </div>
          </div>
        </div>
        <div class="table_wrap">
          <table class="table_view">
            <thead>
              <tr>
                <th v-if="changeAllStatusCheck">
                  <label class="fake_checkbox" for="selectAll" :class="{ 'active': selectAll }">
                    <i v-if="selectAll" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" id="selectAll" v-model="selectAll" @change="toggleSelectAll(selectAll)" />
                </th>
                <th class="name">셀러</th>
                <th>신청일</th>
                <th>품목</th>
                <th>이메일</th>
                <th>연락처</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="filteredData.length > 0">
                <tr v-for="request in filteredData" :key="request.p_account_id" @click="openModal(request)">
                  <!-- 체크박스 -->
                  <td v-if="changeAllStatusCheck" @click.stop="">
                    <label class="fake_checkbox" :for="'select_' + request.p_account_id"
                      :class="{ active: selectedUser.includes(request.p_account_id) }">
                      <i v-if="selectedUser.includes(request.p_account_id)" class="icon-checked"></i>
                    </label>
                    <input type="checkbox" :id="'select_' + request.p_account_id"
                      :checked="selectedUser.includes(request.p_account_id)" @change="handleCheckboxChange(request)" />
                  </td>
                  <!-- 회사 이름 및 담당자 이름 -->
                  <td class="name">
                    <strong>{{ request.company }}</strong>
                    <br />
                    <span>{{ request.full_name }}</span>
                  </td>
                  <!-- 신청일 -->
                  <td>
                    <p v-for="(date, index) in request.p_atd_list" :key="index">{{ date }}</p>
                  </td>
                  <!-- 제품명 -->
                  <td>
                    <span>{{ request.product }}</span>
                  </td>
                  <!-- 이메일 -->
                  <td>
                    <span>{{ request.email }}</span>
                  </td>
                  <!-- 연락처 -->
                  <td>
                    <span>{{ request.mobile }}</span>
                  </td>

                  <!-- 상태 -->
                  <td class="status" @click.stop="">
                    <template v-if="request.approval_status === 0">
                      <div class="ds_flex al_center gap12">
                        <button class="inline_cont_btn cancel_btn"
                          @click="handleApproval(request, 'reject')">반려</button>
                        <button class="inline_cont_btn cancel_btn"
                          @click="handleApproval(request, 'approve')">승인</button>
                      </div>
                    </template>
                    <template v-else>
                      <span :class="{
                        'status_complete': request.approval_status === 1,
                        'status_return': request.approval_status === 2,
                      }">
                        {{ request.approval_status === 1 ? '승인' : '반려' }}
                      </span>
                    </template>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>정보 없음</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
      <pre-match-event-request-modal :request-user-modal="requestUserModal" :pre-match-info="preMatchInfo"
        :request-user-info-id="Number(requestUserInfoId)" @close="closeAllModals"
        @data-saved="updateRequestList"></pre-match-event-request-modal>
    </div>
  </div>
</template>
