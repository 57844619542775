<script>
import { ref, onMounted, computed, inject } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';
import preMatchScheduleTableModal from '@/components/admin/preMatchScheduleTableModal.vue'
import preMatchScheduleTableSideModal from '@/components/admin/preMatchScheduleTableSideModal.vue'
import scheduleModal from '@/components/admin/preMatchScheduleDetailModal.vue';

export default {
  name: 'preMatchScheduleTable',
  props: {
    event: Object
  },
  components: {
    Pagination,
    subHeader,
    tabHeader,
    preMatchScheduleTableModal,
    preMatchScheduleTableSideModal,
    scheduleModal,
  },
  setup(props) {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const eventId = computed(() => store.state.eventStore.eventId);
    const userType = computed(() => store.state.userStore.accountType);
    const token = ref('');
    const activeSub = 'setting';
    const activeHeadTab = 'preMatch';

    // 페이지 관련 변수
    const currentPage = ref(1);
    const itemsPerPage = ref(10); // 페이지당 아이템 수

    const totalPages = computed(() => Math.ceil(yList.value.length / itemsPerPage.value));
    const paginatedYList = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return yList.value.slice(start, end);
    });
    const handleClickPage = (pageIndex) => {
      if (pageIndex >= 1 && pageIndex <= totalPages.value) {
        currentPage.value = pageIndex;
      }
    };


    //이벤트 일시 관련 변수
    const eventDateList = ref([]);
    const eventDateTime = ref([]);
    const { eventTitle, selectDate, handleSelectDate } = props.event;

    //이벤트 기준 관련 변수 (Y축)
    const yList = ref([]);

    const selectedType = ref("booth_name");
    const searchBy = ref("interpreter_name");
    const searchText = ref("");

    // Modal
    const smartMatchingModal = ref(false)
    const matchingListModal = ref(false)
    const showScheduleAddModal = ref(false);
    const selectedScheduleData = ref({});
    const openScheduleAddModal = (booth, time, isSchedule, userInfo) => {
      selectedScheduleData.value = { booth, time, isSchedule, userInfo };
      showScheduleAddModal.value = true;
    }
    const closeAllModals = () => {
      smartMatchingModal.value = false;
      matchingListModal.value = false
      showScheduleAddModal.value = false;
    };


    // STEP1. 이벤트 날짜 리스트 받아오기
    async function fetchDate() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
        });

        const url = `/schedule/read_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateList.value = response.data.date_list;
          if (!selectDate.value) {
            selectDate.value = eventDateList.value[0]
          }
          fetchDateTime()
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }

    // STEP2. 이벤트 세팅 시 설정한 상담회 날짜, 시간 불러오기 (X축 생성)
    async function fetchDateTime() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: selectDate.value,
          limit: 30,
        });

        const url = `/schedule/read_datetime?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateTime.value = response.data.datetime.map(item => ({
            ...item,
            start_time: item.start_time.slice(0, 5), // HH:MM 형식으로 변환
            end_time: item.end_time.slice(0, 5), // HH:MM 형식으로 변환
            showBool: true, // 스케줄 숨기기 boolean
          }));
          fetchBoothList()
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }
    // STEP3. 부스 불러오기 (Y축 생성)
    async function fetchBoothList() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
        });

        const url = `/schedule/read_booths?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          yList.value = response.data.booths;
          fetchSchedule()
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }

    // 스케줄 관련 변수
    const schedule = ref([])
    const scheduleCount = ref({
      manual: 0,
      auto: 0,
      total: 0
    })

    // STEP4. 스케줄 불러오기
    async function fetchSchedule(matching_type) {
      try {
        let fetchedCount = 0; // 현재까지 가져온 데이터 수
        const limit = 100; // 요청당 가져올 데이터 수
        let totalCount = 0; // 전체 데이터 수
        let page = 1;

        schedule.value = []; // 스케줄 초기화

        do {
          const queryParams = new URLSearchParams({
            piece_event_id: eventId.value,
            date: selectDate.value,
            limit,
            page
          });

          if (searchBy.value) {
            queryParams.append('search_by', searchBy.value);
          }
          if (searchText.value) {
            queryParams.append('search_text', searchText.value);
          }
          if (matching_type) {
            queryParams.append('matching_type', matching_type);
          }

          const url = `/prematching/read_pre_schedule_list?${queryParams.toString()}`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          });

          // 서버 응답이 result === true인지 확인
          if (response.data.result === true) {
            totalCount = response.data.count.total;
            scheduleCount.value = response.data.count;

            // 검색결과로 schedule_list가 비어 있으면 종료
            if (!response.data.schedule_list || response.data.schedule_list.length === 0) {
              break;
            }

            if (totalCount >= 0) {
              schedule.value = [...schedule.value, ...response.data.schedule_list];

              fetchedCount += response.data.schedule_list.length;
              page += 1;
            } else {
              alert('에러: 잘못된 totalCount 값');
              break;
            }
          } else {
            console.warn('서버 응답 실패:', response.data);
            alert('데이터를 가져오는 데 실패했습니다.');
            break;
          }
        } while (fetchedCount < totalCount); // 가져온 데이터가 전체 데이터보다 적을 때 반복

      } catch (error) {
        console.error('스케줄 불러올 때 오류:', error);
      }
    }


    const scheduleDownload = async () => {
      try {
        const response = await axios.get(`/schedule/download_schedules_table?piece_event_id=${eventId.value}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
          responseType: "blob", // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
        });
        // 파일 객체 생성
        const blob = new Blob([response.data], { type: response.headers["content-type"] });

        // 파일 URL 생성
        const url = URL.createObjectURL(blob);

        // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `schedule_table.xlsx`); // 파일명에 날짜와 시간을 추가

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 메모리 해제
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("파일 다운로드 중 오류 발생:", error);
      }
    };



    // 스마트 매칭 요청
    const requestSmartMatching = async () => {
      try {
        const response = await axios.post('/prematching/smart_matching_schedules', null, {
          params: {
            piece_event_id: eventId.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        // 요청이 성공했고, 결과가 true이면 상태 확인 시작
        if (response.data.result === true) {
          const task_id = response.data.task_id;
          localStorage.setItem("smart_matching_task_id", task_id); // 이탈했다 와서도 상태확인 필요, task_id 저장
          monitorTaskStatus(task_id);
        } else {
          alert('저장하지 못했습니다.');
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.detail) {
          const errorMessage = error.response.data.detail;

          if (errorMessage === 'Smart Matching is already in progress. Please try again later.') {
            alert('이미 스마트 매칭이 진행 중입니다. 잠시 후 다시 시도해주세요.');
          } else {
            alert(`오류가 발생했습니다: ${errorMessage}`);
          }
        } else {
          // 서버 응답이 없거나 다른 종류의 오류일 경우
          alert("알 수 없는 오류가 발생했습니다. 매칭 중단 후 다시 시도해주세요.");
        }
      }
    };


    // 5초마다 상태 확인하는 함수
    const isMatchingInProgress = ref(false); // 진행 중 상태
    const showTooltip = ref(false); // 진행 중 상태

    async function monitorTaskStatus(task_id) {
      isMatchingInProgress.value = true; // 매칭 시작 시 애니메이션 활성화

      const interval = setInterval(async () => {
        showTooltip.value = true;
        try {
          const queryParams = new URLSearchParams({ task_id });
          const url = `/prematching/smart_matching_schedules_status?${queryParams.toString()}`;
          const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${token.value}` },
          });

          if (response.data.status === "SUCCESS") {
            showTooltip.value = false;
            if (response.data.result) {
              alert("스마트 매칭이 성공적으로 완료되었습니다!");
              fetchSchedule()
            } else {
              alert("스마트 매칭이 실패했습니다. 다시 시도해주세요.");
            }
            localStorage.removeItem("smart_matching_task_id"); // task_id 삭제
            isMatchingInProgress.value = false; // 애니메이션 중지
            clearInterval(interval);
          } else if (response.data.status === "FAILURE") {
            showTooltip.value = false;
            alert("스마트 매칭 실패: " + response.data.message);
            isMatchingInProgress.value = false; // 애니메이션 중지
            localStorage.removeItem("smart_matching_task_id"); // task_id 삭제
            clearInterval(interval);
          } else if (response.data.status !== "PROGRESS") {
            showTooltip.value = false;
            console.warn("에러 발생: 상태 정보를 가져오지 못함.");
            isMatchingInProgress.value = false;
            localStorage.removeItem("smart_matching_task_id"); // task_id 삭제
            clearInterval(interval);
            alert("알 수 없는 오류가 발생했습니다. 매칭 중단 후 다시 시도해주세요.");
          }
        } catch (error) {
          console.error("스케줄 상태 확인 중 오류:", error);
          isMatchingInProgress.value = false;
          clearInterval(interval);
          alert("알 수 없는 오류가 발생했습니다. 매칭 중단 후 다시 시도해주세요.");
        }
      }, 5000);
    }



    const getSchedules = (ptb_id, ptd_id) => {
      let schedules = schedule.value.filter(s => s.ptb_id === parseInt(ptb_id) && s.ptd_id === parseInt(ptd_id));
      return schedules
    };

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        fetchDate()
        const storedTaskId = localStorage.getItem("smart_matching_task_id");
        if (storedTaskId) {
          monitorTaskStatus(storedTaskId);
        }
      }
    });
    return {
      isAuthenticated,
      activeSub,
      tabHeader,
      activeHeadTab,
      userType,

      currentPage,
      totalPages,
      handleClickPage,
      selectedType,
      searchBy,
      searchText,
      scheduleDownload,
      smartMatchingModal,
      matchingListModal,
      closeAllModals,
      schedule,
      getSchedules,

      eventDateList,
      eventDateTime,
      selectDate,
      handleSelectDate,
      paginatedYList,
      requestSmartMatching,


      openScheduleAddModal,
      showScheduleAddModal,
      selectedScheduleData,
      yList,
      fetchSchedule,
      scheduleCount,
      isMatchingInProgress,
      showTooltip
    };
  },
}

</script>

<template>
  <div id="preMatchScheduleTable">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="wide_wrap">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
        <div class="icon_cont_btn" :class="{ 'pulse': isMatchingInProgress }" @click="smartMatchingModal = true">
          스케줄 스마트 매칭 <i class="icon-refresh rotate" v-if="isMatchingInProgress"></i>
          <p class="tooltip" :class="{ 'active': showTooltip }">팝업에서 스마트 매칭을 중단할 수 있어요.</p>
        </div>
      </div>
      <div class="tables_wrap eventSettingTables_wrap operate">
        <div class="function_bar">
          <div class="row">
            <select v-model="selectDate" @change="handleSelectDate">
              <option v-for="(item, index) in eventDateList" :key="index" :value="item">{{ item }}</option>
            </select>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box" @click="scheduleDownload" v-if="userType !== 'C1' && userType !== 'C2'"><i
                  class="icon-download"></i></p>
              <p class="fake_textbox" :class="{ 'w100': userType === 'C1' || userType === 'C2' }">
                <select name="searchBy" id="searchBy" v-model="searchBy">
                  <option value="buyer_name">바이어</option>
                  <option value="interpreter_name">통역사</option>
                  <option value="seller_name">셀러</option>
                  <option value="booth_name">부스</option>
                </select>
                <input type="text" id="searchText" placeholder="검색어 입력" v-model="searchText"
                  @keyup.enter="fetchSchedule()" />
                <template v-if="searchText != ''">
                  <i class="icon-delete secondary_text cs_pointer" @click="searchText = ''; fetchSchedule()"></i>
                </template>
                <template v-else>
                  <i class="icon-search secondary_text cs_pointer"></i>
                </template>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="ds_flex gap8 al_center">
              <label class="size_14 secondary_text">스케줄 필터</label>
              <div class="status_wrap ds_flex">

                <input type="radio" id="all" name="toggle" value="all" checked @change="fetchSchedule()">
                <label for="all" class="size_14 secondary_text b_700">전체 스케줄 {{ scheduleCount.total }}</label>


                <input type="radio" id="aOnly" name="toggle" value="aOnly" @change="fetchSchedule('auto')">
                <label for="aOnly" class="size_14 secondary_text b_700">스마트 매칭 {{ scheduleCount.auto }}</label>


                <input type="radio" id="bOnly" name="toggle" value="bOnly" @change="fetchSchedule('manual')">
                <label for="bOnly" class="size_14 secondary_text b_700">승인된 매칭 {{ scheduleCount.manual }}</label>

                <div class="active-bg"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="table_wrap">
          <table>
            <thead>
              <tr>
                <th>부스<span>▾</span></th>
                <th v-for="(date, index) in eventDateTime" :key="date.ptd_id">
                  {{ date.start_time }}~{{ date.end_time }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="paginatedYList.length > 0">
                <tr v-for="(booth, index) in paginatedYList" :key="index">
                  <td class="booth">{{ booth.booth_name }}</td>
                  <td v-for="time in eventDateTime" :key="time.ptd_id" class="schedule">
                    <template v-if="getSchedules(booth.ptb_id, time.ptd_id).length">
                      <div class="schedule_info_wrap" v-for="schedule in getSchedules(booth.ptb_id, time.ptd_id)"
                        :class="{ 'smart_match': schedule.matching_type === 'auto' }" :key="schedule.p_schedule_id"
                        @click="openScheduleAddModal(booth, time, schedule.p_schedule_id, schedule)">
                        <div class="schedule_info ds_flex gap4 al_center"><i class='icon-operate_remark'></i>
                          <p class="size_12 secondary_text">FM</p>
                          <p class="size_12 secondary_text">{{ schedule.manager_name ? schedule.manager_name : '-' }}
                          </p>
                        </div>
                        <p class="schedule_user_info"><span class="type_bdg">B</span><span>{{ schedule.buyer_company
                            }} {{
                              schedule.buyer_name }}</span></p>
                        <p class="schedule_user_info"><span class="type_bdg">I</span><span>{{
                          schedule.interpreter_name ? schedule.interpreter_name : '-' }}</span></p>
                        <p class="schedule_user_info"><span class="type_bdg">S</span><span>{{ schedule.seller_company
                            }} {{
                              schedule.seller_name }}</span></p>
                      </div>
                      <p class="size_12 sub_text add_btn" @click="openScheduleAddModal(booth, time, false, '')">+ 추가</p>
                    </template>
                    <template v-else>
                      <p class="add_schedule" @click="openScheduleAddModal(booth, time, false, '')">+ 추가</p>
                    </template>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>부스 없음</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <Pagination :currentPage="currentPage" :totalPages="totalPages" :handleClickPage="handleClickPage" />
      <pre-match-schedule-table-modal :smart-matching-modal="smartMatchingModal" @startMatching="requestSmartMatching"
        :smartMatchingStatus="isMatchingInProgress" @close="closeAllModals"></pre-match-schedule-table-modal>
      <pre-match-schedule-table-side-modal :matching-list-modal="matchingListModal"
        @close="closeAllModals"></pre-match-schedule-table-side-modal>
      <schedule-modal :show-schedule-add-modal="showScheduleAddModal" @close="closeAllModals" :boothList="yList"
        :selectedScheduleData="selectedScheduleData"></schedule-modal>
    </div>
    <!-- <p class="right_float_btn" v-if="!matchingListModal" @click="matchingListModal = true"><i
        class="icon-down_arrow rotate90 size_28"></i></p> -->
    <div class="floating_btn" v-if="scheduleCount.total > 0">
      <div class="mx_1160">
        <div class="ds_flex jus_end">
          <p class="inline_sub_btn eqqui_btn b_700" @click="">운영 스케줄로 등록</p>
        </div>
      </div>
    </div>
  </div>
</template>
