<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import preMatchRequestEachTime from '@/components/bis/preMatchRequestEachTime.vue';
import country from '@/assets/js/bisatCountry'
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: "preMatchingMySchedule",
  props: {
    selectDate: String
  },
  components: {
    preMatchRequestEachTime
  },
  setup(props) {
    const axios = inject("axios");
    const store = useStore();
    const route = useRoute(); // vue-router의 현재 route 정보 접근
    const router = useRouter(); // 라우터 인스턴스를 가져오기
    const toast = useToast(); // Toast 호출 함수
    const isAuthenticated = ref(false);
    const eventId = computed(() => store.state.eventStore.eventId);
    const token = ref('');

    const selectDate = props.selectDate;

    const eventDateTime = ref([])
    async function fetchDateTime() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: props.selectDate,
          limit: 30,
        });

        const url = `/schedule/read_datetime?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          eventDateTime.value = response.data.datetime.map(item => ({
            ...item,
            start_time: item.start_time.slice(0, 5), // HH:MM 형식으로 변환
            end_time: item.end_time.slice(0, 5), // HH:MM 형식으로 변환
          }));
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }

    const mySchedules = ref([]);
    async function readMySchedule() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: props.selectDate,
        });

        const url = `/prematching/read_seller_self_pre_schedule?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          mySchedules.value = response.data.p_schedule_list;
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }
    // 필터링된 데이터 생성
    const filteredSchedules = computed(() => {
      return eventDateTime.value.map((time) => {
        const matchingSchedule = mySchedules.value.find(
          (schedule) => schedule.ptd_id === time.ptd_id
        );

        return {
          ...time,
          buyerInfo: matchingSchedule || null,
          p_schedule_id: matchingSchedule ? matchingSchedule.p_schedule_id : null,
        };
      });
    });

    const getCountryLabel = (value) => {
      const selectedCountry = country.find(item => item.code === value);
      return selectedCountry ? selectedCountry.label : value;
    };

    const deleteRequest = async (scheduleId) => {
      if (confirm("신청한 매칭을 취소할까요?")) {
        try {
          const response = await axios.delete(`/prematching/delete_pre_schedule`, {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
            data: { // request body에 데이터 전달
              p_schedule_id: scheduleId,
            },
          });
          if (response.data.result === true) {
            toast.success("신청이 취소되었습니다.")
            setTimeout(() => {
              router.go(0);
            }, 1000); // 1초 후에 페이지 새로 고침
          } else {
            toast.warning("취소되지 않았습니다. 다시 시도해주세요.")
            console.log('Delete failed:', response.data.result);
          }
        } catch (error) {
          toast.warning("취소되지 않았습니다. 다시 시도해주세요.")
          console.error('Delete failed:', error);
        }

      }
    }

    // 모달
    const showPreMatchRequestModal = ref(false)
    const timeInfo = ref({})
    const openModal = (time) => {
      timeInfo.value = time
      showPreMatchRequestModal.value = true;
    };
    const closeModals = () => {
      showPreMatchRequestModal.value = false;
    };


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        setTimeout(async () => {
          await fetchDateTime();
          await readMySchedule();
        }, 200); // props 로드 되고나서 실행
      }
    });

    return {
      eventDateTime,
      openModal,
      timeInfo,
      showPreMatchRequestModal,
      closeModals,
      filteredSchedules,
      getCountryLabel,
      deleteRequest
    };
  },
});
</script>

<template>
  <div id="preMatchingMySchedule" class="ds_flex dir_column gap20">
    <div class="warning ds_flex gap8 al_start">
      <i class="icon-information size_14 b_700"></i>
      <p class="size_12 b_700">매칭 신청은 필수가 아니며, 빈 스케줄은 비샛이 적합한 바이어와 스마트 매칭해드려요.</p>
    </div>
    <div class="back_light_gray3 schedule_cont" v-for="time in filteredSchedules" :key="time.ptd_id">
      <p class="size_20 b_700">{{ time.start_time }}~{{ time.end_time }}</p>
      <div class="info" :class="{ 'is_buyer': time.buyerInfo }">
        <template v-if="time.buyerInfo">
          <div>
            <p class="b_700">{{ time.buyerInfo.buyer_company }}</p>
            <div class="ds_flex gap12 al_center buyer_info">
              <p class="badge country">{{ getCountryLabel(time.buyerInfo.buyer_country) }}</p>
              <p class="badge product">{{ time.buyerInfo.buyer_product }}</p>
            </div>
          </div>
          <p class="delete" @click="deleteRequest(time.p_schedule_id)"><i class="icon-delete"></i></p>
        </template>
        <template v-else>
          <p class="main_text b_700" @click="openModal(time)">+ 스케줄 신청하기</p>
        </template>
      </div>
    </div>
    <preMatchRequestEachTime :showPreMatchRequestModal="showPreMatchRequestModal" @close="closeModals"
      :timeInfo="timeInfo">
    </preMatchRequestEachTime>
  </div>
</template>