<script>
import { ref, defineComponent, onMounted, inject, watch, computed, nextTick } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    showPieceSelectModal: Boolean,
    showUserATypeModal: Boolean,
    showUserBTypeModal: Boolean,
    a_account_list: Array,
    b_account_list: Array,
    piece_event_list: Array,
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');
    const pageEvent = ref(1);
    const pagePM = ref(1);
    const pageClient = ref(1);
    const limit = 10;
    const isFetchingEvent = ref(false);
    const isFetchingPM = ref(false);
    const isFetchingClient = ref(false);
    const observerTargetEvent = ref(null);
    const observerTargetPM = ref(null);
    const observerTargetClient = ref(null);

    // PM과 Client 리스트 관리
    const pieceEventList = ref([]);
    const selectedPieceEvent = ref([]);
    const userPmList = ref([]);
    const userPmListCount = ref(0);
    const pieceEventListCount = ref(0);
    const userClientList = ref([]);
    const userClientListCount = ref(0);
    const selectedPM = ref([]);
    const selectedClient = ref([]);

    // 체크박스 상태 변경 처리
    const handleCheckboxChange = (userItem, event) => {
      const selectedData = {
        at_id: userItem.at_id,
        account_id: userItem.account_id,
        account_type: userItem.account_type,
        full_name: userItem.full_name,
        company: userItem.company
      };

      // 현재 모달 타입에 따라 분기
      const selectedList = activeModalType.value === 'PM' ? selectedPM : selectedClient;
      const alreadySelected = selectedList.value.some(pm => pm.at_id === userItem.at_id);
      const index = selectedList.value.findIndex(pm => pm.at_id === userItem.at_id);

      if (event.target.checked) {
        if (!alreadySelected) selectedList.value.push(selectedData);
      } else {
        if (alreadySelected) selectedList.value.splice(index, 1);
      }
    };

    const handleCheckboxChangePiece = (userItem, event) => {
      const selectedData = {
        title: userItem.title,
        event_id: userItem.piece_event_id
      };

      // 현재 모달 타입에 따라 분기
      const alreadySelected = selectedPieceEvent.value.some(event => event.event_id === userItem.piece_event_id);
      const index = selectedPieceEvent.value.findIndex(event => event.event_id === userItem.piece_event_id);

      if (event.target.checked) {
        if (!alreadySelected) selectedPieceEvent.value.push(selectedData);
      } else {
        if (alreadySelected) selectedPieceEvent.value.splice(index, 1);
      }
    };

    const isActive = (at_id) => {
      const selectedList = activeModalType.value === 'PM' ? selectedPM : selectedClient;
      return selectedList.value.some(pm => pm.at_id === at_id);
    };

    const isActivePiece = (event_id) => {
      return selectedPieceEvent.value.some(event => event.event_id === event_id);
    };

    // activeModalType 계산된 속성
    const activeModalType = computed(() => {
      if (props.showUserATypeModal) return 'PM';
      else if (props.showUserBTypeModal) return 'Client';
      else if (props.showPieceSelectModal) return 'piece';
      else return null;
    });

    // Watch props
    watchProps();

    // Watch props 함수
    function watchProps() {
      watch(
        [() => props.a_account_list, () => props.b_account_list, () => props.piece_event_list, () => props.showUserATypeModal, () => props.showUserBTypeModal, () => props.showPieceSelectModal],
        async ([newAccountList, newAccountClientList, newPieceList, newShowUserATypeModal, newShowUserBTypeModal, newShowPieceModal], [oldAccountList, oldAccountClientList, oldPieceList, oldShowUserATypeModal, oldShowUserBTypeModal, oldShowPieceModal]) => {
          if (newAccountList !== oldAccountList) {
            selectedPM.value = newAccountList.map(item => ({
              at_id: item.at_id,
              account_id: item.account_id,
              account_type: item.account_type,
              full_name: item.full_name,
              company: item.company
            }));
          }

          if (newAccountClientList !== oldAccountClientList) {
            selectedClient.value = newAccountClientList.map(item => ({
              at_id: item.at_id,
              account_id: item.account_id,
              account_type: item.account_type,
              full_name: item.full_name,
              company: item.company
            }));
          }

          if (newPieceList !== oldPieceList) {
            if (Array.isArray(newPieceList)) {
              selectedPieceEvent.value = newPieceList.map(item => ({
                event_id: item.event_id,
                title: item.title,
                company: item.company,
                place: item.place,
                start_date: item.start_date,
                end_date: item.end_date,
                pm: item.pm
              }));
            }
          }

          if (newShowUserATypeModal && newShowUserATypeModal !== oldShowUserATypeModal) {
            fetchPMList();
            await setupObserver();
          }

          if (newShowUserBTypeModal && newShowUserBTypeModal !== oldShowUserBTypeModal) {
            fetchClientList();
            await setupObserver();
          }

          if (newShowPieceModal && newShowPieceModal !== oldShowPieceModal) {
            fetchPieceEventList();
            await setupObserver();
          }
        },
        { immediate: true }
      );
    }

    // FETCH PIECE EVENT LIST
    async function fetchPieceEventList() {
      if (isFetchingEvent.value) return;
      isFetchingEvent.value = true;
      try {
        const queryParams = new URLSearchParams({
          page: pageEvent.value,
          limit: limit
        });
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/event/read_piece_event_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          pieceEventList.value.push(...response.data.piece_event_list); // 이벤트 리스트를 저장합니다.
          pieceEventListCount.value = response.data.count_info.total;
          pageEvent.value++;
          console.log(pageEvent.value);
        } else {
          alert('피스행사를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('피스행사를 가져오는 중에 오류가 발생했습니다:', error);
      } finally {
        isFetchingEvent.value = false;
      }
    }

    // FETCH PM LIST
    async function fetchPMList() {
      if (isFetchingPM.value) return;
      isFetchingPM.value = true;
      try {
        const queryParams = new URLSearchParams({
          page: pagePM.value,
          limit: limit
        });
        queryParams.append('account_type_list', 'A1');
        queryParams.append('account_type_list', 'a2');
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          userPmList.value.push(...response.data.user_list); // 이벤트 리스트를 저장합니다.
          userPmListCount.value = response.data.count_info.total;
          pagePM.value++;
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      } finally {
        isFetchingPM.value = false;
      }
    }

    // FETCH CLIENT LIST
    async function fetchClientList() {
      if (isFetchingClient.value) return;
      isFetchingClient.value = true;
      try {
        const queryParams = new URLSearchParams({
          page: pageClient.value,
          limit: limit
        });
        queryParams.append('account_type_list', 'b1');
        queryParams.append('account_type_list', 'b2');
        queryParams.append('account_type_list', 'c1');
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          userClientList.value.push(...response.data.user_list); // 이벤트 리스트를 저장합니다.
          userClientListCount.value = response.data.count_info.total;
          pageClient.value++;
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      } finally {
        isFetchingClient.value = false;
      }
    }

    const setupObserver = async () => {
      await nextTick();

      if (observerTargetEvent.value) {
        const observerEvent = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            console.log("✅ 이벤트 리스트 무한 스크롤 감지됨!");
            fetchPieceEventList();
          }
        });
        observerEvent.observe(observerTargetEvent.value);
      }

      if (observerTargetPM.value) {
        const observerPM = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            console.log("✅ PM 리스트 무한 스크롤 감지됨!");
            fetchPMList();
          }
        });
        observerPM.observe(observerTargetPM.value);
      }

      if (observerTargetClient.value) {
        const observerClient = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            console.log("✅ Client 리스트 무한 스크롤 감지됨!");
            fetchClientList();
          }
        });
        observerClient.observe(observerTargetClient.value);
      }
    };

    // 컴포넌트 마운트 시 로직
    onMounted(() => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }
      if (props.showUserATypeModal) {
        fetchPMList();
        if (props.a_account_list) {
          selectedPM.value = props.a_account_list.map(item => ({
            at_id: item.at_id,
            account_id: item.account_id,
            account_type: item.account_type,
            full_name: item.full_name,
            company: item.company
          }));
        }
      } else if (props.showUserBTypeModal) {
        fetchClientList();
        if (props.b_account_list) {
          selectedClient.value = props.b_account_list.map(item => ({
            at_id: item.at_id,
            account_id: item.account_id,
            account_type: item.account_type,
            full_name: item.full_name,
            company: item.company
          }));
        }
      } else if (props.showPieceSelectModal) {
        fetchPieceEventList();
        if (props.piece_event_list) {
          selectedPieceEvent.value = props.piece_event_list.map(item => ({
            title: item.title
          }))
        }
      }
    });


    const closeModals = () => {
      emit('close');
    }
    // 선택된 PM을 부모 컴포넌트로 전달
    const sendSelectedPMToParent = () => {
      emit('updateSelectedPM', selectedPM.value);
      emit('close');
    }

    // 선택된 Client를 부모 컴포넌트로 전달
    const sendSelectedClientToParent = () => {
      emit('updateSelectedClient', selectedClient.value);
      emit('close');
    }

    // 선택된 피스를 부모 컴포넌트로 전달
    const sendSelectedPieceToParent = () => {
      emit('updateSelectedPiece', selectedPieceEvent.value);
      emit('close');
    }

    return {
      closeModals,
      userPmList,
      selectedPM,
      handleCheckboxChange,
      handleCheckboxChangePiece,
      isActive,
      isActivePiece,
      sendSelectedPMToParent,
      userClientList,
      selectedClient,
      sendSelectedClientToParent,
      pieceEventList,
      selectedPieceEvent,
      sendSelectedPieceToParent,
      observerTargetEvent,
      observerTargetPM,
      observerTargetClient,
      userPmListCount,
      userClientListCount,
      pieceEventListCount
    }
  }
});
</script>

<template>
  <div id="eventSettingMasterModal">
    <!-- 오버레이 -->
    <div class="overlay" v-if="showPieceSelectModal || showUserATypeModal || showUserBTypeModal"></div>
    <div class="modal" v-if="showPieceSelectModal || showUserATypeModal || showUserBTypeModal">
      <!-- 모달 닫기 아이콘 -->
      <p class="icon_box close" @click="closeModals">
        <i class="icon-delete sub_text"></i>
      </p>
      <!-- 피스 행사 리스트 모달 -->
      <template v-if="showPieceSelectModal">
        <p class="modal_title size_28 b_700">피스 행사 리스트</p>
        <!-- 검색창 -->
        <div class="user_table_modal">
          <!-- 테이블 헤더 -->
          <div class="table_fx ds_flex">
            <p class="table_length">{{ pieceEventListCount }}개의 피스행사</p>
            <div class="fake_textbox ds_flex">
              <input type="text" placeholder="검색어" />
              <div class="icon_box">
                <i class="icon-search"></i>
              </div>
            </div>
          </div>
          <!-- 테이블 본문 -->
          <div class="table_cont">
            <!-- 테이블 헤더 -->
            <div class="table_cont_head ds_flex">
              <p class="table_cont_th">선택</p>
              <p class="table_cont_th name">행사명</p>
              <p class="table_cont_th company">고객사</p>
              <p class="table_cont_th">장소</p>
              <p class="table_cont_th id">날짜</p>
              <p class="table_cont_th id">담당PM</p>
            </div>
            <!-- 테이블 본문 -->
            <div class="table_cont_body">
              <div class="table_cont_tr ds_flex" v-for="(item, index) in pieceEventList" :key="index">
                <div class="table_cont_td">
                  <!-- 선택 체크박스 -->
                  <label class="fake_checkbox" :for="'pieceEvent-' + item.piece_event_id"
                    :class="{ 'active': isActivePiece(item.piece_event_id) }">
                    <i v-if="isActivePiece(item.piece_event_id)" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" :id="'pieceEvent-' + item.piece_event_id"
                    @change="handleCheckboxChangePiece(item, $event)" :checked="isActivePiece(item.piece_event_id)" />
                </div>
                <!-- 행사명 -->
                <p class="table_cont_td name">{{ item.title }}</p>
                <!-- 고객사 -->
                <p class="table_cont_td company">
                  <span v-for="(item2, index) in item.company_list" :key="index">
                    {{ item2 }}<span v-if="index < item.company_list.length - 1">, </span>
                  </span>
                </p>
                <!-- 장소 -->
                <p class="table_cont_td">{{ item.place }}</p>
                <!-- 날짜 -->
                <p class="table_cont_td id">{{ item.start_date }} - {{ item.end_date }}</p>
                <!-- 담당PM -->
                <p class="table_cont_td id">
                  <span v-for="(item2, index) in item.pm_list" :key="index">
                    {{ item2 }}<span v-if="index < item.pm_list.length - 1">, </span>
                  </span>
                </p>
              </div>
              <div ref="observerTargetEvent" style="height: 10px"></div>
            </div>
            <!-- 테이블 하단 -->
            <div class="table_cont_bottom">
              <!-- 선택된 행사 -->
              <div class="table_select_user_wrap ds_flex gap18">
                <p class="b_700">선택한 행사</p>
                <div class="table_select_user ds_flex">
                  <p class="ds_flex" v-for="(item, index) in selectedPieceEvent" :key="index">{{ item.title }}<i
                      class="icon-delete sub_text"></i></p>
                </div>
              </div>
              <!-- 저장 버튼 -->
              <div class="btn_wrap">
                <p class="block_main_btn active_btn" @click="sendSelectedPieceToParent">저장</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- PM 리스트 모달 -->
      <template v-if="showUserATypeModal">
        <p class="modal_title size_28 b_700">PM 리스트</p>
        <!-- 검색창 -->
        <div class="user_table_modal">
          <!-- 테이블 헤더 -->
          <div class="table_fx ds_flex">
            <p class="table_length">{{ userPmListCount }}명의 PM</p>
            <div class="fake_textbox ds_flex">
              <input type="text" placeholder="검색어" />
              <div class="icon_box">
                <i class="icon-search"></i>
              </div>
            </div>
          </div>
          <!-- 테이블 본문 -->
          <div class="table_cont">
            <!-- 테이블 헤더 -->
            <div class="table_cont_head ds_flex">
              <p class="table_cont_th">선택</p>
              <p class="table_cont_th">권한</p>
              <p class="table_cont_th name">이름</p>
              <p class="table_cont_th id">아이디</p>
              <p class="table_cont_th company">기업명</p>
            </div>
            <!-- 테이블 본문 -->
            <div class="table_cont_body">
              <div class="table_cont_tr ds_flex" v-for="(item, index) in userPmList" :key="index">
                <div class="table_cont_td">
                  <!-- 선택 체크박스 -->
                  <label class="fake_checkbox" :for="'userAtype-' + item.at_id"
                    :class="{ 'active': isActive(item.at_id) }">
                    <i v-if="isActive(item.at_id)" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" :id="'userAtype-' + item.at_id" @change="handleCheckboxChange(item, $event)"
                    :checked="isActive(item.at_id)" />
                </div>
                <!-- 권한 -->
                <div class="table_cont_td">
                  <p class="autority" :class="item.account_type">{{ item.account_type }}</p>
                </div>
                <!-- 이름 -->
                <p class="table_cont_td name">{{ item.full_name }}</p>
                <!-- 아이디 -->
                <p class="table_cont_td id">{{ item.email }}</p>
                <!-- 기업명 -->
                <p class="table_cont_td company">{{ item.company }}</p>
              </div>
              <div ref="observerTargetPM" style="height: 10px"></div>
            </div>
            <!-- 테이블 하단 -->
            <div class="table_cont_bottom">
              <!-- 선택된 PM -->
              <div class="table_select_user_wrap ds_flex gap18">
                <p class="b_700">선택한 PM</p>
                <div class="table_select_user ds_flex">
                  <p class="ds_flex" v-for="(item, index) in selectedPM" :key="index">{{ item.full_name }}<i
                      class="icon-delete sub_text"></i></p>
                </div>
              </div>
              <!-- 저장 버튼 -->
              <div class="btn_wrap">
                <p class="block_main_btn" @click="sendSelectedPMToParent">저장</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 고객사 리스트 모달 -->
      <template v-if="showUserBTypeModal">
        <p class="modal_title size_28 b_700">고객사 리스트</p>
        <!-- 검색창 -->
        <div class="user_table_modal">
          <!-- 테이블 헤더 -->
          <div class="table_fx ds_flex">
            <p class="table_length">{{ userClientListCount }}명의 고객</p>
            <div class="fake_textbox ds_flex">
              <input type="text" placeholder="검색어" />
              <div class="icon_box">
                <i class="icon-search"></i>
              </div>
            </div>
          </div>
          <!-- 테이블 본문 -->
          <div class="table_cont">
            <!-- 테이블 헤더 -->
            <div class="table_cont_head ds_flex">
              <p class="table_cont_th">선택</p>
              <p class="table_cont_th">권한</p>
              <p class="table_cont_th name">이름</p>
              <p class="table_cont_th id">아이디</p>
              <p class="table_cont_th company">기업명</p>
            </div>
            <!-- 테이블 본문 -->
            <div class="table_cont_body">
              <div class="table_cont_tr ds_flex" v-for="(item, index) in userClientList" :key="index">
                <div class="table_cont_td">
                  <!-- 선택 체크박스 -->
                  <label class="fake_checkbox" :for="'userBtype-' + item.at_id"
                    :class="{ 'active': isActive(item.at_id) }">
                    <i v-if="isActive(item.at_id)" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" :id="'userBtype-' + item.at_id" @change="handleCheckboxChange(item, $event)"
                    :checked="isActive(item.at_id)" />
                </div>
                <!-- 권한 -->
                <div class="table_cont_td">
                  <p class="autority" :class="item.account_type">{{ item.account_type }}</p>
                </div>
                <!-- 이름 -->
                <p class="table_cont_td name">{{ item.full_name }}</p>
                <!-- 아이디 -->
                <p class="table_cont_td id">{{ item.email }}</p>
                <!-- 기업명 -->
                <p class="table_cont_td company">{{ item.company }}</p>
              </div>
              <div ref="observerTargetClient" style="height: 10px"></div>
            </div>
            <!-- 테이블 하단 -->
            <div class="table_cont_bottom">
              <!-- 선택된 Client -->
              <div class="table_select_user_wrap ds_flex gap18">
                <p class="b_700">선택한 고객</p>
                <div class="table_select_user ds_flex">
                  <p class="ds_flex" v-for="(item, index) in selectedClient" :key="index">{{ item.full_name }}<i
                      class="icon-delete sub_text"></i></p>
                </div>
              </div>
              <!-- 저장 버튼 -->
              <div class="btn_wrap">
                <p class="block_main_btn" @click="sendSelectedClientToParent">저장</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>