<script>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
  setup() {
    const route = useRoute();
    const store = useStore();

    // 경로에 따라 다른 레이아웃을 사용
    const layout = computed(() => {
      if (route.path.startsWith('/metafields')) {
        return 'AppMetafields';
      } else if (route.path.startsWith('/admin')) {
        return 'AppAdmin';
      } else if (route.path.startsWith('/bis')) {
        return 'AppBIS';
      } else {
        return 'AppAdmin';
      }
    });

    // 페이지가 처음 로드될 때 loginCheck 실행
    onMounted(() => {
      store.dispatch('userStore/loginCheck');
    });

    return { layout };
  }
};

</script>


<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
