<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';
import bisatStatus from '@/assets/js/bisatStatus.json';
import bisatLang from '@/assets/js/bisatLang.json';
import bisatCountry from '@/assets/js/bisatCountry'

export default defineComponent({
  props: {
    showStatusModal: Boolean,
    selectedUserData: Object,
    showScheduleAddModal: Boolean,
    selectedScheduleData: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    const eventId = computed(() => store.state.eventStore.eventId);

    // 유저 데이터 모달 start
    const scheduleId = computed(() => props.selectedUserData.scheduleId);
    const userStatus = computed(() => props.selectedUserData.userStatus);
    const userId = computed(() => props.selectedUserData.userId);
    const userType = computed(() => props.selectedUserData.type);
    const userInfo = ref({})

    watch(
      () => props.selectedUserData,
      async (newValue) => {
        if (newValue && newValue.userId) {  // newValue가 존재하고 userId가 있을 때만 실행
          await fetchUserData();
        }
      },
      { immediate: true }
    );

    const closeModals = () => {
      newScheduleInfoBuyer.value = {};
      newScheduleInfoInterpreter.value = {};
      newScheduleInfoSeller.value = {};
      newScheduleInfoManager.value = {};
      newScheduleInfoMemo.value = '';
      userInfo.value = {}
      emit('close');
    }

    const changeUserType = (userType) => {
      switch (userType) {
        case 'BUYER':
          return '바이어';
        case 'INTERPRETER':
          return '통역사';
        case 'SELLER':
          return '셀러';
        case 'C2':
          return '현장요원';
        default:
          return '-';
      }
    }

    const getStatusList = computed(() => {
      if (userType.value && bisatStatus[userType.value]) {
        return bisatStatus[userType.value].operate || [];
      }
      return [];
    });

    const getLanguage = (code) => {
      const languageLabel = bisatLang.find(item => item.code === code);
      return languageLabel ? languageLabel.label : '';
    };

    const getCountry = (code) => {
      const countryLabel = bisatCountry.find(item => item.code === code);
      return countryLabel ? countryLabel.label : '';
    };

    // 상태 업데이트
    const changeStatus = async () => {
      const data = {
        "update_list": [{
          piece_event_id: eventId.value,
          account_id: userInfo.value.account_id,
          account_type: userType.value,
          schedule_id: scheduleId.value,
          code_name: userInfo.value.userStatus,
          created_how: 'SCHEDULETABLE'
        }]
      };
      const url = '/schedule/update_schedule_account_status';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          router.go(0)
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('상태 업데이트 오류:', error);
      }
    }

    // 클릭한 사용자의 상세 정보 불러오기
    async function fetchUserData() {
      try {
        const queryParams = new URLSearchParams({
          account_id: userId.value,
          account_type: userType.value,
        });

        const url = `/user/read_account_detail_info?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          userInfo.value = response.data.info.account
          userInfo.value.userStatus = userStatus.value
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('사용자 정보 에러:', error);
      }
    }
    // 유저 데이터 모달 end

    // 스케줄 데이터 모달 start
    // 수정모드 확인
    const is_video_link = computed(() => store.state.eventStore.isActiveOnlineLink);
    const newScheduleInfoBuyer = ref({});
    const newScheduleInfoInterpreter = ref({});
    const newScheduleInfoSeller = ref({});
    const newScheduleInfoManager = ref({});
    const newScheduleInfoMemo = ref({});
    const newScheduleInfoVideo = ref('');

    watch(
      () => props.selectedScheduleData,
      async (newValue) => {
        if (newValue && newValue.isSchedule) {  // newValue가 존재하고 userId가 있을 때만 실행
          await fetchScheduleDetail();
        }
      },
      { immediate: true }
    );

    async function fetchScheduleDetail() {
      try {
        const queryParams = new URLSearchParams({
          schedule_id: props.selectedScheduleData.isSchedule
        });

        const url = `/schedule/read_each_schedule?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data != null) {
          newScheduleInfoBuyer.value = response.data.buyer;
          newScheduleInfoInterpreter.value = response.data.interpreter;
          newScheduleInfoSeller.value = response.data.seller;
          newScheduleInfoManager.value = response.data.manager;
          newScheduleInfoMemo.value = response.data.memo;
          newScheduleInfoVideo.value = response.data.link;
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 불러올때 오류:', error);
      }
    }

    const newMemo = ref('')
    // 개별 스케줄 수정 API
    async function updateScheduleMemo() {
      try {
        const url = '/schedule/update_schedule';
        const requestData = {
          schedule_id: props.selectedScheduleData.isSchedule,
          buyer: {
            account_id: newScheduleInfoBuyer.value.account_id,
            account_type: newScheduleInfoBuyer.value.account_type,
            at_id: newScheduleInfoBuyer.value.at_id
          },
          interpreter: {
            account_id: newScheduleInfoInterpreter.value.account_id,
            account_type: newScheduleInfoInterpreter.value.account_type,
            at_id: newScheduleInfoInterpreter.value.at_id
          },
          seller: {
            account_id: newScheduleInfoSeller.value.account_id,
            account_type: newScheduleInfoSeller.value.account_type,
            at_id: newScheduleInfoSeller.value.at_id
          },
        };
        requestData.memo = newMemo.value;
        const response = await axios.put(url, requestData, {
          headers: {
            Authorization: `Bearer ${token.value}`
          }
        });

        if (response.data.result === true) {
          alert("메모가 저장되었습니다.")
          router.go(0)
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 개별추가 오류:', error);
      }
    }

    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }

      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });

    return {
      closeModals,
      changeUserType,
      getStatusList,
      getLanguage,
      getCountry,
      changeStatus,
      userInfo,
      userType,
      userStatus,
      userId,
      is_video_link,
      newScheduleInfoBuyer,
      newScheduleInfoInterpreter,
      newScheduleInfoSeller,
      newScheduleInfoManager,
      newScheduleInfoMemo,
      newScheduleInfoVideo,
      newMemo,
      updateScheduleMemo
    }
  },
});
</script>

<template>
  <div id="operateTableModal">
    <div class="overlay" v-if="showStatusModal || showScheduleAddModal"></div>
    <div class="modal status_width" v-if="showStatusModal">
      <div class="modal_overflow">
        <p class="icon_box close" @click="closeModals()">
          <i class="icon-delete sub_text"></i>
        </p>
        <p class="user_type_bdg" :class="userType">{{ changeUserType(userType) }}</p>
        <p class="size_26 b_700">{{ userType === 'INTERPRETER' ? userInfo.full_name : userInfo.company }}</p>
        <div class="user_cont" v-if="userInfo">
          <p v-if="userType != 'INTERPRETER'"><label>이름</label> {{ userInfo.full_name }}</p>
          <p v-if="userInfo.email"><label>이메일</label> {{ userInfo.email }}</p>
          <p v-if="userInfo.mobile"><label>연락처</label> {{ userInfo.mobile }}</p>
          <p v-if="userInfo.country && userType != 'INTERPRETER'"><label>국가</label> {{ getCountry(userInfo.country) }}
          </p>
          <p v-if="userInfo.lang1"><label>언어</label> {{ getLanguage(userInfo.lang1) }} <span
              v-if="userInfo.lang2">|</span> {{ getLanguage(userInfo.lang2) }}</p>
          <div v-if="userInfo.memo?.length > 0" class="memo ds_flex al_start"><label>비고</label>
            <div class="memo_log_wrap">
              <div v-for="(memo, index) in userInfo.memo" :key="index">
                <p class="memo_cont">
                  <i class="icon-talk"></i>
                  <span>{{ memo.memo }}</span>
                </p>
                <p class="ds_flex gap8 size_10 sub_text">{{ memo.created_by }} {{ memo.created_at }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="ds_flex jus_between">
          <p class="size_18 b_700">상태</p>
          <div class="user_survey">
            <p class="active"><label>상담일지</label> 0/0</p>
            <p><label>만족도조사</label> 대기중</p>
          </div>
        </div>
        <div class="user_status">
          <p class="status" v-for="status in getStatusList" :key="status.status"
            :class="{ 'active': userInfo.userStatus === status.status }" @click="userInfo.userStatus = status.status">
            <i :class="`icon-${status.icon}`"></i>{{ status.label }}
          </p>
        </div>
        <div class="btn_wrap ds_flex gap18 jus_end">
          <p class="inline_cont_btn cancel_btn" @click="closeModals">취소</p>
          <p class="inline_cont_btn active_btn" @click="changeStatus">저장</p>
        </div>
      </div>
    </div>
    <div class="modal narrow_width" v-if="showScheduleAddModal">
      <div class="modal_overflow">
        <p class="icon_box close" @click="closeModals()">
          <i class="icon-delete sub_text"></i>
        </p>
        <div class="modal_top">
          <div class="text_wrap">
            <p class="modal_title size_28 b_700">스케줄 추가</p>
          </div>
        </div>
        <div class="cont">
          <div class="input_cont">
            <label>부스명</label>
            <p class="primary_text b_700">{{ selectedScheduleData.schedule.ptb }}</p>
          </div>
          <div class="input_cont">
            <label>상담시간</label>
            <p class="primary_text b_700">{{
              `${selectedScheduleData.time.start_time}-${selectedScheduleData.time.end_time}` }}</p>
          </div>
          <!-- <div class="input_cont">
          <label>실제상담시간</label>
          <input type="text" class="fake_textbox"  />
        </div> -->
          <div class="input_cont">
            <label>바이어</label>
            <p class="primary_text b_700">{{ newScheduleInfoBuyer.company != undefined ? newScheduleInfoBuyer.company :
              '' }}</p>
          </div>
          <div class="input_cont">
            <label>통역사</label>
            <p class="primary_text b_700">{{ newScheduleInfoInterpreter.full_name != undefined ?
              newScheduleInfoInterpreter.full_name : '' }}</p>
          </div>
          <div class="input_cont">
            <label>셀러</label>
            <p class="primary_text b_700">{{ newScheduleInfoSeller.company != undefined ? newScheduleInfoSeller.company
              : '' }}</p>
          </div>
          <div class="input_cont">
            <label>현장요원</label>
            <p class="primary_text b_700">{{ newScheduleInfoManager.length === 0 || newScheduleInfoManager.full_name !=
              undefined ? newScheduleInfoManager.full_name : '-' }}</p>
          </div>
          <div class="input_cont video" v-if="is_video_link === 'Y'">
            <label>상담링크</label>
            <a :href="newScheduleInfoVideo" class="main_text b_700">{{ newScheduleInfoVideo }}</a>
          </div>
          <div class="input_cont memo">
            <label>비고</label>
            <div class="memo_log_wrap">
              <textarea placeholder="내용을 작성해주세요." v-model="newMemo" rows="2"></textarea>
              <div v-for="(memo, index) in newScheduleInfoMemo" :key="index">
                <p class="memo_cont">
                  <i class="icon-talk"></i>
                  <span>{{ memo.memo }}</span>
                </p>
                <p class="created ds_flex gap8 size_12">{{ memo.created_by }} {{ memo.created_at }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="btn_wrap ds_flex gap18 jus_end">
          <p class="inline_cont_btn active_btn size_14 b_700" @click="updateScheduleMemo()">비고 저장</p>
        </div>
      </div>
    </div>
  </div>
</template>
