<script>
import { defineComponent } from "vue";
import router from '@/router';

export default defineComponent({
  name: "NotFoundPage",
  setup() {
    const goBack = () => {
      router.go(-1)
    }

    return {
      goBack
    };
  },
});
</script>

<template>
  <div id="NotFoundPage" class="no_header ds_flex al_center jus_center">
    <div class="t_center ds_flex dir_column gap20">
      <h1 class="size_40 b_700">error</h1>
      <p class="sub_text">요청하신 페이지를 찾을 수 없습니다.<br/>입력하신 주소가 정확한지 다시 한번 확인해주세요.</p>
      <p class="main_text cs_pointer" @click="goBack">이전 페이지로 되돌아가기</p>
    </div>
  </div>
</template>
