<script>
import { ref, defineComponent, onMounted, inject, watch, computed, } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  props: {
    smartMatchingModal: Boolean
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    const eventId = computed(() => store.state.eventStore.eventId);
    const smartMatchingStatus = ref(false);


    const startMatchingGuide = ref(false);
    const startMatching = () => {
      startMatchingGuide.value = true
      emit('startMatching'); // 부모의 requestSmartMatching 실행
    };

    watch(
      () => props.requestUserInfo,
      async (newValue) => {
        if (newValue && newValue.userId) {  // newValue가 존재하고 userId가 있을 때만 실행

        }
      },
      { immediate: true }
    );

    const storedTaskId = ref('')
    const revokeSmartMatching = async () => {
      if (confirm("매칭을 중단할까요? 다시 시도하실 수 있습니다.")) { // 사용자가 확인을 누른 경우에만 실행
        try {
          const response = await axios.delete(`/prematching/revoke_smart_matching_schedules?task_id=${storedTaskId.value}`, {
            headers: {
              Authorization: `Bearer ${token.value}`
            }
          });
          if (response.data.result === true) {
            localStorage.removeItem("smart_matching_task_id"); // task_id 삭제
            alert("매칭이 중단되었습니다.");
            router.go(0);
          } else {
            console.log('Delete failed:', response.data.result);
          }
        } catch (error) {
          console.error('Delete failed:', error);
        }
      }
    };


    const closeModals = () => {
      emit('close');
    }


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        storedTaskId.value = localStorage.getItem("smart_matching_task_id");
        if (storedTaskId.value) {
          smartMatchingStatus.value = true;
        }
      }

      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });

    return {
      closeModals,
      startMatching,
      startMatchingGuide,
      smartMatchingStatus,
      revokeSmartMatching
    }
  },
});
</script>

<template>
  <div id="preMatchScheduleTableModal">
    <div class="overlay" v-if="smartMatchingModal"></div>
    <div class="modal status_width" v-if="smartMatchingModal">
      <div class="modal_overflow">
        <p class="size_28 b_700">스마트 매칭</p>
        <div class="content ds_flex gap8 al_start">
          <i class="icon-information_reverse"></i>
          <p class="size_14">스마트 매칭 결과는 셀러가 작성한 기업 소개와 데이터의 정확성과 양에 따라 달라져요. 입력된 정보가 많고 정확할수록 더 적합한 매칭이 가능해요.</p>
        </div>
        <div class="smart_matching" v-if="startMatchingGuide || smartMatchingStatus">
          <p class="main_text b_700">스마트 매칭이 진행중입니다.</p>
          <p class="size_14 secondary_text">매칭 시간은 대략 5~10분정도 소요되며, 해당 팝업을 닫아도 계속 실행됩니다.</p>
          <p class="size_14 secondary_text">매칭이 완료되면 알려드려요!</p>
        </div>
        <div class="btn_wrap ds_flex jus_between">
          <p class="inline_cont_btn cancel_btn b_700" @click="closeModals()">닫기</p>
          <template v-if="smartMatchingStatus">
            <p class="inline_cont_btn stop_matching_btn b_700" @click="revokeSmartMatching()">스마트 매칭 중단</p>
          </template>
          <template v-else>
            <p class="inline_cont_btn active_btn b_700" @click="startMatching()">스마트 매칭하기</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
