<script>
import { ref, onMounted, computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'metaFieldsResult',
  components: {
  },
  setup() {
    // 차트 옵션
    const axios = inject('axios'); // $axios 주입 받기
    const router = useRouter(); // router 인스턴스 사용
    const route = useRoute(); // 현재 라우트 정보 사용

    const selectedCountry = route.query.country; // 국가 값 받기
    const selectedItemGroup = route.query.itemGroup; // 분야 값 받기

    onMounted(() => {

    });

    return {
      selectedCountry,
      selectedItemGroup
    };
  },
};
</script>

<template>
  <div id="metaFieldsResult" class="back_light_gray3">
    <div class="mx_1160">
      <h2>결과 화면</h2>
      <p>선택된 국가: {{ selectedCountry }}</p>
      <p>선택된 분야: {{ selectedItemGroup }}</p>
    </div>
  </div>
</template>
