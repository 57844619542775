<script>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { useRoute } from 'vue-router';
import metaFields from './views/metafields/metafields.vue';
import metaFieldsResult from './views/metafields/metafieldsResult.vue';

export default {
  setup() {
    const windowSize = ref({
      width: 0,
      height: 0,
    });

    // 현재 윈도우 크기 가져오기
    function updateWindowSize() {
      windowSize.value = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }

    onMounted(() => {
      updateWindowSize();
      window.addEventListener('resize', updateWindowSize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWindowSize);
    });

    // 현재 라우트 확인
    const route = useRoute();


    return {
      windowSize,
    };
  },
};
</script>

<template>
  <div>
    <common-metafields-header :windowSize="windowSize"></common-metafields-header>
    <router-view class="main_content" :class="{ 'mobile': windowSize.width < 769 }" :windowSize="windowSize"></router-view>
  </div>
</template>
