<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  props: {
    showScheduleAddModal: Boolean,
    boothList: Object,
    selectedScheduleData: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const excelUploadModalForBooth = ref(false);

    const eventId = computed(() => store.state.eventStore.eventId);
    const is_video_link = computed(() => store.state.eventStore.isActiveOnlineLink);
    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    const closeModals = () => {
      newScheduleInfo.value = {};
      emit('close');
    }

    const newScheduleInfo = ref({}); // 스케줄 개별추가

    // 수정모드 확인
    const updateMode = ref(false);
    const isUpdate = () => {
      if (props.selectedScheduleData.isSchedule) {
        updateMode.value = true;
        newScheduleInfo.value = {
          p_schedule_id: props.selectedScheduleData.userInfo.p_schedule_id,
          piece_event_id: props.selectedScheduleData.userInfo.piece_event_id,
          matching_type: props.selectedScheduleData.userInfo.matching_type,
          ptd: {
            id: props.selectedScheduleData.userInfo.ptd_id,
            date: props.selectedScheduleData.userInfo.ptd_date,
            time: props.selectedScheduleData.userInfo.ptd_time,
          },
          ptb: {
            id: props.selectedScheduleData.userInfo.ptb_id,
            name: props.selectedScheduleData.userInfo.ptb_name,
          },
          buyer: {
            account_id: props.selectedScheduleData.userInfo.buyer_id,
            full_name: props.selectedScheduleData.userInfo.buyer_name,
            full_name_en: props.selectedScheduleData.userInfo.buyer_name_en,
            company: props.selectedScheduleData.userInfo.buyer_company,
            company_en: props.selectedScheduleData.userInfo.buyer_company_en,
          },
          interpreter: {
            account_id: props.selectedScheduleData.userInfo.interpreter_id,
            full_name: props.selectedScheduleData.userInfo.interpreter_name,
            full_name_en: props.selectedScheduleData.userInfo.interpreter_name_en,
            company: props.selectedScheduleData.userInfo.interpreter_company,
            company_en: props.selectedScheduleData.userInfo.interpreter_company_en,
          },
          seller: {
            account_id: props.selectedScheduleData.userInfo.seller_id,
            full_name: props.selectedScheduleData.userInfo.seller_name,
            full_name_en: props.selectedScheduleData.userInfo.seller_name_en,
            company: props.selectedScheduleData.userInfo.seller_company,
            company_en: props.selectedScheduleData.userInfo.seller_company_en,
          },
          manager: {
            account_id: props.selectedScheduleData.userInfo.manager_id,
            full_name: props.selectedScheduleData.userInfo.manager_name,
            full_name_en: props.selectedScheduleData.userInfo.manager_name_en,
          },
        };

      } else {
        updateMode.value = false;
        newScheduleInfo.value = ({ is_auto_video: 1 });
      }
    };

    watch(() => props.selectedScheduleData.isSchedule, isUpdate, { immediate: true });

    // 수정모드일때 스케줄 데이터 불러오는 API
    // async function fetchScheduleDetail() {
    //   try {
    //     const queryParams = new URLSearchParams({
    //       schedule_id: props.selectedScheduleData.isSchedule
    //     });

    //     const url = `/schedule/read_each_schedule?${queryParams.toString()}`;
    //     const response = await axios.get(url, {
    //       headers: {
    //         Authorization: `Bearer ${token.value}`,
    //       },
    //     });

    //     if (response.data != null) {
    //       newScheduleInfo.value = response.data;
    //     } else {
    //       alert('에러');
    //     }
    //   } catch (error) {
    //     console.error('스케줄 불러올때 오류:', error);
    //   }
    // }

    const userList_buyer = ref([]);
    const userList_inter = ref([]);
    const userList_seller = ref([]);
    const userList_fm = ref([]);
    const newMemo = ref('')

    // BIS 사용자 리스트 API - 이벤트에 등록된 BIS만 가져오기
    async function fetchBISList() {
      try {
        const queryParams = new URLSearchParams({
          page: 1,
          limit: 10000
        });
        queryParams.append('account_type_list', 'buyer');
        queryParams.append('account_type_list', 'interpreter');
        queryParams.append('account_type_list', 'seller');
        queryParams.append('event_id', eventId.value);
        queryParams.append('event_type', 'PIECE');
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          const allUsers = response.data.user_list;
          userList_buyer.value = allUsers.filter(user => user.account_type === 'BUYER');
          userList_inter.value = allUsers.filter(user => user.account_type === 'INTERPRETER');
          userList_seller.value = allUsers.filter(user => user.account_type === 'SELLER');
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    // FM 사용자 리스트 API - 이벤트 상관 없이 전체 C2 불러오기
    async function fetchFMList() {
      try {
        const queryParams = new URLSearchParams({
          page: 1,
          limit: 10000
        });
        queryParams.append('account_type_list', 'c2');
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          userList_fm.value = response.data.user_list;
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    // FM 사용자 행사에 추가
    async function addEventFM() {
      try {
        const url = '/user/create_piece_to_accounts';
        const requestData = {
          piece_event_id: eventId.value, // 적절한 값으로 대체
          at_id_list: [
            newScheduleInfo.value.manager.at_id
          ]
        };

        const response = await axios.post(url, requestData, {
          headers: {
            Authorization: `Bearer ${token.value}`,
            'Content-Type': 'application/json' // JSON 데이터 전송을 위한 헤더 설정
          }
        });
        if (response.data.result === true) {
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('FM 행사추가 오류:', error);
      }
    }

    // 개별 스케줄 추가 API
    async function addSchedule() {
      await addEventFM()
      try {
        const url = '/prematching/make_pre_schedule';
        const requestData = {
          piece_event_id: eventId.value,
          ptb_id: props.selectedScheduleData.booth.ptb_id,
          ptd_id: props.selectedScheduleData.time.ptd_id,
        };

        if (newScheduleInfo.value.buyer.account_id) {
          requestData.buyer_id = newScheduleInfo.value.buyer.account_id
        }
        if (newScheduleInfo.value.seller.account_id) {
          requestData.seller_id = newScheduleInfo.value.seller.account_id
        }
        if (newScheduleInfo.value.interpreter.account_id) {
          requestData.interpreter_id = newScheduleInfo.value.interpreter.account_id
        }
        if (newScheduleInfo.value.manager.account_id) {
          requestData.manager_id = newScheduleInfo.value.manager.account_id
        }

        const response = await axios.post(url, requestData, {
          headers: {
            Authorization: `Bearer ${token.value}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.data.result === true) {
          alert("스케줄이 추가되었습니다.");
          // router.go(0);
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 개별추가 오류:', error);
      }
    }


    // 개별 스케줄 수정 API
    async function updateSchedule() {
      try {
        const url = '/prematching/update_pre_schedule';
        const requestData = {
          p_schedule_id: newScheduleInfo.value.p_schedule_id,
          buyer_id: newScheduleInfo.value.buyer.account_id,
          seller_id: newScheduleInfo.value.seller.account_id,
          interpreter_id: newScheduleInfo.value.interpreter.account_id,

        };

        // manager 필드 값이 있는 경우에만 추가
        if (newScheduleInfo.value.manager.account_id && newScheduleInfo.value.manager.full_name) {
          requestData.manager_id = newScheduleInfo.value.manager.account_id
        }

        const response = await axios.put(url, requestData, {
          headers: {
            Authorization: `Bearer ${token.value}`
          }
        });

        if (response.data.result === true) {
          alert("스케줄이 수정되었습니다.")
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 개별추가 오류:', error);
      }
    }


    const deleteSchedule = async () => {
      if (!confirm("삭제하시겠습니까?")) return; // 사용자가 취소하면 함수 종료

      try {
        const { data } = await axios.delete(`/prematching/delete_pre_schedule`, {
          data: { p_schedule_id: newScheduleInfo.value.p_schedule_id },
          headers: { Authorization: `Bearer ${token.value}` }
        });

        if (data.result) {
          alert("삭제되었습니다.");
          router.go(0);
        } else {
          console.log("Delete failed:", data.result);
        }
      } catch (error) {
        console.error("Delete failed:", error);
      }
    };


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        isUpdate()
        fetchBISList()
        fetchFMList()
      }
      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });
    return {
      is_video_link,
      excelUploadModalForBooth,
      closeModals,
      newScheduleInfo,
      userList_buyer,
      userList_inter,
      userList_seller,
      userList_fm,
      addSchedule,
      updateMode,
      updateSchedule,
      deleteSchedule,

    }
  },
});
</script>

<template>
  <div>
    <div id="eventSettingTableModal">
      <div class="overlay" v-if="showScheduleAddModal"></div>
      <div class="modal narrow_width" v-if="showScheduleAddModal">
        <div class="modal_overflow">
          <p class="icon_box close" @click="closeModals()">
            <i class="icon-delete sub_text"></i>
          </p>
          <div class="modal_top">
            <div class="text_wrap">
              <p class="modal_title size_28 b_700">스케줄 추가</p>
            </div>
          </div>
          <div class="cont">
            <div class="input_cont">
              <label>부스명</label>
              <input type="text" class="fake_textbox" disabled
                :value="selectedScheduleData.booth.booth_name ? selectedScheduleData.booth.booth_name : selectedScheduleData.booth.ptb" />
            </div>
            <div class="input_cont">
              <label>상담시간</label>
              <input type="text" class="fake_textbox" disabled
                :value="`${selectedScheduleData.time.start_time}-${selectedScheduleData.time.end_time}`" />
            </div>
            <!-- <div class="input_cont">
          <label>실제상담시간</label>
          <input type="text" class="fake_textbox"  />
        </div> -->
            <div class="input_cont">
              <label>바이어</label>
              <v-select :options="userList_buyer" v-model="newScheduleInfo.buyer" label="company" :reduce="item => item"
                :clearable="false" placeholder="비어있음"></v-select>
            </div>
            <div class="input_cont">
              <label>통역사</label>
              <v-select :options="userList_inter" v-model="newScheduleInfo.interpreter" label="full_name"
                :reduce="item => item" :clearable="false" placeholder="비어있음"></v-select>
            </div>
            <div class="input_cont">
              <label>셀러</label>
              <v-select :options="userList_seller" v-model="newScheduleInfo.seller" label="company"
                :reduce="item => item" :clearable="false" placeholder="비어있음"></v-select>
            </div>
            <div class="input_cont">
              <label>현장요원</label>
              <v-select :options="userList_fm" v-model="newScheduleInfo.manager" :reduce="item => item"
                label="full_name" :clearable="false" placeholder="비어있음"></v-select>
            </div>
          </div>
          <div class="btn_wrap ds_flex gap18 jus_end">
            <template v-if="updateMode">
              <p class="block_main_btn cancel_btn"
                @click="newScheduleInfo.matching_type === 'manual' ? deleteSchedule() : closeModals()">{{
                  newScheduleInfo.matching_type === 'manual' ?
                    '삭제' :
                    '취소' }}</p>
            </template>
            <template v-else>
              <p class="block_main_btn cancel_btn" @click="closeModals()">취소</p>
            </template>
            <p class="block_main_btn active_btn" @click="updateMode ? updateSchedule() : addSchedule()">{{ updateMode ?
              '수정' :
              '저장' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
