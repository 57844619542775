<script>
import { ref, onMounted, computed, inject, watch } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';
import addUserModal from '@/components/admin/addUserModal.vue';
import userInfoModal from '@/components/admin/userDetailModal.vue';

export default {
  name: 'bisSetting',
  components: {
    Pagination,
    subHeader,
    tabHeader,
    addUserModal,
    userInfoModal
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const userName = ref('');
    const token = ref('');
    const activeSub = ref('setting'); // 여기에서 'setting'이라는 값을 정의합니다.
    const activeHeadTab = ref('user'); // 여기에서 'user'이라는 값을 정의합니다.

    const userList = ref([]);
    const page = ref(1); // 페이지 번호
    const limit = ref(10);
    const user_total = ref(0);
    const user_pagination = ref(0);
    function pagination() {
      let totalPages = Math.ceil(user_total.value / limit.value); // 나눈 값을 올림하여 정수로 변환
      user_pagination.value = totalPages <= 1 ? 1 : totalPages; // 1 이하인 경우에는 1로 설정
    }
    const handleClickPage = (page) => {
      fetchAdminList(page, onlyEvent.value);
      // console.log("test")
    }


    const onlyEvent = ref(true)
    async function fetchAdminList(pageIndex, onlyEvent, type) {
      try {
        page.value = pageIndex
        onlyEvent = onlyEvent
        const queryParams = new URLSearchParams({
          page: pageIndex,
          limit: limit.value,
          event_type: 'PIECE'
        });
        queryParams.append('account_type_list', 'A1');
        queryParams.append('account_type_list', 'A2');
        queryParams.append('account_type_list', 'B1')
        queryParams.append('account_type_list', 'B2');
        queryParams.append('account_type_list', 'C1');
        queryParams.append('account_type_list', 'C2')
        if (onlyEvent) {
          queryParams.append('event_id', eventId.value);
        }
        if (searchBy.value && searchText.value) {
          queryParams.append('search_by', searchBy.value);
          queryParams.append('search_text', searchText.value);
        }
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          userList.value = response.data.user_list; // 이벤트 리스트를 저장합니다.
          user_total.value = response.data.count_info.total; // 이벤트 리스트를 저장합니다.
          pagination();
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    const setOnlyEvent = (status) => {
      onlyEvent.value = status;
      page.value = 1;
      fetchAdminList(page.value, onlyEvent.value).then(() => {
        console.log("Data fetched successfully!");
      }).catch(error => {
        console.error("Error fetching data:", error);
      });
    }

    const showUserAdminModal = ref(false);
    const showAdminInfoModal = ref(false)
    const closeAllModals = (type) => {
      showUserAdminModal.value = false;
      showAdminInfoModal.value = false;
    };
    const selectedUser = ref({})
    const showAdminInfo = (user_id, user_type, pta_id) => {
      selectedUser.value.user_id = user_id; // 선택된 사용자 정보 설정
      selectedUser.value.user_type = user_type; // 선택된 사용자 정보 설정
      selectedUser.value.pta_id = pta_id; // 선택된 사용자 정보 설정
      showAdminInfoModal.value = true; // 사용자 정보 모달 표시
    };

    // 모달 오픈 시 body 스크롤 잠금
    const modals = [showUserAdminModal, showAdminInfoModal]
    watch(
      () => modals.map(modal => modal.value),
      (modalStates) => {
        const isAnyModalOpen = modalStates.some(state => state === true);
        document.body.style.overflow = isAnyModalOpen ? 'hidden' : '';
      },
      { immediate: true, deep: true }
    );

    // FUNCTION BAR
    const searchBy = ref('name_or_email')
    const searchText = ref('')
    const searchByLabel = (searchBy) => {
      switch (searchBy) {
        case 'name_or_email':
          return "이름/이메일"
        case 'company':
          return "회사명"
        case 'mobile':
          return "연락처"
        default:
          return "-"
      }
    }
    //툴팁
    const tooltipState = ref({}); // 툴팁 상태를 저장할 반응형 객체
    const initializeTooltips = () => {
      const length = userList.value.length;
      for (let index = 0; index < length; index++) {
        tooltipState.value[index] = ref(false); // 각 유저의 툴팁 상태를 ref로 초기화
      }
    };

    const showTooltip = (index) => {
      tooltipState.value[index] = true;
    };

    // 툴팁을 숨기는 메서드
    const hideTooltip = (index) => {
      tooltipState.value[index] = false;
    };

    const pushUserToEvent = async (at_id) => {
      try {
        // Axios 요청을 보냅니다.
        const response = await axios.post('/user/create_piece_to_accounts', {
          "piece_event_id": eventId.value,
          "at_id_list": [at_id]
        }, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          alert("행사에 포함되었습니다.")
          // router.go()
        } else {
          alert('행사에 포함하지 못했습니다.');
        }
      } catch (error) {
        console.error(error);
      }
    }

    const adminDownload = async () => {
      try {
        // URL 동적 구성: onlyEvent가 true일 때만 eventId 추가
        const queryParams = new URLSearchParams({
          limit: user_total.value,
          account_type_list: 'a1',
        });
        queryParams.append('account_type_list', 'a2');
        queryParams.append('account_type_list', 'b1');
        queryParams.append('account_type_list', 'b2');
        queryParams.append('account_type_list', 'c1');
        queryParams.append('account_type_list', 'c2');

        if (onlyEvent.value) {
          queryParams.append('piece_event_id', eventId.value);
        }
        // searchBy와 searchText가 있을 경우 추가
        if (searchBy.value && searchText.value) {
          queryParams.append('search_by', searchBy.value);
          queryParams.append('search_text', searchText.value);
        }
        // 완성된 URL 구성
        const url = `/user/download_accounts_excel?${queryParams.toString()}`;

        // 파일 다운로드 요청
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
          responseType: 'blob', // 파일 다운로드를 위한 설정
        });

        // 파일 객체 생성
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // 파일 URL 생성
        const downloadURL = URL.createObjectURL(blob);

        // 파일 다운로드 링크 생성 및 트리거
        const link = document.createElement('a');
        link.href = downloadURL;
        link.setAttribute('download', 'admin_user_list.xlsx'); // 파일 이름 설정
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 메모리 해제
        URL.revokeObjectURL(downloadURL);
      } catch (error) {
        console.error('파일 다운로드 중 오류 발생:', error);
      }
    };

    function onSearch() {
      if (searchText.value) {
        fetchAdminList(page.value, onlyEvent.value);
      }
    }


    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        userName.value = store.state.userStore.userName;
        token.value = store.state.userStore.token;

        fetchAdminList(page.value, onlyEvent.value)
      }
    });
    return {
      isAuthenticated,
      userName,
      activeSub,
      activeHeadTab,
      showUserAdminModal,
      closeAllModals,
      userList,
      page,
      handleClickPage,
      user_total,
      user_pagination,
      setOnlyEvent,
      onlyEvent,
      showAdminInfoModal,
      selectedUser,
      showAdminInfo,
      searchBy,
      searchText,
      searchByLabel,
      adminDownload,
      onSearch,
      showTooltip,
      tooltipState,
      hideTooltip,
      pushUserToEvent
    };
  },
}

</script>

<template>
  <div id="adminSetting" class="back_light_gray2">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"></tab-header>
        <p class="icon_cont_btn" @click="showUserAdminModal = true"><i class="icon-add_person"></i>관리자 추가</p>
      </div>
      <div class="tables_wrap user_table">
        <div class="function_bar">
          <div class="row">
            <p class="b_700">어드민 리스트</p>
            <div class="cont_wrap ds_flex gap18 al_center">
              <p class="icon_box" @click="adminDownload"><i class="icon-download"></i></p>
              <div class="fake_textbox">
                <select v-model="searchBy">
                  <option value="name_or_email">이름/이메일</option>
                  <!-- <option value="company">회사명</option> -->
                  <option value="mobile">연락처</option>
                </select>
                <input type="text" v-model="searchText" :placeholder="`${searchByLabel(searchBy)} 검색어 입력`"
                  @keyup.enter="onSearch()" />
                <template v-if="searchText != ''">
                  <i class="icon-delete secondary_text cs_pointer"
                    @click="searchText = ''; fetchAdminList(page, onlyEvent)"></i>
                </template>
                <template v-else>
                  <i class="icon-search secondary_text cs_pointer"></i>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="btn_wrap">
              <p class="inline_cont_btn" :class="{ 'active': onlyEvent }" @click="setOnlyEvent(true)">이 행사만 보기</p>
              <p class="inline_cont_btn" :class="{ 'active': !onlyEvent }" @click="setOnlyEvent(false)">전체 행사 보기</p>
            </div>
          </div>
          <p class="size_14 sub_text">{{ user_total }}개의 결과</p>
        </div>
        <div class="user_table_body">
          <div class="user_table_body_th">
            <p class="narrow">유형</p>
            <p class="wide">이름</p>
            <p class="wide">아이디</p>
            <p class="wide">이메일</p>
            <p>연락처</p>
            <p v-if="!onlyEvent" style="width:28px"></p>
          </div>
          <div class="user_table_body_cont">
            <div class="user_table_body_tr" v-for="user, index in userList" :key="user.account_id"
              @click="showAdminInfo(user.account_id, user.account_type, user.pta_id)">
              <p class="narrow"><span :class="['type_bdg', user.account_type]">{{ user.account_type }}</span></p>
              <p class="wide">{{ user.full_name }}</p>
              <p class="wide">{{ user.email }}</p>
              <p class="wide">{{ user.contact_email }}</p>
              <p>{{ user.mobile }}</p>
              <p v-if="!onlyEvent" class="icon_box narrow" @mouseover="showTooltip(index)"
                @mouseleave="hideTooltip(index)" @click.stop="pushUserToEvent(user.at_id)">
                <i class="icon-plus"></i>
                <span class="tooltip" :class="{ 'active': tooltipState[index] }">이 행사에 포함하기</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <pagination :currentPage="page" :totalPages="user_pagination" :handleClickPage="handleClickPage"></pagination>
      <add-user-modal :show-user-admin-modal="showUserAdminModal" @close="closeAllModals"></add-user-modal>
      <user-info-modal :show-admin-info-modal="showAdminInfoModal" :account-info="selectedUser"
        @close="closeAllModals"></user-info-modal>
    </div>
  </div>
</template>
