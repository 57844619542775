<script>
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import axios from 'axios'; // axios는 일반적으로 직접 가져와 사용합니다.

export default {
  name: 'subHeader',
  props: {
    window: Object,
    activeSub: String
  },
  setup(props, { emit }) {
    const store = useStore(); // Vuex 스토어 접근
    const route = useRoute(); // vue-router의 현재 route 정보 접근
    const activesub = ref(props.activeSub)
    const eventId = computed(() => store.state.eventStore.eventId);
    const userType = computed(() => store.state.userStore.accountType);
    const isActivePreMatch = computed(() => store.state.eventStore.isActivePreMatch);
    const preMatchStatus = computed(() => store.state.eventStore.preMatchStatus);

    const isUserManagementActive = computed(() => {
      const currentPath = route.path;
      return currentPath === '/admin/event/setting/bis' || currentPath === '/admin/event/setting/admin';
    });

    const isQuestionActive = computed(() => {
      const currentPath = route.name;
      return currentPath === 'questionSetting' || currentPath === 'newQuestionDetail' || currentPath === 'questionDetail' || currentPath === 'surveySetting' || currentPath === 'satisfySetting';
    });

    const isAttendActive = computed(() => {
      const currentPath = route.name;
      return currentPath === 'operateAttendInterpreter' || currentPath === 'operateAttendSeller';
    });

    const isSurveyActive = computed(() => {
      const currentPath = route.name;
      return currentPath === 'surveyRecordInterpreter' || currentPath === 'surveyRecordBuyer' || currentPath === 'surveyRecordSeller';
    });

    const isSatisfyActive = computed(() => {
      const currentPath = route.name;
      return currentPath === 'satisfyRecordInterpreter' || currentPath === 'satisfyRecordBuyer' || currentPath === 'satisfyRecordSeller';
    });

    return {
      userType,
      activesub,
      eventId,
      isUserManagementActive,
      isQuestionActive,
      isAttendActive,
      isSurveyActive,
      isSatisfyActive,
      isActivePreMatch,
      preMatchStatus
    }
  }
}
</script>


<template>
  <div class="sub_header">
    <!-- 설정 -->
    <div class="sub_header_cont" v-if="activesub === 'setting'">
      <router-link custom v-slot="{ navigate, href, isActive }"
        :to="{ name: 'eventSetting', params: { eventId: eventId } }">
        <a :href="href" :class="{ active: isActive }" @click="navigate">상담회 관리</a>
      </router-link>
      <router-link custom v-slot="{ navigate, href }" to="/admin/event/setting/bis">
        <a :href="href" :class="{ active: isUserManagementActive }" @click="navigate">사용자 관리</a>
      </router-link>
      <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'preMatchSetting' }"
        v-if="isActivePreMatch === 'Y' && (userType === 'A1' || userType === 'A2')">
        <a :href="href" :class="{ active: isActive }" @click="navigate">사전주선 관리</a>
      </router-link>
      <router-link custom v-slot="{ navigate, href, isActive }"
        :to="{ name: 'eventSettingTables', params: { eventId: eventId } }" v-if="preMatchStatus !== 1">
        <a :href="href" :class="{ active: isActive }" @click="navigate">운영 스케줄 관리</a>
      </router-link>
      <router-link custom v-slot="{ navigate, href }" :to="{ name: 'questionSetting', params: { eventId: eventId } }"
        v-if="userType === 'A1' || userType === 'A2'">
        <a :href="href" :class="{ active: isQuestionActive }" @click="navigate">질문과 설문 관리</a>
      </router-link>
    </div>
    <!-- 영접 -->
    <div class="sub_header_cont" v-if="activesub === 'reception'">
      <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'reception' }">
        <a :href="href" :class="{ active: isActive }" @click="navigate">영접현황</a>
      </router-link>
    </div>
    <!-- 운영 -->
    <div class="sub_header_cont" v-if="activesub === 'operate'">
      <router-link custom v-slot="{ navigate, href }" :to="{ name: 'operateAttendInterpreter' }">
        <a :href="href" :class="{ active: isAttendActive }" @click="navigate">출석표</a>
      </router-link>
      <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'operateTables' }">
        <a :href="href" :class="{ active: isActive }" @click="navigate">현장운영</a>
      </router-link>
      <!-- <router-link custom v-slot="{ navigate, href, isActive }"
        :to="{ name: 'operateLiveMatch', params: { eventId: eventId } }">
        <a :href="href" :class="{ active: isActive }" @click="navigate">현장매칭</a>
      </router-link> -->
    </div>
    <!-- 상담기록 -->
    <div class="sub_header_cont" v-if="activesub === 'survey'">
      <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'surveyDashboard' }">
        <a :href="href" :class="{ active: isActive }" @click="navigate">대시보드</a>
      </router-link>
      <router-link custom v-slot="{ navigate, href }" :to="{ name: 'surveyRecordInterpreter' }">
        <a :href="href" :class="{ active: isSurveyActive }" @click="navigate">상담일지</a>
      </router-link>
      <router-link custom v-slot="{ navigate, href }" :to="{ name: 'satisfyRecordBuyer' }">
        <a :href="href" :class="{ active: isSatisfyActive }" @click="navigate">사전 설문</a>
      </router-link>
    </div>
    <!--공지사항-->
    <div class="sub_header_cont" v-if="activesub === 'sendNotice'">
      <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'sendNotice' }">
        <a :href="href" :class="{ active: isActive }" @click="navigate">공지사항</a>
      </router-link>
      <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'sendMessage' }">
        <a :href="href" :class="{ active: isActive }" @click="navigate">메세지 전송</a>
      </router-link>
    </div>
  </div>
</template>
