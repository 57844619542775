<script>
import { ref, defineComponent, onMounted, inject, watch, computed, onUnmounted } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  props: {
    showEventModal: Boolean,
    // activeAccountInfo: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');

    const eventListForBIS = ref([])
    const eventListForBISOnEvents = ref([]);
    const eventListForBISPreMatching = ref([]);
    async function fetchBISEventList() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/event/read_list_for_bis', {
          params: {
            page: 1,
            limit: 10,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          eventListForBIS.value = response.data.response_data.event_list;

          const statusOneEvents = eventListForBIS.value.filter(event => event.status === 1);
          const statusZeroEvents = eventListForBIS.value.filter(
            event => event.status === 0 && event.is_pre_arranged === 'Y'
          );

          // 필요한 배열에 값 저장
          eventListForBISOnEvents.value = statusOneEvents; // status가 1인 이벤트 저장
          eventListForBISPreMatching.value = statusZeroEvents; // status가 0인 이벤트 저장
        } else {
          alert('이벤트를 불러오지 못했습니다.');
        }
      } catch (error) {
        // 요청이 실패한 경우
        console.error('이벤트 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }
    const closeModals = () => {
      eventListForBIS.value = []
      emit('close');
    }

    async function routeForBIS(type, index) {
      if (type === 'onGoing') {
        store.dispatch('eventStore/updateEvent', eventListForBISOnEvents.value[index].event_id);
        store.commit('eventStore/setEventTitle', eventListForBISOnEvents.value[index].title);
        store.dispatch('eventStore/updateEvent', {
          eventId: eventListForBISOnEvents.value[index].event_id,
          eventTitle: eventListForBISOnEvents.value[index].title
        });
        store.commit("userStore/login", true);
        router.push({ name: 'BISDashboard' });
      } else if (type === 'preMatch') {
        store.dispatch('eventStore/updateEvent', eventListForBISPreMatching.value[index].event_id);
        store.commit('eventStore/setEventTitle', eventListForBISPreMatching.value[index].title);
        store.dispatch('eventStore/updateEvent', {
          eventId: eventListForBISOnEvents.value[index].event_id,
          eventTitle: eventListForBISOnEvents.value[index].title
        });
        store.commit('eventStore/isActiveMenu', { is_pre_arranged: eventListForBISPreMatching.value[index].is_pre_arranged }); // 필요한 값만 전달
        store.commit('eventStore/setEventStatus', eventListForBISPreMatching.value[index].status); // 필요한 값만 전달
        store.commit("userStore/login", true);
        router.push({ name: 'preMatchDashboard' });
      } else {
        store.dispatch('eventStore/updateEvent', eventList.value[index].event_id);
        store.commit('eventStore/setEventTitle', eventList.value[index].title);
        store.dispatch('eventStore/updateEvent', {
          eventId: eventListForBISOnEvents.value[index].event_id,
          eventTitle: eventListForBISOnEvents.value[index].title
        });
        store.commit('eventStore/isActiveMenu', { is_pre_arranged: eventList.value[index].is_pre_arranged }); // 필요한 값만 전달
        store.commit('eventStore/setEventStatus', eventList.value[index].status); // 필요한 값만 전달
        store.commit("userStore/login", true);
        router.push({ name: 'BISDashboard' });
      }
      closeModals()
    }

    const selectEvent = (selectEventId, selectEventTitle, selectMasterId) => {
      store.dispatch('eventStore/updateEvent', selectEventId);
      store.commit('eventStore/setMasterId', selectMasterId);
      store.commit('eventStore/setEventTitle', selectEventTitle);
      store.dispatch('eventStore/updateEvent', {
        eventId: eventListForBISOnEvents.value[index].event_id,
        eventTitle: eventListForBISOnEvents.value[index].title
      });
    }

    watch(() => props.showEventModal, (newValue, oldValue) => {
      // 속성 이름을 올바르게 참조
      // eventListForBIS.value = { ...newValue };
      fetchBISEventList();
    }, { deep: true });


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        fetchBISEventList()
      }
    });

    return {
      eventListForBIS,
      closeModals,
      token,
      eventId,
      routeForBIS,
      selectEvent,
      eventListForBISOnEvents,
      eventListForBISPreMatching
    }
  },
});
</script>

<template>
  <div id="selectEvent">
    <div class="overlay" v-if="showEventModal"></div>
    <transition name="slide-up">
      <div class="bottom_sheet" v-if="showEventModal">
        <div class="sheet_header">
          <p class="icon_box" @click="closeModals"><i class="icon-delete"></i></p>
        </div>
        <div class="sheet_content">
          <p class="size_20 b_700">참여중인 상담회 목록이에요</p>
          <div class="event_list_wrap" v-if="eventListForBISPreMatching.length > 0">
            <p class="b_700 SELLER_text">사전주선 신청</p>
            <div class="event_list" v-for="item, index in eventListForBISPreMatching" :key="item.event_id"
              @click="routeForBIS('preMatch', index)">
              <p>{{ item.title }}</p>
            </div>
          </div>
          <div class="event_list_wrap" v-if="eventListForBISOnEvents.length > 0">
            <p class="b_600" v-if="eventListForBISOnEvents.length > 0">참여중인 상담회</p>
            <div class="event_list" v-for="item, index in eventListForBISOnEvents" :key="item.event_id"
              @click="routeForBIS('onGoing', index)">
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- <div id="selectEvent">
    <div class="overlay" v-if="showEventModal"></div>
    <transition name="slide-up">
      <div class="bottom_sheet" v-if="showEventModal">
        <div class="sheet_header">
          <p class="icon_box" @click="closeModals"><i class="icon-delete"></i></p>
        </div>
        <div class="sheet_content">
          <p class="size_20 b_700">다른 상담회 보기</p>
          <p class="size_14 sub_text">현재 참여중인 다른 상담회 리스트예요. </p>
          <div class="event_list_wrap">
            <div class="event_list" v-for="item in eventListForBIS" :key="item.event_id"
              @click="selectEvent(item.event_id, item.title, item.master_event_id)">
              <p>{{ item.title }}</p><i class="icon-checked" :class="{ 'active': item.event_id === eventId }"></i>
            </div>
          </div>
          <div class="block_main_btn active_btn b_600" @click="routeForBIS()">선택한 상담회로 전환하기</div>
        </div>
      </div>
    </transition>
  </div> -->
</template>
