<script>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'BISNotice',
  components: {
  },
  props: {
    user: Object,
    event: Object,
  },
  setup(props) {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const token = ref('');
    const router = useRouter(); // useRouter를 사용하여 router 인스턴스를 가져옵니다.
    const route = useRoute();
    const { userName, formattedUserType, userType, accountStatus } = props.user;
    const { selectDate, scheduleDate, eventId } = props.event;

    //공지사항
    const noticeList = ref([])

    async function getNotice() {
      try {
        const response = await axios.get('/notice/read_notice_for_bis', {
          params: {
            'piece_event_id': eventId.value,
            'date': selectDate.value,
            'notice_type': userType.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          noticeList.value = response.data.notice_list.reverse() // 배열을 역순으로 정렬

        } else {
          console.log("공지 로드 실패")
        }
      } catch (error) {
      }
    }

    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      // 날짜와 시간을 별도로 반환
      const formattedDate = `${year}-${month}-${day}`;
      const formattedTime = `${hours}:${minutes}`;

      return { formattedDate, formattedTime };
    }

    const isPreMatching = computed(() => route.query.isPreMatching === 'true');

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        await getNotice()
      }
    });

    return {
      router,
      noticeList,
      formatDate
    };
  },
};
</script>

<template>
  <div>
    <div class="notice">
      <div class="page_title ds_flex jus_between al_center">
        <div class="ds_flex gap8 al_center">
          <p class="icon_box" @click="router.go(-1)"><i
              class="icon-down_arrow rotate90 size_28"></i></p>
          <p class="size_22 b_700">공지사항</p>
        </div>
      </div>
      <div class="notice_cont_wrap">
        <template v-if="noticeList.length > 0">
          <div class="notice_cont" v-for="(item, index) in noticeList" :key="item.notice_id">
            <span class="notice_bdg" v-if="index === 0">NEW</span>
            <p class="size_15 b_600 notice_text">{{ item.notice_text }}</p>
            <div class="ds_flex jus_between">
              <p class="size_12 sub_text">{{ formatDate(item.created_at).formattedDate }}</p>
              <p class="size_12 sub_text">{{ formatDate(item.created_at).formattedTime }}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <p class="t_center sub_text">공지사항이 없습니다.</p>
        </template>
      </div>
    </div>
  </div>
</template>
