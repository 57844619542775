<script>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import bisatStatus from '@/assets/js/bisatStatus.json';
import bisatCountry from '@/assets/js/bisatCountry.json';
import userDetailForHome from '@/components/bis/userDetailForHome.vue';
import userQrForCommon from '@/components/bis/userQrForCommon.vue';
import selectSatisfaction from '@/components/bis/selectSatisfaction.vue';
import { useToast } from 'vue-toastification';

export default {
  name: 'preMatchDashboard',
  components: {
    userDetailForHome,
    userQrForCommon,
    selectSatisfaction,
  },
  props: {
    user: Object,
    event: Object,
  },
  setup(props) {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const token = ref('');
    const toast = useToast(); // Toast 호출 함수
    const router = useRouter(); // useRouter를 사용하여 router 인스턴스를 가져옵니다.
    const { userName, userId, formattedUserType, userType, accountStatus, userCompany } = props.user;
    const { selectDate, scheduleDate, eventId, masterId } = props.event;

    // eventDate가 변경될 때마다 함수를 재호출하도록 watch 설정
    watch(selectDate, (newDate, oldDate) => {
      if (newDate !== oldDate) {
        console.log(`eventDate changed from ${oldDate} to ${newDate}`);
        fetchEvent();
      }
    });

    //event Info
    const eventInfo = ref({})
    const scheduleInfo = ref({})

    // 이벤트 전체 데이터
    const filteredScheduleList = ref({})
    async function fetchEvent() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/event/read_event_info_for_bis', {
          params: {
            piece_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          eventInfo.value = response.data.event; // 이벤트 리스트를 저장합니다.
          filteredScheduleList.value = response.data.schedule.schedule_list.filter(schedule =>
            schedule.date === selectDate.value
          );
        } else {
          console.log("이벤트 로드 실패")
        }
      } catch (error) {
        // 요청이 실패한 경우
        console.error('이벤트를 로드하는 중에 오류가 발생했습니다:', error);
      }
    }

    const makeCall = (number) => {
      window.location.href = `tel:${number}}`;
    };

    // 사전주선 일회 입력 정보 판단
    const existPreMatchExtraInfo = ref(false)
    async function fetchPreMatchData() {
      try {
        // 첫 번째 API 호출
        const response1 = await axios.get('/prematching/read_pre_account_id_list', {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 결과 확인
        if (response1.data.result === true) {
          // piece_event_id가 eventId.value와 같은 항목 필터링
          const matchingAccount = response1.data.p_account_id_list.find(
            (item) => item.piece_event_id === eventId.value
          );

          // 매칭된 p_account_id가 있을 경우
          if (matchingAccount) {
            const { p_account_id } = matchingAccount;

            // 두 번째 API 호출
            const response2 = await axios.get('/prematching/read_pre_account', {
              params: {
                p_account_id, // 필터링된 p_account_id 사용
              },
              headers: {
                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
              },
            });

            if (response2.data.result === true) {
              if (response2.data.p_account.keyword && response2.data.p_account.company_file
                && response2.data.p_account.company_explain && response2.data.p_account.target_country) {
                existPreMatchExtraInfo.value = true
              }
            } else {
              console.log('이벤트 로드 실패');
            }
          } else {
            console.log('eventId에 해당하는 데이터가 없습니다.');
          }
        } else {
          console.log('이벤트 리스트 로드 실패');
        }
      } catch (error) {
        console.error('API 호출 중 오류 발생:', error);
      }
    }
    const preMatchRoute = computed(() => {
      return existPreMatchExtraInfo.value
        ? { name: 'preMatchingWrap' } // existPreMatchExtraInfo가 true일 때
        : { name: 'preMatchingExtraInfo' }; // false일 때
    });



    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        await fetchEvent()
        await fetchPreMatchData()
      }
    });

    return {
      router,
      userCompany,
      eventInfo,
      existPreMatchExtraInfo,
      preMatchRoute
    };
  },
};
</script>

<template>
  <div id="preMatchingDashBoard" class="back_light_gray2">
    <div class="event_box sec2">
      <p class="size_18 b_700 attend">{{ userCompany }}님<br />상담 스케줄 신청이 가능해요 </p>
      <router-link class="icon_cont_btn b_600" :to="preMatchRoute">매칭 신청하기 </router-link>
    </div>
    <p class="bis_title">상담회 정보</p>
    <div class="event_box sec3">
      <p class="b_700">{{ eventInfo.piece_event_title }}</p>
      <p class="event_info ds_flex al_center gap6">
        <span class="icon_box"><i class="icon-date"></i></span>
        <span class="size_14 secondary_text">{{ eventInfo.start_date + " ~ " + eventInfo.end_date }}</span>
      </p>
      <p class="event_info ds_flex al_center gap6">
        <span class="icon_box"><i class="icon-location"></i></span>
        <span class="size_14 secondary_text">{{ eventInfo.place }}</span>
      </p>
      <div class="pm_info ds_flex jus_between" v-for="item, index in eventInfo.pm_list" :key="index">
        <div class="ds_flex gap8 al_center">
          <i class="icon_box icon-pm"></i>
          <p class="size_14 b_700">{{ item.full_name }}</p>
        </div>
        <div class="ds_flex gap8 al_center" @click="makeCall(item.mobile)">
          <p class="size_14 secondary_text">{{ item.mobile }}</p>
          <i class="icon-call sub_text"></i>
        </div>
      </div>
    </div>
  </div>
</template>
