<script>
import { ref, onMounted, computed, inject } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import subHeader from '@/components/admin/subHeader.vue';
import tabHeader from '@/components/admin/tabHeader.vue';
import { useToast } from 'vue-toastification';

export default {
  name: 'preMatchSetting',
  components: {
    Pagination,
    subHeader,
    tabHeader,
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const isAuthenticated = ref(false);
    const toast = useToast(); // Toast 호출 함수
    const eventId = computed(() => store.state.eventStore.eventId);
    const userName = computed(() => store.state.eventStore.userName);
    const userType = computed(() => store.state.userStore.accountType);
    const token = ref('');
    const activeSub = 'setting';
    const activeHeadTab = 'preMatch';

    // 사전주선 기본정보
    const preMatchSetting = ref({})

    // 사전주선 정보 불러오기
    async function fetchPreMatchData() {
      try {
        const response = await axios.get('/prematching/read_prematching', {
          params: { piece_event_id: eventId.value },
          headers: { Authorization: `Bearer ${token.value}` },
        });

        if (response.data.result === true) {
          preMatchSetting.value = response.data.prematching_data;
          preMatchSetting.value.request_link = `http://localhost:8080/bis/preMatchEventRequestCheck/${eventId.value}`
          preMatchSetting.value.buyer_total = 1

          // pre_arranged_chance가 없으면 fetchDateTime 실행
          if (!preMatchSetting.value.pre_arranged_chance) {
            await fetchDateTime();
          }
        } else {
          console.log("이벤트 로드 실패");
        }
      } catch (error) {
        console.error("사전주선 데이터 불러오기 오류:", error);
      }
    }
    // 등록된 바이어 수
    async function fetchBISList() {
      try {
        const queryParams = new URLSearchParams({
          page: 1,
          limit: 100000,
          account_type_list: 'BUYER',
          event_id: eventId.value,
          event_type: 'PIECE'
        });

        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });
        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          preMatchSetting.value.buyer_total = response.data.count_info.total;

        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    const eventDateTime = ref([]);
    async function fetchDateTime() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          limit: 30,
        });

        const url = `/schedule/read_datetime?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          // 날짜별 그룹화
          const groupedData = response.data.datetime.reduce((acc, item) => {
            const date = item.date;
            const timeRange = `${item.start_time.slice(0, 5)}~${item.end_time.slice(0, 5)}`;

            let existingEntry = acc.find(entry => entry.date === date);
            if (!existingEntry) {
              existingEntry = { date, times: [], length: 0 };
              acc.push(existingEntry);
            }

            existingEntry.times.push(timeRange);
            existingEntry.length += 1;

            return acc;
          }, []);

          eventDateTime.value = groupedData;
          preMatchSetting.value.pre_arranged_chance = eventDateTime.value[0].length
          console.log(preMatchSetting.value.pre_arranged_chance)
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }



    // 복사기능
    const copyText = async () => {
      try {
        if (preMatchSetting.value.request_link) {
          await navigator.clipboard.writeText(preMatchSetting.value.request_link);
          // 복사 성공 메시지를 잠시 보여줌
          setTimeout(() => {
            toast.success('주소가 복사되었습니다.');
          }, 10);
        } else {
          alert('복사할 내용을 입력해주세요.');
        }
      } catch (error) {
        console.error('복사 실패:', error);
        alert('복사하는 데 문제가 발생했습니다.');
      }
    };

    // 인풋 숫자 입력 제한
    const enforceNumericInput = (event, fieldName) => {
      const input = event.target.value;
      preMatchSetting.value[fieldName] = input.replace(/\D/g, '');
    };

    // 부스 업로드 샘플파일 다운로드 API
    const downloadSampleBooth = async () => {
      try {
        const response = await axios.get('/schedule/download_booth_sample_for_pre', {
          params: { piece_event_id: eventId.value },
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
          responseType: 'blob' // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
        });
        // 파일 객체 생성
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // 파일 URL 생성
        const url = URL.createObjectURL(blob);

        // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'booth_upload_sample.xlsx'); // 적절한 파일 이름과 확장자로 변경
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 메모리 해제
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('파일 다운로드 중 오류 발생:', error);
      }
    };


    const uploadedFileNameForBooth = ref('')
    // 부스 업로드 파일 관리
    const handleFileUploadForBooth = (event) => {
      const fileForBooth = event.target.files[0]; // 업로드된 파일 객체 가져오기
      if (fileForBooth) {
        uploadedFileNameForBooth.value = fileForBooth.name; // 업로드된 파일명 설정
        console.log('Uploaded file:', fileForBooth);
      }
    };

    // 부스 업로드 API
    const uploadExcelForBooth = async () => {
      try {
        const fileForBooth = document.getElementById('file-upload-booth').files[0]; // 파일 가져오기

        if (!fileForBooth) {
          console.error('No file selected.');
          return;
        }

        const formData = new FormData();
        formData.append('file', fileForBooth);
        formData.append('piece_event_id', eventId.value);

        const response = await axios.post('/schedule/upload_booth_excel', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          }
        });

        console.log('Upload response:', response.data);
        if (response.data.result === true) {
          alert("바이어와 부스 매칭이 완료되었습니다. 사전 테이블 메뉴에서 확인 가능합니다.")
          router.go()
        } else {
          alert('부스 업로드 에러');
        }

      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };



    // 정보 저장 및 수정
    const createPrematching = async () => {
      try {
        // Axios 요청을 보냅니다.
        const response = await axios.post('/prematching/create_prematching', null, {
          params: {
            piece_event_id: eventId.value, // 쿼리 문자열로 데이터 전송
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
        } else {
          alert('저장하지 못했습니다.');
        }
      } catch (error) {
        console.error('오류 발생:', error);
      }
    };


    const updatePreMatching = async () => {
      if (!preMatchSetting.value.pre_arranged_goal) {
        alert("셀러 참여 목표치를 입력하세요.")
      } else if (!preMatchSetting.value.pre_arranged_chance) {
        alert("셀러 매칭 신청 기회를 입력하세요.")
      } else {
        const data = {
          piece_event_id: eventId.value,
          pre_arranged_goal: preMatchSetting.value.pre_arranged_goal,
          pre_arranged_chance: preMatchSetting.value.pre_arranged_chance,
        };
        const url = '/prematching/update_prematching';
        try {
          const response = await axios.put(url, data, {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          });
          if (response.data.result === true) {
            await createPrematching()
            toast.success('저장되었습니다.');
            setTimeout(() => {
              router.go(0)
            }, 2000);
          } else {
            alert('에러');
          }
        } catch (error) {
          console.error('상태 업데이트 오류:', error);
        }
      }
    }

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        token.value = store.state.userStore.token;
        await fetchBISList()
        await fetchPreMatchData()
      }
    });
    return {
      isAuthenticated,
      userName,
      userType,
      activeSub,
      tabHeader,
      activeHeadTab,
      preMatchSetting,

      copyText,

      updatePreMatching,
      enforceNumericInput,
      eventDateTime,
      handleFileUploadForBooth,
      uploadExcelForBooth,
      uploadedFileNameForBooth,
      downloadSampleBooth,
    };
  },
}

</script>

<template>
  <div id="preMatchSetting">
    <sub-header :activeSub="activeSub"></sub-header>
    <div class="mx_1160">
      <div class="header_tab_wrap">
        <tab-header :activeSub="activeSub" :activeHeadTab="activeHeadTab"
          :disableTab="preMatchSetting.buyer_total > 0 ? false : true"></tab-header>
      </div>
      <div class="cont_wrap ds_flex dir_column gap36 w100 al_start">
        <div class="ds_flex gap8 info_box">
          <i class="icon-information_reverse"></i>
          <div>
            <p class="size_12 b_700">1. 사전매칭을 진행하려면 설정 페이지를 먼저 완료해야 합니다.</p>
            <p class="size_12 b_700">2. [사전 테이블 > 운영관리 스케줄 등록]을 완료하면 운영관리 페이지가 활성화됩니다.</p>
          </div>
        </div>
        <div class="field_wrap w100">
          <label>참여자 신청 링크</label>
          <div class="ds_flex al_center jus_between back_light_gray input_wrap">
            <p class="secondary_text">{{ preMatchSetting.request_link }}</p>
            <p class="inline_cont_btn cancel_btn size_14" @click="copyText">복사</p>
          </div>
        </div>
        <div class="field_wrap w100">
          <label>등록된 바이어 수</label>
          <template v-if="preMatchSetting.buyer_total > 0">
            <div class="ds_flex al_center jus_between back_light_gray input_wrap">
              <p class="secondary_text b_700">{{ preMatchSetting.buyer_total }}</p>
              <p class="size_14 b_700 sub_text">개 업체</p>
            </div>
          </template>
          <template v-else>
            <!-- 부스, 스케줄 등록도 필수인 것 언급 필요 -->
            <div>
              <router-link to="/admin/event/setting/bis" class="block_cont_btn eqqui_btn size_14 b_700">바이어 등록하기
                ></router-link>
              <p class="size_14 eqqui_text">등록된 바이어가 없어요. 바이어 등록은 필수예요. </p>
            </div>
          </template>
        </div>
        <div class="field_wrap w100">
          <label>부스 바이어 매칭</label>
          <p class="ds_flex gap4 al_center main_text size_14" @click="downloadSampleBooth">샘플 파일 다운로드 <i
            class="icon-download"></i></p>
            <div class="ds_flex al_center jus_between fake_textbox input_wrap">
            <i class="icon-file size_24 sub_text"></i>
            <div class="text_wrap t_center">
              <p class="b_700 secondary_text">XLS 파일을 업로드하세요.</p>
              <p class="main_text size_14">*업로드 전 샘플 파일 양식을 꼭 확인하세요.</p>
            </div>
            <label for="file-upload-booth" class="block_cont_btn secondary_text size_14">파일 업로드하기</label>
            <input id="file-upload-booth" type="file" style="display: none" @change="handleFileUploadForBooth" />
            <p v-if="uploadedFileNameForBooth" class="secondary_text size_14">업로드된 파일: {{ uploadedFileNameForBooth }}
            </p>
          </div>
        </div>
        <div class="field_wrap w100" v-if="preMatchSetting.buyer_total > 0">
          <label>셀러 참여 목표치</label>
          <p class="size_14 sub_text">전체 상담회 일자를 기준으로, 목표로 하는 셀러 수를 입력하세요.</p>
          <div class="ds_flex al_center jus_between input_wrap fake_textbox">
            <input class="size_14 b_700" type="text" v-model="preMatchSetting.pre_arranged_goal"
              @input="(event) => enforceNumericInput(event, 'pre_arranged_goal')" />
            <p class="size_14 b_700 sub_text">개 업체</p>
          </div>
        </div>
        <div class="field_wrap w100" v-if="preMatchSetting.buyer_total > 0">
          <label>셀러 매칭 신청 기회</label>
          <p class="size_14 sub_text">셀러가 바이어와의 매칭을 요청할 수 있는 총 횟수를 설정하세요.<br />
            기본값은 스케줄에 등록된 타임 테이블 수로 자동 지정됩니다.</p>
          <div class="ds_flex al_center jus_between input_wrap fake_textbox">
            <input class="size_14 b_700" type="text" v-model="preMatchSetting.pre_arranged_chance"
              @input="(event) => enforceNumericInput(event, 'pre_arranged_chance')" />
            <p class="size_14 b_700 sub_text">회</p>
          </div>
        </div>
      </div>
    </div>
    <div class="floating_btn" v-if="preMatchSetting.buyer_total > 0">
      <div class="mx_1160">
        <div class="ds_flex jus_end">
          <p class="block_main_btn eqqui_btn" @click="updatePreMatching">저장</p>
        </div>
      </div>
    </div>
  </div>
</template>
