<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  props: {
    showBoothSideModal: Boolean,
    showScheduleUploadModal: Boolean,
    showScheduleAddModal: Boolean,
    boothList: Object,
    selectedScheduleData: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const excelUploadModalForBooth = ref(false);

    const eventId = computed(() => store.state.eventStore.eventId);
    const is_video_link = computed(() => store.state.eventStore.isActiveOnlineLink);
    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    const closeModals = () => {
      newScheduleInfo.value = {};
      emit('close');
    }

    const uploadedFileNameForBooth = ref(''); // 업로드된 파일명을 저장할 ref
    const uploadedFileNameForSchedule = ref(''); // 업로드된 파일명을 저장할 ref
    const newBoothName = ref(''); // 개별 부스 추가 시 부스명 담을 ref
    const newScheduleInfo = ref({}); // 스케줄 개별추가

    // 수정모드 확인
    const updateMode = ref(false);
    const isUpdate = () => {
      if (props.selectedScheduleData.isSchedule) {
        updateMode.value = true;
        fetchScheduleDetail()
      } else {
        updateMode.value = false;
        newScheduleInfo.value = ({ is_auto_video: 1 });
      }
    };

    watch(() => props.selectedScheduleData.isSchedule, isUpdate, { immediate: true });

    // 수정모드일때 스케줄 데이터 불러오는 API
    async function fetchScheduleDetail() {
      try {
        const queryParams = new URLSearchParams({
          schedule_id: props.selectedScheduleData.isSchedule
        });

        const url = `/schedule/read_each_schedule?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data != null) {
          newScheduleInfo.value = response.data;
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 불러올때 오류:', error);
      }
    }

    // 부스 업로드 파일 관리
    const uploading = ref(false); // 업로드 중 상태를 저장할 ref
    const handleFileUploadForBooth = (event) => {
      const fileForBooth = event.target.files[0]; // 업로드된 파일 객체 가져오기
      if (fileForBooth) {
        uploadedFileNameForBooth.value = fileForBooth.name; // 업로드된 파일명 설정
        console.log('Uploaded file:', fileForBooth);
      }
    };

    // 부스 업로드 API
    const uploadExcelForBooth = async () => {
      uploading.value = true
      try {
        const fileForBooth = document.getElementById('file-upload-booth').files[0]; // 파일 가져오기

        if (!fileForBooth) {
          console.error('No file selected.');
          return;
        }

        const formData = new FormData();
        formData.append('file', fileForBooth);
        formData.append('piece_event_id', eventId.value);

        const response = await axios.post('/schedule/upload_booth_excel', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          }
        });

        console.log('Upload response:', response.data);
        if (response.data.result === true) {
          uploading.value = false; // 업로드 중 상태 설정
          alert("부스가 생성되었습니다.")
          router.go()
        } else {
          uploading.value = false; // 업로드 중 상태 설정
          alert('부스 업로드 에러');
        }

      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };

    // 스케줄 업로드 파일 관리
    const handleFileUploadForSchedule = (event) => {
      const fileForSchedule = event.target.files[0]; // 업로드된 파일 객체 가져오기
      if (fileForSchedule) {
        uploadedFileNameForSchedule.value = fileForSchedule.name; // 업로드된 파일명 설정
        console.log('Uploaded file:', fileForSchedule);
      }
    };

    // 스케줄 업로드 API
    const uploadExcelForSchedule = async () => {
      uploading.value = true
      try {
        const fileForSchedule = document.getElementById('file-upload-schedule').files[0]; // 파일 가져오기

        if (!fileForSchedule) {
          console.error('No file selected.');
          return;
        }

        const formData = new FormData();
        formData.append('file', fileForSchedule);
        formData.append('piece_event_id', eventId.value);

        const response = await axios.post('/schedule/upload_schedules_excel', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          }
        });
        console.log('Upload response:', response.data);
        if (response.data.result === true) {
          uploading.value = false; // 업로드 중 상태 설정
          alert("스케줄이 등록되었습니다.")
          router.go()
        } else {
          uploading.value = false; // 업로드 중 상태 설정
          alert('스케줄 업로드 에러');
          uploadedFileNameForSchedule.value = ''
        }

      } catch (error) {
        uploading.value = false; // 업로드 중 상태 설정
        const errorMsg = error?.response?.data?.detail || error.message || '';

        if (errorMsg.includes('check datetime')) {
          alert('엑셀의 날짜와 시간이 행사의 날짜와 시간과 다릅니다.');
        } else if (errorMsg.includes('check account or account_type')) {
          alert('엑셀에 입력하신 계정 정보가 잘못되었습니다.');
        } else if (errorMsg.includes('empty booths_name_list')) {
          alert('부스명이 공란인 셀이 있습니다.');
        } else if (errorMsg.includes('check booths_name')) {
          alert('입력하신 부스명이 행사에 등록된 부스명이 아닙니다.');
        } else if (errorMsg.includes('empty datetime_list')) {
          alert('날짜와 시간을 입력해주세요.');
        } else if (errorMsg.includes('empty c_schedule')) {
          alert('등록할 스케줄 데이터가 없습니다.');
        } else {
          alert('스케줄 업로드 중 오류가 발생했습니다. 관리자에게 문의하세요.');
        }
        uploadedFileNameForSchedule.value = ''
        console.error('Error uploading file:', error);
      }
    };

    // 부스 업로드 샘플파일 다운로드 API
    const downloadSampleBooth = async () => {
      try {
        const response = await axios.get('/schedule/download_booth_sample', {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
          responseType: 'blob' // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
        });
        // 파일 객체 생성
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // 파일 URL 생성
        const url = URL.createObjectURL(blob);

        // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'booth_upload_sample.xlsx'); // 적절한 파일 이름과 확장자로 변경
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 메모리 해제
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('파일 다운로드 중 오류 발생:', error);
      }
    };

    const downloadSampleSchedule = async () => {
      try {
        const response = await axios.get('/schedule/download_schedules_table_sample', {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
          responseType: 'blob' // 중요: 파일을 다운로드할 때는 responseType을 'blob'으로 설정해야 합니다.
        });
        // 파일 객체 생성
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // 파일 URL 생성
        const url = URL.createObjectURL(blob);

        // 파일 다운로드를 트리거하기 위해 링크를 생성하고 클릭
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'schedule_upload_sample.xlsx'); // 적절한 파일 이름과 확장자로 변경
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 메모리 해제
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('파일 다운로드 중 오류 발생:', error);
      }
    };

    // 개별 부스 추가 API
    async function createBooths() {
      try {
        const url = '/schedule/create_booths';
        const requestData = {
          piece_event_id: eventId.value, // 적절한 값으로 대체
          booths_list: [
            {
              "booth_name": newBoothName.value
            }
          ]
        };

        const response = await axios.post(url, requestData, {
          headers: {
            Authorization: `Bearer ${token.value}`,
            'Content-Type': 'application/json' // JSON 데이터 전송을 위한 헤더 설정
          }
        });

        if (response.data.result === true) {
          alert("부스가 추가되었습니다.")
          router.go()
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error(error);

        const errorMsg = error?.response?.data?.detail || error.message || '';

        if (errorMsg.includes('Duplicate entry')) {
          alert('중복된 부스명입니다.');
        } else {
          alert('저장 중 오류가 발생했습니다.');
        }
      }
    }

    const userList_buyer = ref([]);
    const userList_inter = ref([]);
    const userList_seller = ref([]);
    const userList_fm = ref([]);
    const newMemo = ref('')

    // BIS 사용자 리스트 API - 이벤트에 등록된 BIS만 가져오기
    async function fetchBISList() {
      try {
        const queryParams = new URLSearchParams({
          page: 1,
          limit: 10000
        });
        queryParams.append('account_type_list', 'buyer');
        queryParams.append('account_type_list', 'interpreter');
        queryParams.append('account_type_list', 'seller');
        queryParams.append('event_id', eventId.value);
        queryParams.append('event_type', 'PIECE');
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          const allUsers = response.data.user_list;
          userList_buyer.value = allUsers.filter(user => user.account_type === 'BUYER');
          userList_inter.value = allUsers.filter(user => user.account_type === 'INTERPRETER');
          userList_seller.value = allUsers.filter(user => user.account_type === 'SELLER');
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    // FM 사용자 리스트 API - 이벤트 상관 없이 전체 C2 불러오기
    async function fetchFMList() {
      try {
        const queryParams = new URLSearchParams({
          page: 1,
          limit: 10000
        });
        queryParams.append('account_type_list', 'c2');
        // 전체 URL에 쿼리 스트링을 추가합니다.
        const url = `/user/read_list?${queryParams.toString()}`;
        // Axios 요청을 보냅니다.
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          userList_fm.value = response.data.user_list;
        } else {
          alert('사용자를 불러오지 못했습니다.');
        }
      } catch (error) {
        console.error('사용자 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    // FM 사용자 행사에 추가
    async function addEventFM() {
      try {
        const url = '/user/create_piece_to_accounts';
        const requestData = {
          piece_event_id: eventId.value, // 적절한 값으로 대체
          at_id_list: [
            newScheduleInfo.value.manager.at_id
          ]
        };

        const response = await axios.post(url, requestData, {
          headers: {
            Authorization: `Bearer ${token.value}`,
            'Content-Type': 'application/json' // JSON 데이터 전송을 위한 헤더 설정
          }
        });
        if (response.data.result === true) {
          addSchedule()
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('FM 행사추가 오류:', error);
      }
    }

    // 개별 스케줄 추가 API
    async function addSchedule() {
      try {
        const url = '/schedule/create_schedules_for_bis';
        const requestData = {
          piece_event_id: eventId.value,
          ptd_id: props.selectedScheduleData.time.ptd_id,
          ptb_id: props.selectedScheduleData.booth.ptb_id
        };

        // if (newScheduleInfo.value.time?.ptd_id) {
        //   requestData.ptd_id = newScheduleInfo.value.time.ptd_id;
        // }
        // if (newScheduleInfo.value.booth?.ptb_id) {
        //   requestData.ptb_id = newScheduleInfo.value.booth.ptb_id;
        // }

        if (newScheduleInfo.value.buyer?.account_id) {
          requestData.buyer = {
            account_id: newScheduleInfo.value.buyer.account_id,
            account_type: newScheduleInfo.value.buyer.account_type,
            at_id: newScheduleInfo.value.buyer.at_id
          };
        }

        if (newScheduleInfo.value.interpreter?.account_id) {
          requestData.interpreter = {
            account_id: newScheduleInfo.value.interpreter.account_id,
            account_type: newScheduleInfo.value.interpreter.account_type,
            at_id: newScheduleInfo.value.interpreter.at_id
          };
        }

        if (newScheduleInfo.value.seller?.account_id) {
          requestData.seller = {
            account_id: newScheduleInfo.value.seller.account_id,
            account_type: newScheduleInfo.value.seller.account_type,
            at_id: newScheduleInfo.value.seller.at_id
          };
        }

        if (newScheduleInfo.value.memo) {
          requestData.memo = newScheduleInfo.value.memo;
        }

        // 기본값이 아닌 경우에만 추가
        if (newScheduleInfo.value.link) {
          requestData.link = newScheduleInfo.value.link;
        }
        if (newScheduleInfo.value.real_time) {
          requestData.real_time = newScheduleInfo.value.real_time;
        }
        if (newScheduleInfo.value.video_provider !== 'OFF') {
          requestData.video_provider = newScheduleInfo.value.video_provider;
        }

        const response = await axios.post(url, requestData, {
          headers: {
            Authorization: `Bearer ${token.value}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.data.result === true) {
          alert("스케줄이 추가되었습니다.");
          router.go();
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 개별추가 오류:', error);
      }
    }


    // 화상링크 자동생성 개별
    const createVideoLink = async () => {
      try {
        const response = await axios.post(`/schedule/create_video_link?schedule_id=${newScheduleInfo.value.schedule_id}`, {}, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          newScheduleInfo.value.link = response.data.link; // result 대신 link 할당
        } else {
          alert('저장하지 못했습니다.');
        }
      } catch (error) {
        console.error('Error creating video link:', error);
      }
    }


    // 개별 스케줄 수정 API
    async function updateSchedule() {
      try {
        const url = '/schedule/update_schedule';
        const requestData = {
          schedule_id: newScheduleInfo.value.schedule_id,
          buyer: {
            account_id: newScheduleInfo.value.buyer.account_id,
            account_type: newScheduleInfo.value.buyer.account_type,
            at_id: newScheduleInfo.value.buyer.at_id
          },
          interpreter: {
            account_id: newScheduleInfo.value.interpreter.account_id,
            account_type: newScheduleInfo.value.interpreter.account_type,
            at_id: newScheduleInfo.value.interpreter.at_id
          },
          seller: {
            account_id: newScheduleInfo.value.seller.account_id,
            account_type: newScheduleInfo.value.seller.account_type,
            at_id: newScheduleInfo.value.seller.at_id
          },
          is_auto_video: newScheduleInfo.value.is_auto_video,
        };

        // manager 필드 값이 있는 경우에만 추가
        if (newScheduleInfo.value.manager && newScheduleInfo.value.manager.account_id) {
          requestData.manager = {
            account_id: newScheduleInfo.value.manager.account_id,
            account_type: 'c2',
            at_id: newScheduleInfo.value.manager.at_id
          };
        }
        // link 필드 값이 있으면 requestData 객체에 포함
        if (newScheduleInfo.value.link) {
          requestData.link = newScheduleInfo.value.link;
        }

        // memo 필드 값이 있으면 requestData 객체에 포함
        if (newMemo.value) {
          requestData.memo = newMemo.value;
        }

        const response = await axios.put(url, requestData, {
          headers: {
            Authorization: `Bearer ${token.value}`
          }
        });

        if (response.data.result === true) {
          alert("스케줄이 수정되었습니다.")
          router.go(0)
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 개별추가 오류:', error);
      }
    }

    const deleteSchedule = async () => {
      if (confirm("삭제하시겠습니까?")) { // 사용자가 확인을 누른 경우에만 실행
        try {
          const response = await axios.delete(`/schedule/delete_schedule?schedule_id=${newScheduleInfo.value.schedule_id}`, {
            headers: {
              Authorization: `Bearer ${token.value}`
            }
          });
          if (response.data.result === true) {
            alert("삭제되었습니다.");
            router.go(0);
          } else {
            console.log('Delete failed:', response.data.result);
          }
        } catch (error) {
          console.error('Delete failed:', error);
        }
      }
    };

    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
        isUpdate()
        fetchBISList()
        fetchFMList()
      }
      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });
    return {
      is_video_link,
      excelUploadModalForBooth,
      closeModals,
      uploadedFileNameForBooth,
      uploadedFileNameForSchedule,
      handleFileUploadForBooth,
      handleFileUploadForSchedule,
      uploadExcelForBooth,
      uploadExcelForSchedule,
      downloadSampleBooth,
      downloadSampleSchedule,
      newBoothName,
      createBooths,
      newScheduleInfo,
      userList_buyer,
      userList_inter,
      userList_seller,
      userList_fm,
      addEventFM,
      updateMode,
      updateSchedule,
      deleteSchedule,
      createVideoLink,
      newMemo,
      uploading
    }
  },
});
</script>

<template>
  <div>
    <div id="eventSettingTableModal">
      <div class="overlay" v-if="excelUploadModalForBooth || showScheduleUploadModal || showScheduleAddModal"></div>
      <div class="modal" v-if="excelUploadModalForBooth || showScheduleUploadModal">
        <div class="modal_overflow">
          <p class="icon_box close" @click="excelUploadModalForBooth = false, closeModals()">
            <i class="icon-delete sub_text"></i>
          </p>
          <template v-if="excelUploadModalForBooth">
            <div class="modal_top">
              <div class="text_wrap">
                <p class="modal_title size_28 b_700">부스 업로드</p>
                <p class="secondary_text">엑셀 파일로 부스를 일괄 추가할 수 있어요.</p>
              </div>
              <a class="download" @click="downloadSampleBooth">샘플 파일 다운로드 <i class="icon-download"></i></a>
            </div>
            <div class="img_upload ds_flex">
              <template v-if="!uploading">
                <i class="icon-file size_32"></i>
                <div class="text_wrap t_center">
                  <p class="b_700 secondary_text">XLS 파일을 업로드하세요.</p>
                  <p class="main_text size_14">*업로드 전 샘플 파일 양식을 꼭 확인하세요.</p>
                </div>
                <label for="file-upload-booth" class="block_cont_btn secondary_text size_14">파일 업로드하기</label>
                <input id="file-upload-booth" type="file" style="display: none" @change="handleFileUploadForBooth" />
              </template>
              <template v-else-if="uploading">
                <div class="text_wrap t_center">
                  <p class="b_700 secondary_text">파일을 업로드 중입니다.</p>
                  <div class="spinner"></div>
                </div>
              </template>
              <p v-if="uploadedFileNameForBooth" class="secondary_text size_14">업로드된 파일: {{ uploadedFileNameForBooth }}
              </p>
            </div>
            <p class="block_main_btn active_btn" @click="uploadExcelForBooth">저장</p>
          </template>
          <template v-if="showScheduleUploadModal">
            <div class="modal_top">
              <div class="text_wrap">
                <p class="modal_title size_28 b_700">스케줄 업로드</p>
                <p class="secondary_text">엑셀 파일로 스케줄을 일괄 추가할 수 있어요.</p>
              </div>
              <a class="download" @click="downloadSampleSchedule" download>샘플 파일 다운로드 <i class="icon-download"></i></a>
            </div>
            <div class="img_upload ds_flex">
              <template v-if="!uploading">
                <i class="icon-file size_32"></i>
                <div class="text_wrap t_center">
                  <p class="b_700 secondary_text">XLS 파일을 업로드하세요.</p>
                  <p class="main_text size_14">*업로드 전 샘플 파일 양식을 꼭 확인하세요.</p>
                </div>
                <label for="file-upload-schedule" class="block_cont_btn secondary_text size_14">파일 업로드하기</label>
                <input id="file-upload-schedule" type="file" style="display: none"
                  @change="handleFileUploadForSchedule" />
              </template>
              <template v-else-if="uploading">
                <div class="text_wrap t_center">
                  <p class="b_700 secondary_text">파일을 업로드 중입니다.</p>
                  <div class="spinner"></div>
                </div>
              </template>
              <p v-if="uploadedFileNameForSchedule" class="secondary_text size_14">업로드된 파일: {{
                uploadedFileNameForSchedule
              }}</p>
            </div>
            <p class="block_main_btn active_btn" @click="uploadExcelForSchedule">저장</p>
          </template>
        </div>
      </div>
      <transition name="slide-fade">
        <div class="sidemodal" v-if="showBoothSideModal">
          <div class="top">
            <p class="inline_cont_btn upload" @click="excelUploadModalForBooth = true">부스 업로드</p>
          </div>
          <p class="size_26 b_700">부스 추가</p>
          <p class="description secondary_text">추가할 부스명을 입력하세요.</p>
          <div class="cont">
            <div class="input_cont">
              <input type="text" placeholder="부스명" class="fake_textbox" v-model="newBoothName" />
            </div>
          </div>
          <div class="btn_wrap bottom">
            <p class="block_main_btn cancel_btn" @click="closeModals">취소</p>
            <p class="block_main_btn active_btn" @click="createBooths">추가하기</p>
          </div>
        </div>
      </transition>
      <div class="modal narrow_width" v-if="showScheduleAddModal">
        <div class="modal_overflow">
          <p class="icon_box close" @click="closeModals()">
            <i class="icon-delete sub_text"></i>
          </p>
          <div class="modal_top">
            <div class="text_wrap">
              <p class="modal_title size_28 b_700">스케줄 추가</p>
            </div>
          </div>
          <div class="cont">
            <div class="input_cont">
              <label>부스명</label>
              <input type="text" class="fake_textbox" disabled
                :value="selectedScheduleData.booth.booth_name ? selectedScheduleData.booth.booth_name : selectedScheduleData.booth.ptb" />
            </div>
            <div class="input_cont">
              <label>상담시간</label>
              <input type="text" class="fake_textbox" disabled
                :value="`${selectedScheduleData.time.start_time}-${selectedScheduleData.time.end_time}`" />
            </div>
            <!-- <div class="input_cont">
          <label>실제상담시간</label>
          <input type="text" class="fake_textbox"  />
        </div> -->
            <div class="input_cont">
              <label>바이어</label>
              <v-select :options="userList_buyer" v-model="newScheduleInfo.buyer" label="company" :reduce="item => item"
                :clearable="false" placeholder="비어있음"></v-select>
            </div>
            <div class="input_cont">
              <label>통역사</label>
              <v-select :options="userList_inter" v-model="newScheduleInfo.interpreter" label="full_name"
                :reduce="item => item" :clearable="false" placeholder="비어있음"></v-select>
            </div>
            <div class="input_cont">
              <label>셀러</label>
              <v-select :options="userList_seller" v-model="newScheduleInfo.seller" label="company"
                :reduce="item => item" :clearable="false" placeholder="비어있음"></v-select>
            </div>
            <div class="input_cont">
              <label>현장요원</label>
              <v-select :options="userList_fm" v-model="newScheduleInfo.manager" :reduce="item => item"
                label="full_name" :clearable="false" placeholder="비어있음"></v-select>
            </div>
            <div class="input_cont video" v-if="is_video_link === 'Y'">
              <label>상담링크</label>
              <div class="ds_flex al_center gap4">
                <select v-model.number="newScheduleInfo.is_auto_video">
                  <option :value="1">자동생성</option>
                  <option :value="0">직접입력</option>
                </select>
                <p class="size_12 sub_text" v-if="newScheduleInfo.is_auto_video === 1 && !newScheduleInfo.schedule_id">
                  스케줄 추가
                  후 생성이 가능합니다.</p>
                <p v-if="newScheduleInfo.schedule_id && newScheduleInfo.is_auto_video === 1 && !newScheduleInfo.link"
                  class="inline_cont_btn" @click="createVideoLink">생성</p>
                <input type="text" class="fake_textbox" v-model="newScheduleInfo.link"
                  v-if="newScheduleInfo.is_auto_video === 0 || newScheduleInfo.link" />
              </div>
            </div>
            <div class="input_cont memo">
              <label>비고</label>
              <div>
                <textarea placeholder="내용을 작성해주세요." v-model="newMemo"></textarea>
                <div class="memo_log_wrap">
                  <div v-for="(memo, index) in newScheduleInfo.memo" :key="index">
                    <p class="memo_cont">
                      <i class="icon-talk"></i>
                      <span>{{ memo.memo }}</span>
                    </p>
                    <p class="created ds_flex gap8">{{ memo.created_by }} {{ memo.created_at }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn_wrap ds_flex gap18 jus_end">
            <p class="block_main_btn cancel_btn" @click="updateMode ? deleteSchedule() : closeModals()">{{ updateMode ?
              '삭제' :
              '취소' }}</p>
            <p class="block_main_btn active_btn" @click="updateMode ? updateSchedule() : addEventFM()">{{ updateMode ?
              '수정' :
              '저장' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
