<script>
import { ref, computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios'; // axios는 일반적으로 직접 가져와 사용합니다.

export default {
  name: 'commonHeader',
  props: {
    windowSize: Object
  },
  setup(props, { emit }) {
    const store = useStore(); // Vuex 스토어 접근
    const route = useRoute(); // vue-router의 현재 route 정보 접근
    const router = useRouter(); // 라우터 인스턴스를 가져오기
    const axios = inject('axios') // $axios 주입 받기

    const user_id = ref('p1@test.test');
    const user_pw = ref('0000');
    const user_data = ref([]);
    const multitype_user = ref(false);

    const isAuthenticated = computed(() => store.state.userStore.isAuthenticated);
    const userName = computed(() => store.state.userStore.userName);
    const userType = computed(() => store.state.userStore.accountType);
    const eventTitle = computed(() => store.state.eventStore.eventTitle);
    const isActivePreMatch = computed(() => store.state.eventStore.isActivePreMatch);
    const isActiveReception = computed(() => store.state.eventStore.isActiveReception);
    const isActiveLiveMatch = computed(() => store.state.eventStore.isActiveLiveMatch);
    const isActiveOnlineLink = computed(() => store.state.eventStore.isActiveOnlineLink);
    const preMatchStatus = computed(() => store.state.eventStore.preMatchStatus);
    const clickEventHistory = computed(() => store.state.eventStore.eventHistory);


    const logout = () => {
      store.dispatch('userStore/logout');
    };

    const eventId = computed(() => route.params.eventId || store.state.eventStore.eventId);

    const isAdminRoute = computed(() => route.path.startsWith('/admin'));
    const isBISRoute = computed(() => route.path.startsWith('/BIS'));

    const isVisible = computed(() => {
      const path = route.path;
      const pathName = route.name;
      return path === '/' || pathName === '/surveyEmail' || path === '/admin/event/select' || path === '/admin/event/setting/new' || pathName === 'eventListMaster' || pathName === 'newEventSettingMasterMaster'
        || pathName === 'receptionQr' || pathName === 'operateAttendQr';
    });


    const isInfoDesk = computed(() => {
      const pathName = route.name;
      return pathName === 'infoDesk' || pathName === 'masterSurveyDashboard' || pathName === 'masterReception' || pathName === 'masterInfoDesk';
    });

    const onEvent = computed(() => {
      const pathName = route.name;
      return pathName === 'eventSetting'
    });


    const loginBool = ref(false);
    const login = async () => {
      if (!loginBool.value) {
        loginBool.value = true;
        if (user_id.value == '') {
          alert("아이디를 입력해주세요.");
          loginBool.value = false;
          return false;
        }
        if (user_pw.value == '') {
          alert("비밀번호를 입력해주세요.");
          loginBool.value = false;
          return false;
        }
        try {
          const response = await axios.post('/user/login', {
            email: user_id.value,
            password: user_pw.value
          });

          if (response.data.result === true) {
            console.log(response.data);
            if (response.data.user_data.length > 1) {
              // 유저 데이터가 2개 이상 넘어오는 경우 멀티타입 셀렉트 모달 true
              multitype_user.value = true;
              user_data.value = response.data.user_data;
            } else {
              // 유저 데이터가 하나인 경우
              user_data.value = response.data.user_data;
              // 어드민인 경우 바로 로그인 가능 > 로그인 true, 대시보드로 이동
              if (['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].includes(response.data.user_data[0].account_type)) {
                store.commit("userStore/login_info", response.data.user_data[0]);
                store.commit("userStore/login", true);
                // console.log(response.data.user_data[0].account_type + "admin")
                router.push({ name: 'eventList' });
              } else if (['BUYER', 'SELLER', 'INTERPRETER'].includes(response.data.user_data[0].account_type)) {
                // BIS인 경우 여러 이벤트 참여할 수 있으므로 이벤트 리스트 불러오는 함수 실행
                fetchBISEventList(response.data.user_data[0])
                // console.log(response.data.user_data[0].account_type + "BIS")
              } else {
                console.error("알 수 없는 유저 타입:", response.data.user_data.account_type);
              }
            }
          } else {
            alert("잘못된 데이터");
          }
        } catch (error) {
          loginBool.value = false;

          if (error.response) {
            // 서버에서 응답은 왔는데 상태 코드가 4xx, 5xx일 때
            const status = error.response.status;
            if (status === 401) {
              alert("아이디 또는 비밀번호가 올바르지 않습니다.");
            } else if (status === 500) {
              alert("서버 오류가 발생했습니다. 관리자에게 문의하세요.");
            } else {
              alert(`오류가 발생했습니다. (에러 코드: ${status})`);
            }
          } else if (error.request) {
            // 요청은 갔지만 응답이 없는 경우
            alert("서버로부터 응답이 없습니다. 네트워크 상태를 확인해주세요.");
          } else {
            // 요청 설정 중에 오류가 난 경우
            alert("요청 중 오류가 발생했습니다.");
          }
          console.error("로그인 실패:", error);
          router.go(0)
        }
      } else {
        return false;
      }
    };

    // 멀티타입 유저 
    const multiTypeSelect = (index) => {
      multitype_user.value = false;
      // 라우팅 로직 추가
      // 어드민 권한이면
      if (['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].includes(user_data.value[index].account_type)) {
        store.commit("userStore/login_info", user_data.value[index]);
        store.commit("userStore/login", true);
        // 이벤트 리스트로 라우터 푸시
        router.push({ name: 'eventList' });
        // BIS 계정이면
      } else if (['BUYER', 'SELLER', 'INTERPRETER'].includes(user_data.value[index].account_type)) {
        // 행사 리스트 반환
        fetchBISEventList(user_data.value[index])
      } else {
        console.error("알 수 없는 유저 타입:", user_data.value[index].account_type);
      }
    };


    const eventList = ref([]);
    const eventListForBISOnEvents = ref([]);
    const eventListForBISPreMatching = ref([]);
    const eventListForBISModal = ref(false);

    async function fetchBISEventList(userData) {
      store.commit("userStore/login_info", userData);
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/event/read_list_for_bis', {
          params: {
            page: 1,
            limit: 10,
          },
          headers: {
            Authorization: `Bearer ${userData.token}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          // userData 정보만 먼저 저장
          eventList.value = response.data.response_data.event_list;

          const statusOneEvents = eventList.value.filter(event => event.status === 1);
          const statusZeroEvents = eventList.value.filter(
            event => event.status === 0 && event.is_pre_arranged === 'Y'
          );

          // 필요한 배열에 값 저장
          eventListForBISOnEvents.value = statusOneEvents; // status가 1인 이벤트 저장
          eventListForBISPreMatching.value = statusZeroEvents; // status가 0인 이벤트 저장
          eventListForBISModal.value = true
          // if (response.data.response_data.event_list.length > 1) {
          //   eventListForBISModal.value = true
          // } else {
          //   routeForBIS('', 0);
          // }
        } else {
          alert('이벤트를 불러오지 못했습니다.');
        }
      } catch (error) {
        // 요청이 실패한 경우
        console.error('이벤트 리스트를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }

    async function routeForBIS(type, index) {
      if (type === 'onGoing') {
        store.commit('eventStore/setEventId', eventListForBISOnEvents.value[index].event_id);
        store.commit('eventStore/setEventTitle', eventListForBISOnEvents.value[index].title);
        store.dispatch('eventStore/updateEvent', {
          eventId: eventListForBISOnEvents.value[index].event_id,
          eventTitle: eventListForBISOnEvents.value[index].title
        });
        store.commit("userStore/login", true);
        router.push({ name: 'BISDashboard' });
      } else if (type === 'preMatch') {
        store.commit('eventStore/setEventId', eventListForBISPreMatching.value[index].event_id);
        store.commit('eventStore/setEventTitle', eventListForBISPreMatching.value[index].title);
        store.dispatch('eventStore/updateEvent', {
          eventId: eventListForBISOnEvents.value[index].event_id,
          eventTitle: eventListForBISOnEvents.value[index].title
        });
        store.commit('eventStore/isActiveMenu', { is_pre_arranged: eventListForBISPreMatching.value[index].is_pre_arranged }); // 필요한 값만 전달
        store.commit('eventStore/setEventStatus', eventListForBISPreMatching.value[index].status); // 필요한 값만 전달
        store.commit("userStore/login", true);
        router.push({ name: 'preMatchDashboard' });
      } else {
        store.commit('eventStore/setEventId', eventList.value[index].event_id);
        store.commit('eventStore/setEventTitle', eventList.value[index].title);
        store.dispatch('eventStore/updateEvent', {
          eventId: eventListForBISOnEvents.value[index].event_id,
          eventTitle: eventListForBISOnEvents.value[index].title
        });
        store.commit('eventStore/isActiveMenu', { is_pre_arranged: eventList.value[index].is_pre_arranged }); // 필요한 값만 전달
        store.commit('eventStore/setEventStatus', eventList.value[index].status); // 필요한 값만 전달
        store.commit("userStore/login", true);
        router.push({ name: 'BISDashboard' });
      }
    }

    const isUserManagementActive = computed(() => {
      const currentPath = route.path;
      return currentPath === '/admin/event/setting/bis' || currentPath === '/admin/event/setting/admin';
    });

    const isQuestionActive = computed(() => {
      const currentPath = route.name;
      return currentPath === 'questionSetting' || currentPath === 'newQuestionDetail' || currentPath === 'questionDetail' || currentPath === 'surveySetting';
    });

    const isAttendActive = computed(() => {
      const currentPath = route.name;
      return currentPath === 'operateAttendInterpreter' || currentPath === 'operateAttendSeller';
    });

    const isSurveyActive = computed(() => {
      const currentPath = route.name;
      return currentPath === 'surveyRecordInterpreter' || currentPath === 'surveyRecordBuyer' || currentPath === 'surveyRecordSeller';
    });

    const isSatisfyActive = computed(() => {
      const currentPath = route.name;
      return currentPath === 'satisfyRecordInterpreter' || currentPath === 'satisfyRecordBuyer' || currentPath === 'satisfyRecordSeller';
    });

    const eventHistoryPopup = ref(false)
    const toggleEventHistoryPopup = () => {
      eventHistoryPopup.value = !eventHistoryPopup.value;
    };

    // 라우트 변경 감지 -> 팝업 닫기
    watch(() => route.path, () => {
      eventHistoryPopup.value = false;
    });

    // 모든 로컬 state와 메소드를 반환하여 템플릿에서 접근 가능하도록 합니다.
    return {
      user_id,
      user_pw,
      user_data,
      userType,
      multitype_user,
      isAdminRoute,
      isBISRoute,
      isAuthenticated,
      userName,
      eventTitle,
      eventId,
      isVisible,
      onEvent,
      login,
      logout,
      multiTypeSelect,
      isUserManagementActive,
      isActivePreMatch,
      isActiveReception,
      isActiveLiveMatch,
      isActiveOnlineLink,
      preMatchStatus,
      isQuestionActive,
      isAttendActive,
      isSurveyActive,
      isSatisfyActive,
      isInfoDesk,
      eventListForBISOnEvents,
      eventListForBISPreMatching,
      eventListForBISModal,
      routeForBIS,
      clickEventHistory,
      eventHistoryPopup,
      toggleEventHistoryPopup
    };
  }
}
</script>


<template>
  <template v-if="windowSize.width < 1024">
    <template v-if="!isAuthenticated && !isInfoDesk">
      <div class="mobile_header">
        <div class="logo_wrap">
          <img src="@/assets/img/common/bisat_logo.svg" />
        </div>
        <p class="sub_text t_center">비샛 계정은 에퀴코리아가 생성 후 알려드려요.<br />
          에퀴 PM에게 안내받은 아이디로 로그인하세요.</p>
        <div class="login_wrap">
          <input type="text" v-model="user_id" @keyup.enter="login()" />
          <input type="password" v-model="user_pw" @keyup.enter="login()" />
        </div>
        <div class="btn_wrap">
          <p class="login" @click="login()">로그인</p>
          <p class="">셀러 만족도 조사 제출하기</p>
        </div>
        <p class="notice">로그인과 동시에 eQQui의 <a href="https://www.eqqui.com/about/company?page=company-4"
            target="_blank">서비스약관</a> <a href="https://www.eqqui.com/about/company?page=company-4"
            target="_blank">개인정보보호정책</a> 및 <a href="https://www.eqqui.com/about/company?page=company-4"
            target="_blank">위탁 관련 약관</a>에 동의하게 됩니다.</p>
        <div class="ds_flex gap12 al_center">
          <p class="sub_text size_14">이용가이드</p>
          <p class="sub_text size_14">한국어</p>
        </div>
      </div>
      <div id="selectEvent">
        <div class="overlay" v-if="multitype_user"></div>
        <transition name="slide-up">
          <div class="bottom_sheet" v-if="multitype_user">
            <div class="sheet_header">
            </div>
            <div class="sheet_content">
              <p class="size_20 b_700">멀티타입 계정이에요.<br />어느 타입으로 로그인할까요?</p>
              <div class="event_list_wrap">
                <div class="event_list" v-for="item, index in user_data" :key="index" @click="multiTypeSelect(index)">
                  <p>{{ item.account_type }}</p>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div id="selectEvent">
        <div class="overlay" v-if="eventListForBISModal"></div>
        <transition name="slide-up">
          <div class="bottom_sheet" v-if="eventListForBISModal">
            <div class="sheet_header">
            </div>
            <div class="sheet_content">
              <p class="size_20 b_700">참여중인 상담회 목록이에요</p>
              <p class="size_14 sub_text">로그인후에도 다른 상담회 모드로 변경할 수 있어요. </p>
              <div class="event_list_wrap" v-if="eventListForBISPreMatching.length > 0">
                <p class="b_700 SELLER_text">사전주선 신청</p>
                <div class="event_list" v-for="item, index in eventListForBISPreMatching" :key="item.event_id"
                  @click="routeForBIS('preMatch', index)">
                  <p>{{ item.title }}</p>
                </div>
              </div>
              <div class="event_list_wrap" v-if="eventListForBISOnEvents.length > 0">
                <p class="b_600" v-if="eventListForBISOnEvents.length > 0">참여중인 상담회</p>
                <div class="event_list" v-for="item, index in eventListForBISOnEvents" :key="item.event_id"
                  @click="routeForBIS('onGoing', index)">
                  <p>{{ item.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </template>
  <template v-else>
    <!-- 로그인 상태가 아닌 경우 -->
    <template v-if="!isAuthenticated && !isInfoDesk">
      <div class="header">
        <router-link :to="isAuthenticated ? '/admin/event/select' : '/'">
          <div class="logo_wrap">
            <img src="@/assets/img/common/bisat_logo.svg" />
          </div>
        </router-link>
        <div class="ds_flex al_center gap28">
          <!-- <a href="/metafields/main" class="ds_flex al_center gap6" target="_blank">
            <p class="new_badge">new</p>
            <p class="size_14 gray_text">상담회 실적 보기</p>
          </a> -->
          <!-- <router-link custom v-slot="{ navigate, href }" :to="{ name: 'metaFields' }">
            <a :href="href" @click="navigate" class="ds_flex al_center gap6">
              <p class="new_badge">new</p>
              <p class="size_14 gray_text">상담회 실적 보기</p>
            </a>
          </router-link> -->
          <!-- <p class="size_14 gray_text">만족도 조사(셀러용)</p> -->
          <div class="ds_flex gap4 al_center">
            <i class="icon-manual1 secondary_text size_20" />
            <i class="icon-down_arrow secondary_text size_10" />
          </div>
          <div class="ds_flex gap4 al_center">
            <i class="icon-language secondary_text size_22" />
            <i class="icon-down_arrow secondary_text size_10" />
          </div>
          <div class="login_wrap">
            <input type="text" v-model="user_id" @keyup.enter="login()" />
            <input type="password" v-model="user_pw" @keyup.enter="login()" />
            <p class="login" @click="login()">로그인</p>
          </div>
        </div>
      </div>
      <div id="selectEvent">
        <div class="overlay" v-if="multitype_user"></div>
        <transition name="slide-up">
          <div class="bottom_sheet" v-if="multitype_user">
            <div class="sheet_header">
            </div>
            <div class="sheet_content">
              <p class="size_20 b_700">멀티타입 계정이에요.<br />어느 타입으로 로그인할까요?</p>
              <div class="event_list_wrap">
                <div class="event_list" v-for="item, index in user_data" :key="index" @click="multiTypeSelect(index)">
                  <p>{{ item.account_type }}</p>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div id="selectEvent">
        <div class="overlay" v-if="eventListForBISModal"></div>
        <transition name="slide-up">
          <div class="bottom_sheet" v-if="eventListForBISModal">
            <div class="sheet_header">
            </div>
            <div class="sheet_content">
              <p class="size_20 b_700">참여중인 상담회 목록이에요</p>
              <p class="size_14 sub_text">로그인후에도 다른 상담회 모드로 변경할 수 있어요. </p>
              <div class="event_list_wrap" v-if="eventListForBISPreMatching.length > 0">
                <p class="b_700 SELLER_text">사전주선 신청</p>
                <div class="event_list" v-for="item, index in eventListForBISPreMatching" :key="item.event_id"
                  @click="routeForBIS('preMatch', index)">
                  <p>{{ item.title }}</p>
                </div>
              </div>
              <div class="event_list_wrap" v-if="eventListForBISOnEvents.length > 0">
                <p class="b_600" v-if="eventListForBISOnEvents.length > 0">참여중인 상담회</p>
                <div class="event_list" v-for="item, index in eventListForBISOnEvents" :key="item.event_id"
                  @click="routeForBIS('onGoing', index)">
                  <p>{{ item.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </template>
    <!-- 로그인 상태인 경우 -->
    <template v-else>
      <!-- 어드민 헤더 -->
      <!-- 이벤트 진입 전 사용하는 네비게이션 없는 헤더 -->
      <template v-if="isVisible && !isInfoDesk">
        <div class="header">
          <router-link :to="isAuthenticated ? '/admin/event/select' : '/'">
            <div class="logo_wrap">
              <img src="@/assets/img/common/bisat_logo.png" />
            </div>
          </router-link>
          <div class="login_wrap">
            <p>{{ userName }} 님</p>
            <i @click="logout" class="icon-logout cs_pointer"></i>
          </div>
        </div>
      </template>
      <template v-else-if="isInfoDesk">
        <div class="header">
          <div class="logo_wrap">
            <img src="@/assets/img/common/bisat_logo.png" />
          </div>
        </div>
      </template>
      <template v-else>
        <!-- 이벤트 진입 후 사용하는 헤더 -->
        <div class="header on_event">
          <img src="@/assets/img/common/event_title_badge.png" />
          <p class="size_26 b_700">{{ eventTitle }}</p>
          <p class="icon_box" @click="toggleEventHistoryPopup"><i class="icon-down_arrow size_22"
              :class="{ 'rotate': clickEventHistory.length > 0 && eventHistoryPopup }"></i></p>
          <transition name="slide-fade-up">
            <div v-if="clickEventHistory.length > 0 && eventHistoryPopup" class="event_history_popup">
              <a v-for="item in clickEventHistory" :key="item.id" :href="`/admin/event/setting/${item.id}`"
                class="b_700 secondary_text">
                {{ item.title }}
              </a>
            </div>
          </transition>
        </div>
        <!-- </template> -->
        <div class="nav">
          <router-link :to="isAuthenticated ? '/admin/event/select' : '/'">
            <div class="logo_wrap">
              <img src="@/assets/img/common/bisat_logo.png" />
            </div>
          </router-link>
          <div class="user_info">
            <router-link to="/">
              <img src="@/assets/img/common/user_info.png">
              <p class="size_18 b_700">{{ userName }} 님</p>
            </router-link>
          </div>
          <div class="menu_wrap">
            <ul>
              <li class="ds_flex al_center">
                <i class="icon-setting"></i>
                <span class="menu_title">설정</span>
                <ul class="w100 ds_flex">
                  <li>
                    <router-link custom v-slot="{ navigate, href, isActive }"
                      :to="{ name: 'eventSetting', params: { eventId: eventId } }">
                      <a :href="href" :class="{ active: isActive }" @click="navigate">상담회 관리</a>
                    </router-link>
                  </li>
                  <li>
                    <router-link custom v-slot="{ navigate, href }" to="/admin/event/setting/bis">
                      <a :href="href" :class="{ active: isUserManagementActive }" @click="navigate">사용자 관리</a>
                    </router-link>
                  </li>
                  <li v-if="isActivePreMatch == 'Y' && (userType === 'A1' || userType === 'A2')">
                    <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'preMatchSetting' }">
                      <a :href="href" :class="{ active: isActive }" @click="navigate">사전주선 관리</a>
                    </router-link>
                  </li>
                  <li v-if="preMatchStatus !== 1">
                    <router-link custom v-slot="{ navigate, href, isActive }"
                      :to="{ name: 'eventSettingTables', params: { eventId: eventId } }">
                      <a :href="href" :class="{ active: isActive }" @click="navigate">운영 스케줄 관리</a>
                    </router-link>
                  </li>
                  <li v-if="userType === 'A1' || userType === 'A2'">
                    <router-link custom v-slot="{ navigate, href, isActive }"
                      :to="{ name: 'questionSetting', params: { eventId: eventId } }">
                      <a :href="href" :class="{ active: isActive }" @click="navigate">질문과 설문 관리</a>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="ds_flex al_center" v-if="isActiveReception == 'Y'">
                <i class="icon-landing"></i>
                <span class="menu_title">영접</span>
                <ul class="w100 ds_flex">
                  <li>
                    <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'reception' }">
                      <a :href="href" :class="{ active: isActive }" @click="navigate">영접 현황</a>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="ds_flex al_center">
                <i class="icon-monitor_check"></i>
                <span class="menu_title">운영</span>
                <ul class="w100 ds_flex">
                  <li>
                    <router-link custom v-slot="{ navigate, href }" :to="{ name: 'operateAttendInterpreter' }">
                      <a :href="href" :class="{ active: isAttendActive }" @click="navigate">출석표</a>
                    </router-link>
                  </li>
                  <li>
                    <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'operateTables' }">
                      <a :href="href" :class="{ active: isActive }" @click="navigate">현장 운영</a>
                    </router-link>
                  </li>
                  <!-- <li v-if="isActiveLiveMatch == 'Y'">
                    <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'operateLiveMatch' }">
                      <a :href="href" :class="{ active: isActive }" @click="navigate">현장 매칭</a>
                    </router-link>
                  </li> -->
                </ul>
              </li>
              <li class="ds_flex al_center">
                <i class="icon-manual2"></i>
                <span class="menu_title">상담기록</span>
                <ul class="w100 ds_flex">
                  <li>
                    <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'surveyDashboard' }">
                      <a :href="href" :class="{ active: isActive }" @click="navigate">대시보드</a>
                    </router-link>
                  </li>
                  <li>
                    <router-link custom v-slot="{ navigate, href }" :to="{ name: 'surveyRecordInterpreter' }">
                      <a :href="href" :class="{ active: isSurveyActive }" @click="navigate">상담일지</a>
                    </router-link>
                  </li>
                  <li>
                    <router-link custom v-slot="{ navigate, href }" :to="{ name: 'satisfyRecordBuyer' }">
                      <a :href="href" :class="{ active: isSatisfyActive }" @click="navigate">사전 설문</a>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="ds_flex al_center cs_pointer">
                <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'infoDesk' }">
                  <i class="icon-information"></i>
                  <a :href="href" target="_blank" class="menu_title cs_pointer" :class="{ active: isActive }"
                    @click="navigate">인포데스크</a>
                </router-link>
              </li>
              <li class="ds_flex al_center" v-if="userType !== 'C1'">
                <i class="icon-paper_airplane"></i>
                <span class="menu_title">전송</span>
                <ul class="w100 ds_flex">
                  <li>
                    <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'sendNotice' }">
                      <a :href="href" :class="{ active: isActive }" @click="navigate">공지사항</a>
                    </router-link>
                  </li>
                  <li>
                    <router-link custom v-slot="{ navigate, href, isActive }" :to="{ name: 'sendMessage' }">
                      <a :href="href" :class="{ active: isActive }" @click="navigate">문자전송</a>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="logout">
            <p class="icon_box" @click="logout"><i class="icon-logout size_20 sub_text"></i></p>
          </div>
        </div>
      </template>
    </template>
  </template>
</template>
