<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import Pagination from '@/components/admin/pagination.vue'
import router from '@/router';

export default defineComponent({
  props: {
    matchingListModal: Boolean
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const eventId = computed(() => store.state.eventStore.eventId);
    const isAuthenticated = ref(false);
    const token = ref('');



    const closeModals = () => {
      emit('close');
    }


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }
      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });
    return {
      closeModals
    }
  },
});
</script>

<template>
  <div id="preMatchScheduleTableSideModal">
    <transition name="slide-fade">
      <div class="sidemodal" v-if="matchingListModal">
        <div class="top">
        </div>
        <p class="size_26 b_700">상담 리스트</p>
        <p class="description">셀러 당 매칭 신청 가능 횟수: nn</p>
        <div class="cont">
          <div class="tab_wrap">
            <p class="tab">매칭 완료</p>
            <p class="tab">매칭 대기중</p>
          </div>
          <p class="">셀러의 매칭 신청으로 성사된 상담 리스트입니다.<br/>해당 항목이 우선적으로 스케줄 배정돼요.</p>
        </div>
        <div class="btn_wrap bottom">
          <p class="block_main_btn cancel_btn" @click="closeModals">취소</p>
        </div>
      </div>
    </transition>
  </div>
</template>
