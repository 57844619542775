<script>
import { ref, onMounted, computed, inject, reactive, defineComponent } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import EventSettingMasterModal from '@/components/admin/eventSettingMasterModal.vue';

export default {
  name: 'eventSettingMaster',
  components: {
    EventSettingMasterModal
  },
  setup() {
    const axios = inject('axios') // $axios 주입 받기
    const store = useStore();
    const userName = ref('');
    const token = ref('');

    const eventId = computed(() => store.state.eventStore.eventId);
    const isUpdateMode = computed(() => {
      // eventId가 null이나 undefined가 아니고, 빈 문자열이 아닌 경우에만 true를 반환
      return eventId.value != null && eventId.value !== '';
    });
    const MasterEventData = ref({});


    async function fetchEventMasterDetail() {
      try {
        // axios를 사용하여 이벤트 상세 정보를 가져옵니다.
        const response = await axios.get('/event/read_master', {
          params: {
            master_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        MasterEventData.value = response.data.master_event;

      } catch (error) {
        console.error('이벤트 데이터를 가져오는 중에 오류가 발생했습니다:', error);
        alert('이벤트를 불러오지 못했습니다.');
        router.push('/admin/event/select');  // 에러 발생 시 리다이렉션
      }
    }

    const isProcessing = ref(false);
    const processEditModeOrSaveMode = async (event) => {
      event.preventDefault(); // 클릭 이벤트의 기본 동작 방지
      if (isProcessing.value) {
        alert("처리 중입니다.")
        return; // 이미 처리 중이면 아무것도 하지 않습니다.
      }
      isProcessing.value = true; // 처리 중 상태를 활성화합니다.

      try {
        if (isUpdateMode.value) {
          await updateMasterEvent(); // 수정 모드에서의 이벤트 처리
        } else {
          await saveMasterEvent(); // 저장 모드에서의 이벤트 처리
        }
      } catch (error) {
        console.error("Error processing the event:", error);
        alert("이벤트 처리 중 오류가 발생했습니다."); // 사용자에게 오류 알림
      } finally {
        isProcessing.value = false; // 비동기 작업이 완료된 후에 처리 상태를 비활성화
      }
    };

    const saveMasterEvent = async () => {
      // API 엔드포인트 URL
      const url = "/event/create_master";
      const postData = {
        // ...MasterEventData.value,
        master_event_title: MasterEventData.value.title,
        a_account_list: MasterEventData.value.a_account_list.map(account => ({
          account_id: account.account_id,
          account_type: account.account_type,
          at_id: account.at_id
        })),
        b_account_list: MasterEventData.value.b_account_list.map(account => ({
          account_id: account.account_id,
          account_type: account.account_type,
          at_id: account.at_id
        })),
        piece_event_list: MasterEventData.value.piece_event_list.map(event => event.event_id)
      };
      try {
        // axios POST 요청
        const response = await axios.post(url, postData, {
          headers: {
            // 토큰을 헤더에 포함하여 인증 구현
            'Authorization': `Bearer ${token.value}`
          }
        });
        if (response.data.result == true) {
          // 요청이 성공적으로 완료되면 실행
          console.log("Event saved successfully:", response.data);
          alert("이벤트가 성공적으로 저장되었습니다.");  // 사용자에게 성공 알림
          router.push(`/admin/event/select/master/${response.data.master_event_id}`)
        } else {
          console.error("Error saving the event:", error);
          alert("이벤트 저장 중 오류가 발생했습니다.");  // 사용자에게 오류 알림
        }
      } catch (error) {
        // 에러 처리
        console.error("Error saving the event:", error);
        alert("이벤트 저장 중 오류가 발생했습니다.");  // 사용자에게 오류 알림
      }
    };

    const updateMasterEvent = async () => {
      const url = "/event/update_master";
      const postData = {
        // ...MasterEventData.value,
        master_event_id: eventId.value,
        master_event_title: MasterEventData.value.title,
        a_account_list: MasterEventData.value.a_account_list.map(account => ({
          account_id: account.account_id,
          account_type: account.account_type,
          at_id: account.at_id
        })),
        b_account_list: MasterEventData.value.b_account_list.map(account => ({
          account_id: account.account_id,
          account_type: account.account_type,
          at_id: account.at_id
        })),
        piece_event_list: MasterEventData.value.piece_event_list.map(event => event.event_id)
      };

      try {
        const response = await axios.put(url, postData, {
          headers: {
            'Authorization': `Bearer ${token.value}`
          }
        });
        if (response.data.result == true) {
          console.log("Event updated successfully:", response.data);
          alert("이벤트가 성공적으로 수정되었습니다.");
          router.go()
        } else {
          console.error("Error updating the event:", response.data);
          alert("이벤트 수정 중 오류가 발생했습니다.");
        }
      } catch (error) {
        console.error("Error updating the event:", error);
        alert("이벤트 수정 중 오류가 발생했습니다.");
      }
    };

    async function deleteMasterEvent() {
      confirm("정말 이벤트를 삭제하시겠습니까? 삭제한 데이터는 되돌릴 수 없습니다.")
      try {
        // axios.delete를 사용하여 이벤트 삭제 요청을 보냅니다.
        const response = await axios.delete('/event/delete_master', {
          params: {
            master_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`  // 헤더에 인증 토큰 추가
          }
        });

        // 응답 확인
        if (response.data.result) {
          alert('이벤트가 성공적으로 삭제되었습니다.');
          router.push('/admin/event/select'); // 성공 시 이벤트 목록 페이지로 리다이렉션
        } else {
          alert('이벤트를 삭제하지 못했습니다.');
        }
      } catch (error) {
        console.error('이벤트 삭제 중 오류가 발생했습니다:', error);
        alert('이벤트 삭제 과정에서 오류가 발생했습니다.');
      }
    }

    const goBack = () => {
      router.go(-1);
    }

    const showPieceSelectModal = ref(false);
    const showUserATypeModal = ref(false);
    const showUserBTypeModal = ref(false);

    const showModal = (type) => {
      showPieceSelectModal.value = type === 'piece';
      showUserATypeModal.value = type === 'userA';
      showUserBTypeModal.value = type === 'userB';
    };

    const closeAllModals = (type) => {
      showPieceSelectModal.value = false;
      showUserATypeModal.value = false;
      showUserBTypeModal.value = false;
    };

    const updateEventAccountList = (newSelectedPM) => {
      console.log("Received data from child:", newSelectedPM);
      MasterEventData.value.a_account_list = newSelectedPM;
    }

    const updateEventClientList = (newSelectedClient) => {
      console.log("Received data from child:", newSelectedClient);
      MasterEventData.value.b_account_list = newSelectedClient;
    }

    const updateEventPieceList = (newSelectedPiece) => {
      console.log("Received data from child:", newSelectedPiece);
      MasterEventData.value.piece_event_list = newSelectedPiece;
    }



    onMounted(async () => {
      const isAuthenticated = store.state.userStore.isAuthenticated;
      const accountType = store.state.userStore.accountType;
      if (!isAuthenticated) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else if (accountType !== 'A1' && accountType !== 'A2') {
        alert("접근 권한이 없습니다.")
        router.push({ name: 'eventList' }); // 리스트로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        userName.value = store.state.userStore.userName;
        token.value = store.state.userStore.token;
        if (eventId.value) {
          fetchEventMasterDetail()
        }
      }
    });

    return {
      eventId,
      isUpdateMode,
      MasterEventData,
      isProcessing,
      processEditModeOrSaveMode,
      goBack,
      showModal,
      closeAllModals,
      showPieceSelectModal,
      showUserATypeModal,
      showUserBTypeModal,
      updateEventAccountList,
      updateEventClientList,
      updateEventPieceList,
      deleteMasterEvent
    };
  },
}

</script>

<template>
  <div id="eventSettingMaster" class="back_light_gray2">
    <div class="evetSettingMaster_wrap mx_1160">
      <div class="common_top">
        <i class="icon-down_arrow goback size_32" @click="goBack"></i>
        <p class="size_32 b_700 ">상담회 세팅</p>
        <p class="master_icon"><i class="icon-crown"></i></p>
      </div>
      <div class="eventSetting_cont_wrap">
        <div class="eventSetting_info_title ds_flex">
          <img src="@/assets/img/event/event_setting_icon1.png" class="title_img" />
          <input type="text" v-model="MasterEventData.title" placeholder="마스터 행사명을 입력하세요." />
        </div>
        <div class="eventSetting_info_cont_wrap">
          <div class="eventSetting_info_cont ds_flex">
            <label class="eventSetting_info_cont_label">포함된 행사<span class="require">*</span></label>
            <div class="ds_flex">
              <template v-if="MasterEventData.piece_event_list">
                <p class="eventSetting_info_box" v-for="(item, index) in MasterEventData.piece_event_list" :key="index">
                  {{ item.title }}</p>
                <p class="inline_cont_btn" @click="showModal('piece')"><i class="icon-edit"></i></p>
              </template>
              <template v-else>
                <p class="block_cont_btn" @click="showModal('piece')">선택</p>
              </template>
            </div>
          </div>
          <div class="eventSetting_info_cont ds_flex">
            <label class="eventSetting_info_cont_label">고객사<span class="require">*</span></label>
            <div class="ds_flex">
              <template v-if="MasterEventData.b_account_list">
                <p class="eventSetting_info_box" v-for="(item, index) in MasterEventData.b_account_list" :key="index">{{
                  item.company
                  }} {{ item.full_name }}</p>
                <p class="inline_cont_btn" @click="showModal('userB')"><i class="icon-edit"></i></p>
              </template>
              <template v-else>
                <p class="block_cont_btn" @click="showModal('userB')">선택</p>
              </template>
            </div>
          </div>
          <div class="eventSetting_info_cont ds_flex">
            <label class="eventSetting_info_cont_label">담당 PM<span class="require">*</span></label>
            <div class="ds_flex">
              <template v-if="MasterEventData.a_account_list">
                <p class="eventSetting_info_box" v-for="(item, index) in MasterEventData.a_account_list" :key="index">{{
                  item.company
                  }} {{ item.full_name }}</p>
                <p class="inline_cont_btn" @click="showModal('userA')"><i class="icon-edit"></i></p>
              </template>
              <template v-else>
                <p class="block_cont_btn" @click="showModal('userA')">선택</p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="floating_btn">
      <div class="mx_1160">
        <div class="ds_flex">
          <p class="block_main_btn delete" @click="deleteMasterEvent">삭제</p>
          <p class="block_main_btn save" @click="processEditModeOrSaveMode" :disabled="isProcessing">저장</p>
        </div>
      </div>
    </div>
    <event-setting-master-modal :show-user-a-type-modal="showUserATypeModal"
      :show-user-b-type-modal="showUserBTypeModal" :show-piece-select-modal="showPieceSelectModal"
      @close="closeAllModals" :a_account_list="MasterEventData.a_account_list"
      :b_account_list="MasterEventData.b_account_list" :piece_event_list="MasterEventData.piece_event_list"
      @updateSelectedPM="updateEventAccountList" @updateSelectedClient="updateEventClientList"
      @updateSelectedPiece="updateEventPieceList" />

  </div>
</template>
