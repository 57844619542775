<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    showTimeTableGenerator: Boolean,
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    const closeModals = () => {
      emit('close');
    }

    const formatDate = (date) => {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    // ✅ range는 날짜 객체로 관리
    const range = ref({
      start: new Date(),
      end: new Date(new Date().setDate(new Date().getDate() + 3)),
    })
    const getDefaultTimeTable = () => {
      const today = new Date()
      const threeDaysLater = new Date()
      threeDaysLater.setDate(today.getDate() + 3)

      // range 초기값과 일치시킴
      range.value = {
        start: today,
        end: threeDaysLater,
      }

      return {
        startDate: formatDate(today),
        endDate: formatDate(threeDaysLater),
        startTime: '00:00',
        endTime: '00:00',
        isWeekendExcluded: false,
        breakTime: 0,
        duration: 0,
        hasLunchTime: null,
        lunchStartTime: '12:00',
        lunchEndTime: '13:30',
      }
    }

    const timeTable = ref(getDefaultTimeTable())

    const resetTimeTable = () => {
      timeTable.value = getDefaultTimeTable()
    }


    // range → timeTable 날짜로 포맷해서 반영
    watch(range, (newRange) => {
      if (newRange?.start && newRange?.end) {
        timeTable.value.startDate = formatDate(newRange.start)
        timeTable.value.endDate = formatDate(newRange.end)
      }
    })
    const masks_date = ref({
      data: 'YYYY-MM-DD',
      input: 'YYYY-MM-DD',
    });

    const masks_time = ref({
      modelValue: 'HH:mm',
    });

    const rules = ref({
      minutes: { interval: 5 },
    });

    const generateTimeTable = () => {
      const {
        startDate,
        endDate,
        startTime,
        endTime,
        duration,
        breakTime,
        hasLunchTime,
        lunchStartTime,
        lunchEndTime,
      } = timeTable.value

      // 필수 항목 유효성 체크
      if (!startDate || !endDate) {
        alert("상담 시작일과 종료일을 선택해주세요.");
        return;
      }

      if (startTime === '00:00' || endTime === '00:00') {
        alert("상담 시작 시간과 종료 시간을 설정해주세요.");
        return;
      }

      if (!duration || duration <= 0) {
        alert("상담 시간을 입력해주세요.");
        return;
      }

      if (hasLunchTime === true) {
        if (lunchStartTime === '00:00' || lunchEndTime === '00:00') {
          alert("점심시간이 '있음'일 경우 시작/종료 시간을 설정해주세요.");
          return;
        }
      }
      if (confirm("상담 테이블을 생성할까요?")) {
        const result = {
          ...timeTable.value,
          lunchStartTime: timeTable.value.hasLunchTime ? timeTable.value.lunchStartTime : null,
          lunchEndTime: timeTable.value.hasLunchTime ? timeTable.value.lunchEndTime : null,
        }
        emit('generateTimeTable', result)
        emit('close')
        resetTimeTable()
      } else {

      }
    }

    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;

      }
    });
    return {
      closeModals,
      timeTable,
      range,
      masks_date,
      masks_time,
      rules,
      generateTimeTable
    }
  }
});
</script>

<template>
  <div id="eventSettingTimeTableModal">
    <div class="overlay" v-if="showTimeTableGenerator"></div>
    <div class="modal" v-if="showTimeTableGenerator">
      <p class="icon_box close" @click="closeModals">
        <i class="icon-delete sub_text"></i>
      </p>
      <p class="modal_title size_28 b_700">상담 테이블 생성</p>
      <p class="modal_description secondary_text">*쉬는 시간과 점심 시간이 자동으로 계산됩니다.</p>
      <div class="input_wrap">
        <fieldset class="">
          <div class="ds_flex al_center">
            <label class="secondary_text b_700">상담일<b class="main_text">*</b></label>
            <VDatePicker v-model.range="range" mode="day-content" local="KO">
              <template #default="{ togglePopover, inputValue }">
                <p class="flex3 fake_textbox size_14 b_700" @click="togglePopover">{{ inputValue.start }}-{{
                  inputValue.end }}
                </p>
              </template>
            </VDatePicker>
          </div>
          <div class="checkbox_wrap ds_flex al_center jus_end">
            <label for="isWeekendExcluded" class="fake_checkbox" :class="{ 'active': timeTable.isWeekendExcluded }">
              <i v-if="timeTable.isWeekendExcluded" class="icon-checked"></i>
            </label>
            <input type="checkbox" v-model="timeTable.isWeekendExcluded" id="isWeekendExcluded" />
            <label for="isWeekendExcluded" class="size_14 secondary_text b_500">주말제외</label>
          </div>
        </fieldset>
        <fieldset class="ds_flex">
          <label class="secondary_text b_700">상담시간<b class="main_text">*</b></label>
          <div class="fake_textbox flex3">
            <div class="time_table_time ds_flex">
              <label class="flex1 size_14">시작</label>
              <VDatePicker v-model.string="timeTable.startTime" mode="time" is24hr :rules="rules" :masks="masks_time"
                hide-time-header>
                <template #default="{ togglePopover }">
                  <p class="time b_700 size_14 flex4" :class="{ 'not_entered_text': timeTable.startTime === '00:00' }"
                    @click="togglePopover">{{ timeTable.startTime ? timeTable.startTime :
                      "HH:MM" }}</p>
                </template>
              </VDatePicker>
            </div>
            <p>-</p>
            <div class="time_table_time ds_flex">
              <label class="flex1">종료</label>
              <VDatePicker v-model.string="timeTable.endTime" mode="time" is24hr :rules="rules" :masks="masks_time"
                hide-time-header>
                <template #default="{ togglePopover }">
                  <p class="time b_700 size_14 flex4" :class="{ 'not_entered_text': timeTable.endTime === '00:00' }"
                    @click="togglePopover">
                    {{
                      timeTable.endTime ? timeTable.endTime : "HH:MM"
                    }}</p>
                </template>
              </VDatePicker>
            </div>
          </div>
        </fieldset>
        <fieldset class="ds_flex">
          <label class="secondary_text b_700">상담시간<b class="main_text">*</b></label>
          <div class="flex3 fake_textbox ds_flex al_center gap8">
            <input type="number" v-model="timeTable.duration" class="t_right flex6 b_700 size_14"
              :class="{ 'not_entered_text': timeTable.duration === 0 }" placeholder="분" /><span class="size_14">분</span>
          </div>
        </fieldset>
        <fieldset class="ds_flex">
          <label class="secondary_text b_700">쉬는시간<b class="main_text">*</b></label>
          <div class="flex3 fake_textbox ds_flex al_center gap8">
            <input type="number" v-model="timeTable.breakTime" class="t_right flex6 b_700 size_14"
              :class="{ 'not_entered_text': timeTable.breakTime === 0 }" placeholder="분" /><span
              class="size_14">분</span>
          </div>
        </fieldset>
        <fieldset class="ds_flex">
          <label class="secondary_text b_700">점심시간<b class="main_text">*</b></label>
          <div class="ds_flex gap12 flex3 btn_wrap">
            <p class="block_main_btn flex1 size_14 b_700" :class="{ 'active': timeTable.hasLunchTime }"
              @click="timeTable.hasLunchTime = true">있음</p>
            <p class="block_main_btn flex1 size_14 b_700" :class="{ 'active': timeTable.hasLunchTime === false }"
              @click="timeTable.hasLunchTime = false;">없음</p>
          </div>
        </fieldset>
        <fieldset class="ds_flex jus_end" v-if="timeTable.hasLunchTime">
          <label class="secondary_text b_700"></label>
          <div class="fake_textbox flex3">
            <div class="time_table_time ds_flex">
              <label class="flex1 size_14">시작</label>
              <VDatePicker v-model.string="timeTable.lunchStartTime" mode="time" is24hr :rules="rules"
                :masks="masks_time" hide-time-header>
                <template #default="{ togglePopover }">
                  <p class="time b_700 size_14 flex4"
                    :class="{ 'not_entered_text': timeTable.lunchStartTime === '00:00' }" @click="togglePopover">{{
                      timeTable.lunchStartTime ? timeTable.lunchStartTime :
                        "HH:MM" }}</p>
                </template>
              </VDatePicker>
            </div>
            <p>-</p>
            <div class="time_table_time ds_flex">
              <label class="flex1">종료</label>
              <VDatePicker v-model.string="timeTable.lunchEndTime" mode="time" is24hr :rules="rules" :masks="masks_time"
                hide-time-header>
                <template #default="{ togglePopover }">
                  <p class="time b_700 size_14 flex4"
                    :class="{ 'not_entered_text': timeTable.lunchEndTime === '00:00' }" @click="togglePopover">
                    {{
                      timeTable.endTime ? timeTable.lunchEndTime : "HH:MM"
                    }}</p>
                </template>
              </VDatePicker>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="btn_wrap ds_flex gap18 jus_end">
        <p class="block_main_btn active_btn b_700" @click="generateTimeTable">추가하기</p>
      </div>
    </div>
  </div>
</template>
