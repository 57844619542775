import router from '@/router'
import { useStore } from 'vuex';

const userStore = {
    namespaced: true,
    state: () => ({
        isAuthenticated: false,
        email: '',
        accountId: '',
        accountType: '',
        userName: '',
        userCompany: '',
        token: '',
        multitype_user: false
    }),
    mutations: {
        login(state, status) {
            state.isAuthenticated = status;
        },
        login_info(state, payload) {
            state.email = payload.email;
            state.accountId = payload.account_id;
            state.accountType = payload.account_type;
            state.userName = payload.full_name;
            state.userCompany = payload.company;
            state.token = payload.token;
        },
        setMultiTypeUser(state, value) {
            state.multitype_user = value;
        },
        resetUserState(state) {
            state.email = '';
            state.accountId = '';
            state.accountType = '';
            state.userName = '';
            state.token = '';
            state.isAuthenticated = false;
        }
    },
    actions: {
        loginCheck({ state, commit }) {
            const routeMeta = router.currentRoute.value.meta;
            // 비로그인 페이지 홈으로 튕기는 이슈로 메타데이터 체크 후 true인 경우는 체크 패스
            // if (routeMeta.noLoginRequired) {
            if (routeMeta.noLoginRequired && !state.token) {
                router.push({
                    name: 'home'
                }).catch(error => {
                    console.log(error);
                });
            } else {
                const tokenExpired = isTokenExpired(state.token);
                if (tokenExpired) {
                    alert("토큰이 만료되었습니다. 다시 로그인해주세요.");
                    localStorage.removeItem('vuex');
                    commit('resetUserState');
                    router.push({
                        name: 'home'
                    }).catch(error => {
                        console.log(error);
                    });
                }
            }
            // } else {
            //     return;
            // }
        },
        logout({ commit }) {
            try {
                if (confirm("로그아웃 하시겠습니까?")) {
                    localStorage.removeItem('vuex');
                    commit('resetUserState');
                    commit('eventStore/resetState', null, { root: true });
                    commit('bisStore/resetState', null, { root: true });
                    router.push({ name: 'home' }).catch(error => {
                        console.log(error);
                    });
                } else {
                    return;
                }
            } catch (error) {
                console.error(error);
            } finally {
                // 페이지 리로드
                window.location.reload();
            }
        }
    }
};

export default userStore;

function isTokenExpired(token) {
    if (!token) {
        // 토큰 없음 → false 반환
        return false;
    }
    if (token.split('.').length < 3) {
        // 유효하지 않은 토큰 → true 반환
        console.warn('Invalid token format');
        return true;
    }
    try {
        const base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
        const decodedPayload = JSON.parse(atob(base64));
        const expirationDate = new Date(decodedPayload.exp * 1000);
        // 만료된 토큰 → true 반환
        return expirationDate < new Date();
    } catch (error) {
        console.error('Error decoding token:', error);
        // 유효하지 않은 토큰 → true 반환
        return true;
    }
}

