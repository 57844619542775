<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';
import { useToast } from 'vue-toastification';
import country from '@/assets/js/bisatCountry'

export default defineComponent({
  name: 'preMatchRequestEachTime',
  props: {
    showPreMatchRequestModal: Boolean,
    timeInfo: Object
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();
    const toast = useToast(); // Toast 호출 함수

    const eventId = computed(() => store.state.eventStore.eventId);
    const selectDate = computed(() => store.state.eventStore.eventDate);
    const isAuthenticated = ref('');
    const token = ref('');

    const closeModals = () => {
      emit('close');
    }

    // 검색 필터 관련
    const searchText = ref('')
    const productList = ref([''])
    const productSelect = ref([''])
    const activeTab = ref('')
    // 품목 불러오기
    const fetchProductList = async () => {
      try {
        // axios를 사용하여 이벤트 상세 정보를 가져옵니다.
        const response = await axios.get('/event/read_piece', {
          params: {
            piece_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        if (response.data.result === true) {
          productList.value = response.data.piece_event.product_list
        } else {
          console.log("품목 로드 실패")
        }
      } catch (error) {
        console.error('이벤트 데이터를 가져오는 중에 오류가 발생했습니다:', error);
      }
    }
    const countryList = ref([])
    const getCountryLabel = (value) => {
      const selectedCountry = country.find(item => item.code === value);
      return selectedCountry ? selectedCountry.label : value;
    };
    const countrySelect = ref([])
    // 국가 불러오기
    async function fetchCountryList() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/event/read_account_country_list', {
          params: {
            piece_event_id: eventId.value,
            account_type: "BUYER"
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          countryList.value = response.data.country_list.map(code => {
            return {
              code,
              label: getCountryLabel(code), // 코드에 해당하는 라벨을 가져옵니다.
            };
          });
        } else {
          console.log("국가 로드 실패");
        }
      } catch (error) {
      }
    }


    const selectedList = ref({
      product: [], // 선택된 품목 리스트
      country: [] // 선택된 국가 리스트
    });
    const isActive = (type, id) => {
      return selectedList.value[type].some(item => item.value === id);
    };
    // 체크박스 변경 시 실행되는 함수
    const handleCheckboxChange = (item, event) => {
      const type = activeTab.value; // 현재 탭 타입 (product 또는 country)
      const selected = selectedList.value[type];

      const newItem = {
        value: item.ptp_id || item.code, // 항목의 고유 ID (value)
        label: item.product || item.label  // 항목의 표시 이름 (label)
      };

      if (event.target.checked) {
        // 체크된 경우 객체를 추가 (중복 검사)
        const exists = selected.some(selectedItem => selectedItem.value === newItem.value);
        if (!exists) selected.push(newItem);
      } else {
        // 체크 해제된 경우 객체를 제거
        const index = selected.findIndex(selectedItem => selectedItem.value === newItem.value);
        if (index > -1) selected.splice(index, 1);
      }
    };

    const allSelectedLabels = computed(() => {
      return [
        ...selectedList.value.product.map(item => item.label),
        ...selectedList.value.country.map(item => item.label)
      ];
    });

    const getLabelDisplay = (list) => {
      if (list.length === 0) return ''; // 선택된 항목이 없으면 빈 문자열 반환
      if (list.length === 1) return ` :${list[0].label}`; // 하나만 선택된 경우
      return ` :${list[0].label} 외 ${list.length - 1}개`; // 두 개 이상인 경우
    };

    const productLabelDisplay = computed(() => getLabelDisplay(selectedList.value.product));
    const countryLabelDisplay = computed(() => getLabelDisplay(selectedList.value.country));


    const bookmark = ref(false)
    const handleBookmark = () => {
      bookmark.value = !bookmark.value;
    };

    // 추천 바이어 리스트
    const recommendSection = ref(true)
    const handleRecommendSection = () => {
      recommendSection.value = !recommendSection.value;
    };
    const recommendBuyerList = ref([])
    async function fetchRecommendBuyerList() {
      recommendBuyerList.value = []
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          ptd_id_list: [props.timeInfo.ptd_id],
        });

        const url = `/prematching/read_pre_recommended_buyer_list?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          recommendBuyerList.value = response.data.buyer_list.map(buyer => ({
            ...buyer,
            similarity: `${Math.round(buyer.similarity * 100)}%`, // 소수점 값을 퍼센트로 변환
          }));
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }

    // 일반 바이어 리스트
    const sortOption = ref('popular');
    const buyerList = ref([])
    async function fetchBuyerList() {
      try {
        const sortMap = {
          popular: { sortBy: 'favorite_count', orderBy: 'DESC' },
          asc: { sortBy: 'buyer_full_name', orderBy: 'ASC' },
          desc: { sortBy: 'buyer_full_name', orderBy: 'DESC' },
        };

        const { sortBy, orderBy } = sortMap[sortOption.value];
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          account_type: 'BUYER',
          ptd_id_list: Array.isArray(props.timeInfo.ptd_id) ? props.timeInfo.ptd_id : [props.timeInfo.ptd_id],
          sort_by: sortBy,
          order_by: orderBy
        });

        // 검색 텍스트가 있는 경우 추가
        if (searchText.value.trim()) {
          queryParams.append('search_by', 'company');
          queryParams.append('search_text', searchText.value.trim());
        }
        // 선택된 품목 필터 추가
        if (selectedList.value.product.length > 0) {
          selectedList.value.product.forEach(product =>
            queryParams.append('product_list[]', product.value)
          );
        }

        // 선택된 국가 필터 추가
        if (selectedList.value.country.length > 0) {
          selectedList.value.country.forEach(country =>
            queryParams.append('country_list[]', country.value)
          );
        }

        const url = `/prematching/read_account_list_for_matching?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.data.result === true) {
          buyerList.value = response.data.account_list
        } else {
          alert("에러");
        }
      } catch (error) {
        console.error("스케줄 일시 불러올때 오류:", error);
      }
    }

    watch(
      () => props.timeInfo,
      async (newValue) => {
        if (newValue && newValue.ptd_id) {
          await fetchRecommendBuyerList();
          await fetchBuyerList();
        }
      },
      { immediate: true }
    );

    // 관심 바이어
    const createFavoriteBuyer = async (buyerId) => {
      const queryParams = new URLSearchParams({
        piece_event_id: eventId.value,
        buyer_id: buyerId,
      });
      const url = `/prematching/create_pre_buyer_favorite?${queryParams.toString()}`;
      try {
        const response = await axios.post(url, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          toast.success('관심 바이어로 등록했습니다.');
          fetchBuyerList()
        } else {

        }
      } catch (error) {
        console.error(error);
      }
    }


    // 매칭 신청하기
    const requestMatching = async (buyerInfo) => {
      try {
        const response = await axios.post('/prematching/create_pre_schedule', {
          piece_event_id: eventId.value,
          ptb_id: buyerInfo.ptb_id,
          ptd_id: props.timeInfo.ptd_id,
          buyer_id: buyerInfo.account_id,
        }, {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          toast.success('매칭이 신청되었습니다.');
        } else {
        }
      } catch (error) {
        console.error(error);
        if (error.response.data.detail === 'already p_schedule') {
          toast.error("이미 선택한 시간에 다른 매칭 스케줄이 있습니다.");
        }
      }
    }



    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        token.value = store.state.userStore.token;
        await fetchProductList();
        await fetchCountryList();
      }
    });
    return {
      closeModals,
      bookmark,
      handleBookmark,

      searchText,
      productList,
      countryList,
      productSelect,
      countrySelect,
      activeTab,
      selectedList,
      allSelectedLabels,
      isActive,
      handleCheckboxChange,
      productLabelDisplay,
      countryLabelDisplay,
      handleRecommendSection,
      recommendSection,

      recommendBuyerList,
      buyerList,
      getCountryLabel,
      createFavoriteBuyer,
      requestMatching,
      fetchBuyerList,
      sortOption
    }
  },
});
</script>

<template>
  <div id="preMatchRequestEachTime">
    <div class="overlay" v-if="showPreMatchRequestModal"></div>
    <transition name="slide-up">
      <div class="bottom_sheet" v-if="showPreMatchRequestModal">
        <div class="sheet_header jus_between">
          <p class="size_20 b_700">상담을 원하는 바이어가 있으세요?</p>
          <p class="icon_box" @click="closeModals"><i class="icon-delete"></i></p>
        </div>
        <div class="sheet_content">
          <div class="ds_flex jus_between back_light_gray3">
            <p class="size_14 secondary_text">상담시간</p>
            <p class="b_700">{{ timeInfo.start_time }}~{{ timeInfo.end_time }}</p>
          </div>
          <div class="cont_wrap">
            <div class="list_wrap">
              <div class="recommend_list" v-if="recommendBuyerList.length > 0">
                <div class="top">
                  <div class="ds_flex jus_between al_center">
                    <div class="ds_flex gap4 al_center">
                      <b class="SELLER_text">AI</b>
                      <p class="b_700">추천 바이어</p>
                    </div>
                    <div class="ds_flex gap4 al_center">
                      <p class="size_12 secondary_text" @click="handleRecommendSection">
                        {{ recommendSection ? '추천 접기' : '추천 열기' }}
                      </p>
                      <i :class="recommendSection ? 'icon-up_arrow' : 'icon-down_arrow'"
                        class="size_12 secondary_text"></i>
                    </div>
                  </div>
                  <p class="size_12 secondary_text">AI가 분석한 우리 회사와 매칭 성공률이 높은 바이어 리스트예요.</p>
                </div>
                <div v-for="(buyer, index) in recommendBuyerList" :key="index" class="list" v-if="recommendSection">
                  <div class="list_cont">
                    <div class="list_cont_top ds_flex jus_between al_center">
                      <div class="ds_flex gap8 al_center">
                        <b class="SELLER_text size_12">매칭 {{ buyer.similarity }}</b>
                        <p class="size_14 b_700">{{ buyer.company }}</p>
                      </div>
                      <div class="ds_flex gap8 al_center">
                        <b class="size_12 sub_text">{{ buyer.numberOfProducts }}</b>
                        <i @click="handleBookmark"
                          :class="buyer.favorite ? ['icon-bookmark_on', 'main_text'] : ['icon-bookmark', 'sub_text']">
                        </i>
                      </div>
                    </div>
                    <div class="list_cont_bottom">
                      <div class="ds_flex gap12 al_center">
                        <p class="badge country">{{ getCountryLabel(buyer.country) }}</p>
                        <p class="badge product">{{ buyer.product }}</p>
                      </div>
                    </div>
                    <div class="ds_flex jus_end">
                      <p class="inline_cont_btn size_12">매칭 신청하기</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="search_wrap ds_flex dir_column gap20">
                <div class="fake_textbox ds_flex w100">
                  <input class="" v-model="searchText" @keyup="fetchBuyerList" placeholder="기업명으로 검색" />
                  <i class="icon-search"></i>
                </div>
                <div class="ds_flex gap8 w100 filter_wrap">
                  <div class="fake_select" @click="activeTab = 'product'" :class="{ 'active': productLabelDisplay }">
                    <p class="select">품목 {{ productLabelDisplay }}<i class="icon-down_arrow size_15"></i></p>
                  </div>
                  <div class="fake_select" @click="activeTab = 'country'" :class="{ 'active': countryLabelDisplay }">
                    <p class="select">국가 {{ countryLabelDisplay }}<i class="icon-down_arrow size_15"></i></p>
                  </div>
                  <div class="option_wrap" v-if="activeTab">
                    <p class="icon_box close" @click="activeTab = ''"><i class="icon-delete sub_text"></i></p>
                    <div class="page_tab_wrap">
                      <p class="tab" :class="{ 'active': activeTab === 'product' }" @click="activeTab = 'product'">품목
                      </p>
                      <p class="tab" :class="{ 'active': activeTab === 'country' }" @click="activeTab = 'country'">국가
                      </p>
                    </div>
                    <div class="tab_cont">
                      <template v-if="activeTab === 'product'">
                        <div class="cont" v-for="item in productList" :key="item.ptp_id">
                          <label class="fake_checkbox" :for="'product' + item.ptp_id"
                            :class="{ 'active': isActive('product', item.ptp_id) }">
                            <i v-if="isActive('product', item.ptp_id)" class="icon-checked"></i>
                          </label>
                          <input type="checkbox" :id="'product' + item.ptp_id"
                            @change="handleCheckboxChange(item, $event)" :checked="isActive('product', item.ptp_id)" />
                          <label :for="'product' + item.ptp_id">{{ item.product }}</label>
                        </div>
                      </template>
                      <template v-else-if="activeTab === 'country'">
                        <div class="cont" v-for="item in countryList" :key="item.code">
                          <label class="fake_checkbox" :for="'country' + item.code"
                            :class="{ 'active': isActive('country', item.code) }">
                            <i v-if="isActive('country', item.code)" class="icon-checked"></i>
                          </label>
                          <input type="checkbox" :id="'country' + item.code"
                            @change="handleCheckboxChange(item, $event)" :checked="isActive('country', item.code)" />
                          <label :for="'country' + item.code">{{ item.label }}</label>
                        </div>
                      </template>
                    </div>
                    <div v-if="selectedList.product.length > 0 || selectedList.country.length > 0"
                      class="selected_filter">
                      <p class="" v-for="item, index in allSelectedLabels" :key="index">{{ item }}<i
                          class="icon-delete"></i></p>
                    </div>
                    <p class="block_sub_btn active_btn b_600" @click="fetchBuyerList">필터 적용하기</p>
                  </div>
                </div>
              </div>
              <div class="buyer_list">
                <div class="top">
                  <div class="ds_flex jus_between al_center">
                    <p class="b_700">전체 바이어</p>
                    <select class="size_12" v-model="sortOption" @change="fetchBuyerList">
                      <option value="popular">인기순</option>
                      <option value="asc">오름차순</option>
                      <option value="desc">내림차순</option>
                    </select>
                  </div>
                </div>
                <div class="list">
                  <template v-if="buyerList.length > 0">
                    <div v-for="(buyer, index) in buyerList" :key="index" class="list_cont">
                      <div class="list_cont_top ds_flex jus_between al_center">
                        <p class="size_14 b_700">{{ buyer.company }}</p>
                        <div class="ds_flex gap8 al_center">
                          <b class="size_12 sub_text">{{ buyer.other_fav_count }}</b>
                          <i class="sub_text" @click="createFavoriteBuyer(buyer.account_id)"
                            :class="buyer.favorite ? ['icon-bookmark_on', 'main_text'] : ['icon-bookmark', 'sub_text']">
                          </i>
                        </div>
                      </div>
                      <div class="list_cont_bottom">
                        <div class="ds_flex gap12 al_center">
                          <p class="badge country">{{ getCountryLabel(buyer.country) }}</p>
                          <p class="badge product">{{ buyer.product }}</p>
                        </div>
                      </div>
                      <div class="ds_flex jus_end">
                        <p class="inline_cont_btn size_12" @click="requestMatching(buyer)">매칭 신청하기</p>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <p class="no_data">데이터가 없습니다.</p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
