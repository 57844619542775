<script>
import { ref, onMounted, computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { countries } from "@/assets/js/metafieldsCountry.js";
import { itemGroups } from "@/assets/js/metafieldsItems.js";

export default {
  name: 'metaFields',
  components: {
  },
  setup() {
    // 차트 옵션
    const axios = inject('axios'); // $axios 주입 받기
    const router = useRouter(); // router 인스턴스 사용
    const route = useRoute(); // 현재 라우트 정보 사용

    const selectedCountry = ref('');
    const selectedItemGroup = ref('');
    const selectedClient = ref('a');


    const slides = ref([
      {
        title: '수출상담액/계약추진액',
        description: '메타필드 AI 모델이 예측한 수출상담액 및 계약추진액을 확인해 보세요.',
        sDescription: '각 항목은 상담을 진행한 모든 품목의 거래 추정 금액(수출상담액)과 실제 계약 가능성이 있는 품목의 거래 추정 금액(계약추진액)을 나타냅니다',
        image: require('@/assets/img/landing/metafiled_landing_sec4_img1.png')
      },
      {
        title: '상담 비중',
        description: '수출상담회 전체 실적에서 해당 국가의 바이어 또는 해당 분야의 상담이 얼마만큼의 비중을 차지할지 예측해 보세요.',
        sDescription: '',
        image: require('@/assets/img/landing/metafiled_landing_sec4_img2.png')
      },
      // Add other slides here
    ]);
    const guides = ref([
      {
        description: '어느 나라 바이어를 초청할지 고민하고 있다면?',
        title: "'국가 검색'으로 바이어 분석을 확인해 보세요.",
        image: require('@/assets/img/landing/metafiled_landing_sec5_img1.png')
      },
      {
        description: '우리 회사 제품의 해외 수출을 목표하고 있다면?',
        title: "'분야 검색'으로 유사한 품목들의 상담회 실적을 확인해 보세요.",
        image: require('@/assets/img/landing/metafiled_landing_sec5_img2.png')
      }
    ]);

    const handleButtonClick = () => {
      if (selectedCountry.value && selectedItemGroup.value) {
        router.push({
          name: "metaFieldsResult", // 이동할 라우트 이름
          query: {
            country: selectedCountry.value,
            itemGroup: selectedItemGroup.value,
          },
        });
      } else {
        alert("국가와 분야를 모두 선택해주세요."); // 입력값 유효성 체크
      }
    };
    const heatmap = ref(null);  // heatmap 데이터를 관리할 reactive 변수
    const isLoading = ref(false);  // 로딩 상태를 관리하는 변수
    const error = ref(null);  // 에러 메시지를 관리하는 변수

    // heatmap 데이터를 가져오는 함수
    const dataForHeatmap = async () => {
      isLoading.value = true;  // 로딩 시작
      error.value = null;  // 이전 에러 초기화

      try {
        const response = await axios.post('/metafields/api_heatmap', {}, {
          headers: {
            'Content-Type': 'application/json', // POST 요청시 JSON 헤더를 명시
          },
        });

        if (response.data.success === false && response.data.message) {
          console.log("failed", response.data.message);
          error.value = response.data.message; // 에러 메시지 처리
        } else if (response.data.success === true) {
          heatmap.value = JSON.parse(response.data.result)[0]; // heatmap 데이터 저장
        }
      } catch (err) {
        console.log("catched error", err);
        error.value = 'An error occurred while fetching the heatmap data';  // 에러 처리
      } finally {
        isLoading.value = false;  // 로딩 끝
      }
    };

    onMounted(() => {

    });

    return {
      selectedCountry,
      selectedItemGroup,
      selectedClient,
      countries,
      itemGroups,
      slides,
      guides,
      handleButtonClick
    };
  }
};
</script>

<template>
  <div id="metaFields" class="back_light_gray3">
    <div class="metafield-main">
      <section class="section1">
        <div class="sec_head_img">
          <div class="width_1034">
            <div class="head_ttl_wrap">
              <div class="head_ttl">어떤 수출상담회의 예측 데이터가 필요하신가요?</div>
              <div class="head_text">수출상담회 빅데이터 예측·분석 전문 플랫폼 Meta-Field</div>
            </div>
            <div class="head_search_wrap">
              <div class="search_wrap">
                <div class="search_wrap_inner">
                  <img src="@/assets/img/landing/icon_country.png" width="28" alt="search country">
                  <div class="search_field">
                    <div class="search_ttl">국가</div>
                    <select name="country" id="country" class="search_val" v-model="selectedCountry">
                      <option value="">국가를 선택하세요.</option>
                      <option v-for="country in countries" :key="country.code" :value="country.code">{{ country.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="search_divide_line"></div>
              <div class="search_wrap">
                <div class="search_wrap_inner">
                  <img src="@/assets/img/landing/icon_itemgroup.png" width="28" alt="search itemgroup">
                  <div class="search_field">
                    <div class="search_ttl">분야</div>
                    <select name="itemgroup" id="itemgroup" class="search_val" v-model="selectedItemGroup">
                      <option value="">분야를 선택하세요.</option>
                      <option v-for="(itemGroup, index) in itemGroups" :key="index" :value="itemGroup">{{ itemGroup }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <p class="search_btn" @click="handleButtonClick"></p>
            </div>
          </div>
          <div id="heatmap" style="width: 90%; height: 800px;"></div>
        </div>
      </section>
      <section class="section2">
        <div class="width_1034" data-aos="fade-down" data-aos-duration="1500">
          <div class="content">
            <p class="subtitle">누구도 예상못한, 누구나 예상할 수 있는 플랫폼 Meta-Field</p>
            <p class="title">메타필드로 미리보는<br /><span>중국 바이어 300개사</span>가 참가하는 수출상담회</p>
            <p class="subtitle">BIS@T의 시작, 2017년부터 최신 데이터까지 국내·외 수출상담회 빅데이터를 기반으로<br />AI가 예측한 수출상담회에 관련된 모든 데이터를 지금
              확인해 보세요.</p>
            <p class="s_description">메타필드 AI 모델은 딥러닝 기반의 정확하고 신속한 분석을 통해, 상담회 실적 예측 및 수출산업 관련 인사이트를 제공합니다.</p>
          </div>
        </div>
      </section>
      <section class="section3">
        <div class="width_1034" data-aos="fade-down" data-aos-duration="1500">
          <div class="content">
            <p class="subtitle">수출상담회, 이제 누구나 손쉽게 예측해요</p>
            <p class="title">상담회 주관사부터 수출기업 사장님까지</p>
            <p class="description">글로벌 비즈니스를 목표로 하는 여러분에게 필요한 모든 수출상담회 예측 데이터를 손쉽게 검색하세요. 이를 통해 얻은 다양한 인사이트를 활용해, 언어
              장벽 너머의 무수한 기회들을 연결할 수 있습니다.</p>
          </div>
          <div class="content">
            <div class="field">
              <label class="checkbox_wrap" for="a">
                <input type="radio" name="client" id="a" value="a" v-model="selectedClient" />
                <label class="box" for="a"></label>
                <label for="a">수출상담회 주관사</label>
              </label>
              <label class="checkbox_wrap" for="b">
                <input type="radio" name="client" id="b" value="b" v-model="selectedClient" />
                <label class="box" for="b"></label>
                <label for="b">수출기업 사장님​</label>
              </label>
            </div>
            <hr />
            <div class="img_wrap">
              <p class="subtitle">주요 예측 데이터</p>
              <img v-if="selectedClient === 'a'" src="@/assets/img/landing/metafiled_landing_sec3_img-a.png" />
              <img v-if="selectedClient === 'b'" src="@/assets/img/landing/metafiled_landing_sec3_img-b.png" />
            </div>
          </div>
        </div>
      </section>
      <section class="section4 swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(slide, index) in slides" :key="index">
            <div class="width_1034">
              <div class="content">
                <p class="title">{{ slide.title }}</p>
                <p class="description">{{ slide.description }}</p>
                <p class="s_description">{{ slide.sDescription }}</p>
              </div>
              <div class="content">
                <img :src="slide.image" />
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </section>
      <section class="section5 swiper2" style="overflow: hidden;">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(guide, index) in guides" :key="index">
            <div class="width_1034">
              <div class="content">
                <p class="description">{{ guide.description }}</p>
                <p class="title">{{ guide.title }}</p>
              </div>
              <div class="content">
                <img :src="guide.image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
