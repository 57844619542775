<script>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  setup() {
    const windowSize = ref({
      width: 0,
      height: 0
    });
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    // 컴포넌트가 마운트될 때 호출
    onMounted(() => {
      getWindowSize();
      window.addEventListener('resize', resizeHandler);
    });

    // 컴포넌트가 언마운트되기 전에 호출
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeHandler);
    });

    // 윈도우 크기 업데이트
    function resizeHandler() {
      getWindowSize();
    }

    // 현재 윈도우 크기 가져오기
    function getWindowSize() {
      windowSize.value.width = window.innerWidth;
      windowSize.value.height = window.innerHeight;
    }

    // `isVisible` 계산된 프로퍼티
    // CSS만 정리하면 필요없을듯,,isheader 클래스 추가해주는건데 패딩탑 먹일려고,,,,
    const isVisible = computed(() => {
      const path = route.path;
      const pathName = route.name;
      return path === '/admin/event/select' || path === '/admin/event/setting/new' || pathName === 'eventListMaster';
    });

    const eventTitle = computed(() => store.state.eventStore.eventTitle);
    const eventId = computed(() => store.state.eventStore.eventId);
    const eventDate = computed(() => store.state.eventStore.eventDate);
    const scheduleDates = computed(() => store.state.eventStore.eventDates.dates); // dates가 배열인 경우
    const selectDate = ref(eventDate.value);

    const userId = computed(() => store.state.userStore.accountId);
    const userName = computed(() => store.state.userStore.userName);
    const userType = computed(() => store.state.userStore.accountType);

    function handleSelectDate() {
      store.commit('eventStore/setEventDate', selectDate.value);
      router.go(0)
    }

    const event = {
      eventTitle,
      eventId,
      eventDate,
      scheduleDates,
      handleSelectDate,
      selectDate
    };


    const user = {
      userId,
      userName,
      userType,
    };

    return {
      windowSize,
      isVisible,
      event,
      user
    };
  }
};
</script>
<template>
  <div>
    <common-header :windowSize="windowSize"></common-header>
    <router-view class="main_content" :class="[{ 'mobile': windowSize.width < 769 }, { 'isheader': isVisible }]"
      :windowSize="windowSize" :event="event" :user="user"></router-view>
  </div>
</template>