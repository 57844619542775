<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import preMatchingMySchedule from '@/views/BIS/preMatchingMySchedule.vue'
import preMatchingBuyerList from '@/views/BIS/preMatchingBuyerList.vue'
import preMatchingMarkedBuyer from '@/views/BIS/preMatchingMarkedBuyer.vue'

export default defineComponent({
  name: "preMatchingWrap",
  props: {
    event: Object
  },
  components: {
    preMatchingMySchedule,
    preMatchingBuyerList,
    preMatchingMarkedBuyer
  },
  setup(props) {
    const axios = inject("axios");
    const store = useStore();
    const route = useRoute(); // vue-router의 현재 route 정보 접근
    const router = useRouter(); // 라우터 인스턴스를 가져오기
    const isAuthenticated = ref(false);
    const eventId = computed(() => store.state.eventStore.eventId);
    const userId = computed(() => store.state.userStore.accountId);
    const token = ref('');

    const { selectDate, handleSelectDate } = props.event;
    const tabValue = ref('mySchedule')

    // 사전주선 기본정보 불러오기
    const pre_arranged_chance = ref(0)
    async function fetchPreMatchDefaultDataCount() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/prematching/read_prematching', {
          params: {
            piece_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          pre_arranged_chance.value = response.data.prematching_data.pre_arranged_chance
        } else {
          console.log("이벤트 로드 실패")
        }
      } catch (error) {
      }
    }

    const pre_arranged_mine = ref(0)
    const scheduleDate = ref([])
    async function fetchPreMatchMyDataCount() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/prematching/read_all_count_seller_self_pre_schedule', {
          params: {
            piece_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          pre_arranged_mine.value = response.data.total
          await fetchDateTime()
        } else {
          console.log("이벤트 로드 실패")
        }
      } catch (error) {
      }
    }

    const updateAccountInfo = ref('N')
    async function fetchPreMatchData() {
      try {
        // 첫 번째 API 호출
        const response1 = await axios.get('/prematching/read_pre_account_id_list', {
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 결과 확인
        if (response1.data.result === true) {
          // piece_event_id가 eventId.value와 같은 항목 필터링
          const matchingAccount = response1.data.p_account_id_list.find(
            (item) => item.piece_event_id === eventId.value
          );

          // 매칭된 p_account_id가 있을 경우
          if (matchingAccount) {
            const { p_account_id } = matchingAccount;

            // 두 번째 API 호출
            const response2 = await axios.get('/prematching/read_pre_account', {
              params: {
                p_account_id, // 필터링된 p_account_id 사용
              },
              headers: {
                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
              },
            });

            if (response2.data.result === true) {
              updateAccountInfo.value = response2.data.p_account.is_updated; // 결과 저장
              scheduleDate.value = response2.data.p_account.p_atd_list; // 결과 저장
            } else {
              console.log('이벤트 로드 실패');
            }
          } else {
            console.log('eventId에 해당하는 데이터가 없습니다.');
          }
        } else {
          console.log('이벤트 리스트 로드 실패');
        }
      } catch (error) {
        console.error('API 호출 중 오류 발생:', error);
      }
    }

    const formattedScheduleDate = computed(() => {
      return scheduleDate.value.map(date => {
        const [year, month, day] = date.date.split('-');
        return {
          value: date.date,
          label: `${parseInt(month, 10)}월 ${parseInt(day, 10)}일`,
        };
      });
    });



    // 공공 API - 로컬 아닌 개발서버에서 테스트 필요
    // const searchQuery = ref('렉스코드')
    // const searchCompany = async () => {
    //   const API_URL = "https://apis.data.go.kr/1160100/service/GetCorpBasicInfoService_V2/"; // 프록시 경로 사용
    //   const API_KEY = "mtAzA8hosIttPAPEeeXCu2tWxHP4gwq4AZYQtbLWNVT2ghmHRn2iKnWkwWSpshUZE0FQgnqBlWU3uDS8eioKGA==";

    //   try {
    //     const response = await axios.get(API_URL, {
    //       params: {
    //         ServiceKey: API_KEY,
    //         pageNo: 1, // 상호명 검색
    //         numOfRows: 10,
    //         resultType: "json", // 출력 포맷 JSON
    //         corpNm: searchQuery.value,
    //       },
    //     });

    //     if (response.data.body.items.item.length > 0) {
    //       // results.value = response.data.body.items.item
    //       // results.value = response.data.items.map((item) => ({
    //       //   companyName: item.name || "정보 없음",
    //       //   ceoName: item.ceo || "대표자 정보 없음",
    //       // }));
    //     } else {
    //       error.value = "검색 결과가 없습니다.";
    //     }
    //   } catch (err) {
    //     error.value = "검색 중 오류가 발생했습니다.";
    //   } finally {
    //     loading.value = false;
    //   }
    // };



    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        // 컴포넌트가 마운트된 후에 Vuex 스토어에서 데이터를 가져옵니다.
        token.value = store.state.userStore.token;
        await fetchPreMatchDefaultDataCount()
        await fetchPreMatchMyDataCount()
        await fetchPreMatchData()
        // searchCompany()
      }
    });

    return {
      selectDate,
      scheduleDate,
      formattedScheduleDate,
      handleSelectDate,

      pre_arranged_chance,
      pre_arranged_mine,
      tabValue,
      updateAccountInfo,
    };
  },
});
</script>

<template>
  <div id="preMatching" class="back_light_gray2">
    <div class="page_title">
      <div class="ds_flex gap8 al_center">
        <router-link :to="{ name: 'preMatchDashboard' }">
          <i class="icon-down_arrow rotate90 size_24 b_700"></i>
        </router-link>
        <p class="size_22 b_700">상담 스케줄 신청</p>
      </div>
      <p class="description size_14 sub_text">상담을 원하는 바이어가 있다면 매칭을 신청해보세요!<br />매칭 성공 가능성이 90% 이상 높아져요.</p>
    </div>
    <div class="tab_wrap" v-if="formattedScheduleDate.length > 0">
      <p class="tab" v-for="item in formattedScheduleDate" :class="{ 'active': selectDate === item.value }"
        :key="item.value" :style="{ width: `calc(100% / ${scheduleDate.length})` }"
        @click="selectDate = item.value, handleSelectDate()">{{
          item.label }}</p>
    </div>
    <div class="ds_flex jus_between al_center pre_arranged_chance back_light_gray">
      <p class="size_14 secondary_text">신청 가능 횟수</p>
      <p class="size_20 b_700">{{ pre_arranged_mine }}/{{ pre_arranged_chance }}</p>
    </div>
    <div class="re_recommend ds_flex jus_between" v-if="updateAccountInfo === 'Y'">
      <div class="text_wrap">
        <p class="size_14 b_400 secondary_text">내 정보를 업데이트하셨네요!</p>
        <p class="b_700 SELLER_text">새로운 바이어 추천 받기</p>
      </div>
      <img src="@/assets/img/bis/refresh.svg" />
    </div>
    <div class="page_tab_wrap">
      <p class="tab" @click="tabValue = 'mySchedule'" :class="{ 'active': tabValue === 'mySchedule' }">내 스케줄</p>
      <p class="tab" @click="tabValue = 'list'" :class="{ 'active': tabValue === 'list' }">바이어 리스트</p>
      <p class="tab" @click="tabValue = 'like'" :class="{ 'active': tabValue === 'like' }">관심 바이어</p>
    </div>
    <template v-if="tabValue === 'mySchedule'">
      <preMatchingMySchedule :selectDate="selectDate"></preMatchingMySchedule>
    </template>
    <template v-else-if="tabValue === 'list'">
      <preMatchingBuyerList :selectDate="selectDate"></preMatchingBuyerList>
    </template>
    <template v-else-if="tabValue === 'like'">
      <preMatchingMarkedBuyer :selectDate="selectDate"></preMatchingMarkedBuyer>
    </template>
  </div>
</template>