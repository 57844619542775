<script>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import language from '@/assets/js/bisatLang'
import field from '@/assets/js/bisatField'
import { useToast } from 'vue-toastification';

export default {
  name: 'BISMyAccount',
  components: {
  },
  props: {
    user: Object,
    event: Object,
  },
  setup(props) {
    const axios = inject('axios'); // $axios 주입 받기
    const store = useStore();
    const toast = useToast(); // Toast 호출 함수
    const isAuthenticated = ref(false);
    const token = ref('');
    const router = useRouter(); // useRouter를 사용하여 router 인스턴스를 가져옵니다.
    const route = useRoute();
    const { userName, userId, formattedUserType, userType, accountStatus } = props.user;
    const { selectDate, scheduleDate, eventId } = props.event;
    const userEmail = ref('')

    const userInfo = ref([])
    async function fetchUserInfo() {
      try {
        const response = await axios.get('/user/read_account_detail_info_for_bis', {
          params: {
            'piece_event_id': eventId.value,
            'account_id': userId.value,
            'account_type': userType.value,
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          userInfo.value = response.data.info
          if (response.data.info.company_file) {
            getPresignedUrl(response.data.info.company_file)
          }
          if (userInfo.value.keyword) {
            userInfo.value.keyword = userInfo.value.keyword
              .split(',')
              .map(item => item.trim());
          }

        } else {
          console.log("사용자 정보 로드 실패")
        }
      } catch (error) {
      }
    }

    const editMode = ref(false)
    const languageSelect = language.map(item => ({
      label: item.label,
      value: item.code
    }))
    const fieldSelect = field.map(item => ({
      label: item.label,
      value: item.code
    }))
    const getLanguageLabel = (value) => {
      const selectedLanguage = language.find(item => item.code === value);
      return selectedLanguage ? selectedLanguage.label : value;
    };

    const uploadedFileName = ref(''); // 업로드된 파일명을 저장할 ref
    const loading = ref(false)
    const showInputs = ref(false)

    const handleFileUpload = (event) => {
      loading.value = true
      const file = event.target.files[0]; // 업로드된 파일 객체 가져오기
      if (file) {
        uploadedFileName.value = file.name; // 업로드된 파일명 설정
        uploadCompanyFile(file)
      }
    };
    const handleFileDelete = () => {
      uploadedFileName.value = '';
      document.getElementById('file-upload').value = ''; // 파일 입력 필드 값 초기화
    };

    // 사용자 정보에 파일 업로드 API, 리턴받은 파일 패스는 1시간만 유효
    const uploadCompanyFile = async (file) => {
      try {
        if (!file) {
          console.error('No file selected.');
          return;
        }

        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(`/file/account?account_id=${userId.value}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          }
        });

        // console.log('Upload response:', response.data);
        if (response.data.result === true) {
          userInfo.value.company_file = response.data.file_path
          getPresignedUrl(response.data.file_path)
        } else {
          alert('저장하지 못했습니다.');
        }

      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };

    // 업로드한 파일의 s3 패스 받아오기, 로드 할때마다 새로운 파일 패스로 교체
    const presignedUrl = ref([])
    async function getPresignedUrl(filePath) {
      try {
        // axios를 사용하여 이벤트 상세 정보를 가져옵니다.
        const response = await axios.get('/file/presigned_url', {
          params: {
            file_path: filePath
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });
        if (response.data.result === true) {
          presignedUrl.value = response.data.file_info
        }

      } catch (error) {
        console.error('파일 경로를 가져오지 못했습니다.:', error);
      }
    }

    // s3에서 파일 삭제 API 
    const deleteEventFile = async () => {
      if (confirm("삭제하시겠습니까?")) { // 사용자가 확인을 누른 경우에만 실행
        try {
          const response = await axios.delete(`/file/account?account_id=${userId.value}`, {
            headers: {
              Authorization: `Bearer ${token.value}`
            }
          });
          if (response.data.result === true) {
            alert("삭제되었습니다.");
          } else {
            console.log('Delete failed:', response.data.result);
          }
        } catch (error) {
          console.error('Delete failed:', error);
        }
      }
    };


    const newKeyword = ref(''); // 🔹 사용자가 입력하는 키워드
    const addCustomKeyword = () => {
      if (newKeyword.value.trim() && !companyInfo.value.keyword.includes(newKeyword.value.trim())) {
        companyInfo.value.keyword.push(newKeyword.value.trim());
        newKeyword.value = ''; // 입력 필드 초기화
      }
    };

    // 키워드 삭제 함수
    const removeKeyword = (item) => {
      const index = companyInfo.value.keyword.indexOf(item);
      if (index !== -1) {
        companyInfo.value.keyword.splice(index, 1);
      }
    };


    const updateUserInfo = async () => {
      const data = {
        account_id: userId.value,
        pta_id: userInfo.value.pta_id,
        full_name: userInfo.value.full_name,
        contact_email: userInfo.value.contact_email,
        mobile: userInfo.value.mobile,
        company: userInfo.value.company,
        lang1: userInfo.value.lang1,
        lang2: userInfo.value.lang2,
        eqqui_profile: userInfo.value.eqqui_profile,
        bank_name: userInfo.value.bank_name,
        bank_ac_holder: userInfo.value.bank_ac_holder,
        bank_ac_number: userInfo.value.bank_ac_number,
      }
      const url = '/user/update_account_detail_info_for_bis';
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.data.result === true) {
          toast.success('내 정보가 성공적으로 변경되었습니다.');
          editMode.value = false
          await fetchUserInfo();
        } else {
          toast.alert(error);
          alert('에러');
        }
      } catch (error) {
        console.error('상태 업데이트 오류:', error);
      }
    }
    const getCategoryLabel = (value) => {
      const selectedField = field.find(item => item.code === value);
      return selectedField ? selectedField.label : value;
    };

    // 사전주선인 경우
    const isPreMatching = computed(() => route.query.isPreMatching === 'true');

    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;

      if (!isAuthenticated.value) {
        router.push({ name: 'home' }); // 홈으로 이동
      } else {
        token.value = store.state.userStore.token;
        userEmail.value = store.state.userStore.email;
        await fetchUserInfo()
      }
    });
    return {
      router,
      userInfo,
      formattedUserType,
      userType,
      userName,
      userEmail,
      getLanguageLabel,
      editMode,
      languageSelect,
      fieldSelect,
      updateUserInfo,
      getCategoryLabel,
      isPreMatching,
      uploadedFileName,
      handleFileUpload,
      deleteEventFile,
      presignedUrl,
      newKeyword,
      addCustomKeyword,
      removeKeyword
    };
  },
};
</script>

<template>
  <div class="no_header">
    <div class="my_account">
      <div class="page_title ds_flex jus_between al_center">
        <div class="ds_flex gap8 al_center">
          <p class="icon_box" @click="router.go(-1)">
            <i class="icon-down_arrow rotate90 size_28"></i>
          </p>
          <p class="size_22 b_700">내 정보</p>
        </div>
        <p class="icon_cont_btn secondary_text size_14" @click="editMode = true"><i class="icon-edit sub_text"></i>수정
        </p>
      </div>
      <div class="field_wrap">
        <div class="top">
          <div class="ds_flex dir_column gap4">
            <div class="ds_flex jus_between al_center">
              <p class="size_14" :class="`${userType}_text`">{{ formattedUserType }}</p>
              <a class="user_type_bdg size_12 back_light_gray sub_text" v-if="userInfo.company_homepage"
                :href="userInfo.company_homepage" target="_blank">홈페이지</a>
            </div>
            <p class="size_24 b_700">{{ userType === 'SELLER' ? userInfo.company : userName }}</p>
            <p class="sub_text">{{ userEmail }}</p>
          </div>
          <p class="user_type_bdg size_12 product" v-if="userType !== 'INTERPRETER'">#{{ userInfo.product ?
            userInfo.product : "-" }}/{{ userInfo.product_detail ? userInfo.product_detail : '-' }}</p>
        </div>
        <div class="content" v-if="userType === 'INTERPRETER'">
          <section class="cont1 ds_flex dir_column gap20" :class="{ 'edit_mode': editMode }">
            <p class="size_18 b_700">기본정보</p>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">이름</p>
              <template v-if="editMode">
                <p class="fake_textbox"><input type="text" v-model="userInfo.full_name" /></p>
              </template>
              <template v-else>
                <p class="b_600">{{ userInfo.full_name }}</p>
              </template>
            </div>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">이메일</p>
              <template v-if="editMode">
                <p class="fake_textbox"><input type="text" v-model="userInfo.contact_email" /></p>
              </template>
              <template v-else>
                <p class="b_600">{{ userInfo.contact_email }}</p>
              </template>
            </div>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">연락처</p>
              <template v-if="editMode">
                <p class="fake_textbox"><input type="text" v-model="userInfo.mobile" /></p>
              </template>
              <template v-else>
                <p class="b_600">{{ userInfo.mobile }}</p>
              </template>
            </div>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">통역언어</p>
              <template v-if="editMode">
                <div class="ds_flex dir_column gap12">
                  <v-select :options="languageSelect" label="label" :reduce="item => item.value" :clearable="false"
                    v-model="userInfo.lang1" />
                  <v-select :options="languageSelect" label="label" :reduce="item => item.value" :clearable="false"
                    v-model="userInfo.lang2" />
                </div>
              </template>
              <template v-else>
                <p class="b_600">{{ getLanguageLabel(userInfo.lang1) }}<template v-if="userInfo.lang2">, {{
                  getLanguageLabel(userInfo.lang2) }}</template>
                </p>
              </template>
            </div>
          </section>
          <hr />
          <section class="cont2 ds_flex dir_column gap20" :class="{ 'edit_mode': editMode }">
            <p class="size_18 b_700">세부정보</p>
            <div class="text_box_wrap ds_flex dir_column gap12">
              <div class="text_box" :class="{ 'back_light_gray3': !editMode }">
                <p class="size_14 secondary_text">에퀴코드</p>
                <template v-if="editMode">
                  <p class="fake_textbox"><span class="sub_text url">www.eqqui.com/profile/</span><input type="text"
                      v-model="userInfo.eqqui_profile" /></p>
                </template>
                <template v-else>
                  <p class="b_600 main_text">https://www.eqqui.com/profile/{{ userInfo.eqqui_profile }}</p>
                </template>
              </div>
              <div class="text_box bank" :class="{ 'back_light_gray3': !editMode }">
                <p class="size_14 secondary_text">입금정보</p>
                <template v-if="editMode">
                  <p class="fake_textbox"><span class="sub_text size_14">예금주</span><input type="text"
                      v-model="userInfo.bank_ac_holder" /></p>
                  <p class="fake_textbox"><span class="sub_text size_14">은행명</span><input type="text"
                      v-model="userInfo.bank_name" /></p>
                  <p class="fake_textbox"><span class="sub_text size_14">계좌번호</span><input type="text"
                      v-model="userInfo.bank_ac_number" /></p>
                </template>
                <template v-else>
                  <p class="b_600">{{ userInfo.bank_ac_holder }}</p>
                  <div class="ds_flex gap8 al_center">
                    <p class="size_14 secondary_text">{{ userInfo.bank_name }}</p>
                    <p class="size_14 secondary_text">|</p>
                    <p class="size_14 secondary_text">{{ userInfo.bank_ac_number }}</p>
                  </div>
                </template>
              </div>
            </div>
          </section>
        </div>
        <div class="content" v-else-if="userType === 'SELLER'">
          <section class="cont1 ds_flex dir_column gap20" :class="{ 'edit_mode': editMode }">
            <p class="size_18 b_700">기업 소개</p>
            <div class="text_wrap">
              <div class="file_wrap">
                <p class="size_14 b_600 secondary_text">기업소개서</p>
                <template v-if="editMode">
                  <template v-if="!presignedUrl.presigned_url">
                    <label v-if="!uploadedFileName" for="file-upload">
                      <div class="t_center file_label ds_flex dir_column gap8">
                        <i class="icon-upload size_20 main_text"></i>
                        <p class="size_14 main_text b_700">기업 소개서 첨부하기</p>
                        <p class="size_12 sub_text">docx, pdf (50mb이하) 파일 첨부 가능</p>
                      </div>
                    </label>
                    <input id="file-upload" type="file" @change="handleFileUpload" />
                  </template>
                  <template v-else>
                    <div class="file_label">
                      <a :href="presignedUrl.presigned_url" class="inline_cont_btn size_14 main_text">{{
                        presignedUrl.file_name }}</a>
                      <p @click="deleteEventFile" class="inline_cont_btn cancel_btn"><i class="icon-delete"></i></p>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <a :href="presignedUrl.presigned_url" class="size_14 main_text b_700">{{
                    presignedUrl.file_name }}</a>
                </template>
              </div>
            </div>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">기업소개서</p>
              <template v-if="editMode">
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">기업소개</p>
                  <textarea class="fake_textbox" v-model="userInfo.company_explain"></textarea>
                </div>
              </template>
              <template v-else>
                <div class="text_box back_light_gray3">
                  <p class="size_14 secondary_text">{{ userInfo.company_explain }}</p>
                </div>
              </template>
            </div>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">기업 키워드</p>
              <template v-if="editMode">
                <div class="text_wrap">
                  <div class="field_wrap">
                    <div class="ds_flex dir_column gap8">
                      <p class="size_14 secondary_text">기업 키워드 <span class="require">*</span>(최소 3개)</p>
                      <p class="size_12 b_500 secondary_text">기업을 대표하는 키워드를 입력해주세요. 주요 제품, 서비스, 기술 등을 간단히 표현하면 바이어와의 매칭
                        정확도가 높아져요.
                      </p>
                    </div>
                    <div class="input_wrap">
                      <div class="fake_textbox w100">
                        <input type="text" class="flex4" v-model="newKeyword" @keyup.enter="addCustomKeyword"
                          placeholder="예: 친환경 포장재, 스마트 제조 기술" />
                        <p class="size_14 main_text b_600 flex1 t_right" @click="addCustomKeyword">추가</p>
                      </div>
                      <div class="slot_wrap ds_flex gap8">
                        <p v-for="(item, index) in userInfo.keyword" :key="index" class="slot">
                          {{ item }} <i class="icon-delete sub_text" @click="removeKeyword(item)"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="slot_wrap ds_flex gap8">
                  <p v-for="(item, index) in userInfo.keyword" :key="index" class="slot">{{ item }} </p>
                </div>
              </template>
            </div>
          </section>
          <hr />
          <section class="cont2 ds_flex dir_column gap20" :class="{ 'edit_mode': editMode }">
            <p class="size_18 b_700">기업세부정보</p>
            <div class="text_box_wrap ds_flex dir_column gap20">
              <template v-if="editMode">
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">기업소개</p>
                  <textarea class="fake_textbox" v-model="userInfo.company_explain"></textarea>
                </div>
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">홈페이지</p>
                  <p class="fake_textbox"><input type="text" v-model="userInfo.company_homepage" /></p>
                </div>
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">기업소개서</p>
                  <p class="block_main_btn file">파일 첨부하기</p>
                </div>
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">분야</p>
                  <v-select :options="fieldSelect" label="label" :reduce="item => item.value" :clearable="false"
                    v-model="userInfo.category" />
                </div>
              </template>
              <template v-else>
                <div class="text_box back_light_gray3">
                  <p class="b_600">{{ userInfo.company_explain }}</p>
                  <div class="ds_flex jus_between">
                    <p class="size_14 secondary_text">기업소개서</p>
                    <a class="size_14 main_text" v-if="userInfo.company_file && userInfo.company_file.file_url"
                      :href="userInfo.company_file.file_url" target="_blank">
                      {{ userInfo.company_file.file_name ? userInfo.company_file.file_name : '-' }}
                    </a>
                    <span v-else>-</span>
                  </div>
                  <div class="ds_flex jus_between">
                    <p class="size_14 secondary_text">관심분야</p>
                    <p class="size_14 b_600">{{ getCategoryLabel(userInfo.category) }}</p>
                  </div>
                </div>
              </template>
            </div>
          </section>
          <hr />
          <section class="cont2 ds_flex dir_column gap20" :class="{ 'edit_mode': editMode }">
            <p class="size_18 b_700">담당자 정보</p>
            <div class="text_wrap">
              <template v-if="editMode">
                <div class="ds_flex dir_column gap12">
                  <p class="fake_textbox"><input type="text" v-model="userInfo.full_name" /></p>
                  <p class="fake_textbox"><input type="text" v-model="userInfo.contact_email" /></p>
                  <p class="fake_textbox"><input type="text" v-model="userInfo.mobile" /></p>
                </div>
              </template>
              <template v-else>
                <div class="text_box" :class="{ 'back_light_gray3': !editMode }">
                  <p class="b_700">{{ userInfo.full_name }}</p>
                  <p class="secondary_text">{{ userInfo.full_name_en }}</p>
                  <p class="size_14 secondary_text">{{ userInfo.contact_email }} | {{ userInfo.mobile }}</p>
                </div>
              </template>
            </div>
          </section>
        </div>
        <div class="content" v-else>
          <section class="cont1 ds_flex dir_column gap20" :class="{ 'edit_mode': editMode }">
            <p class="size_18 b_700">기업 정보</p>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">기업명</p>
              <template v-if="editMode">
                <p class="fake_textbox"><input type="text" v-model="userInfo.company" /></p>
              </template>
              <template v-else>
                <p class="b_600">{{ userInfo.company }}</p>
              </template>
            </div>
            <div class="text_wrap">
              <p class="size_14 b_600 secondary_text">담당자</p>
              <template v-if="editMode">
                <div class="ds_flex dir_column gap12">
                  <p class="fake_textbox"><input type="text" v-model="userInfo.full_name" /></p>
                  <p class="fake_textbox"><input type="text" v-model="userInfo.contact_email" /></p>
                  <p class="fake_textbox"><input type="text" v-model="userInfo.mobile" /></p>
                </div>
              </template>
              <template v-else>
                <div class="text_box" :class="{ 'back_light_gray3': !editMode }">
                  <p class="size_14 secondary_text">{{ userInfo.full_name }}</p>
                  <p class="size_14 secondary_text">{{ userInfo.contact_email }} | {{ userInfo.mobile }}</p>
                </div>
              </template>
            </div>
          </section>
          <hr />
          <section class="cont2 ds_flex dir_column gap20" :class="{ 'edit_mode': editMode }">
            <p class="size_18 b_700">기업세부정보</p>
            <div class="text_box_wrap ds_flex dir_column gap20">
              <template v-if="editMode">
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">기업소개</p>
                  <textarea class="fake_textbox" v-model="userInfo.company_explain"></textarea>
                </div>
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">홈페이지</p>
                  <p class="fake_textbox"><input type="text" v-model="userInfo.company_homepage" /></p>
                </div>
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">기업소개서</p>
                  <p class="block_main_btn file">파일 첨부하기</p>
                </div>
                <div class="text_wrap">
                  <p class="size_14 b_600 secondary_text">분야</p>
                  <v-select :options="fieldSelect" label="label" :reduce="item => item.value" :clearable="false"
                    v-model="userInfo.category" />
                </div>
              </template>
              <template v-else>
                <div class="text_box back_light_gray3">
                  <p class="b_600">{{ userInfo.company_explain }}</p>
                  <div class="ds_flex jus_between">
                    <p class="size_14 secondary_text">기업소개서</p>
                    <a class="size_14 main_text" v-if="userInfo.company_file && userInfo.company_file.file_url"
                      :href="userInfo.company_file.file_url" target="_blank">
                      {{ userInfo.company_file.file_name ? userInfo.company_file.file_name : '-' }}
                    </a>
                    <span v-else>-</span>
                  </div>
                  <div class="ds_flex jus_between">
                    <p class="size_14 secondary_text">관심분야</p>
                    <p class="size_14 b_600">{{ getCategoryLabel(userInfo.category) }}</p>
                  </div>
                </div>
              </template>
            </div>
          </section>
        </div>
      </div>
      <p v-if="editMode" class="block_main_btn save" @click="updateUserInfo">저장하기</p>
    </div>
  </div>
</template>
