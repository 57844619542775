<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  props: {
    requestUserModal: Boolean,
    requestUserInfo: Object,
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');

    const eventId = computed(() => store.state.eventStore.eventId);
    const buyerRequestList = ref([])
    async function fetchBuyerSchedule() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/prematching/read_buyer_pre_schedule', {
          params: {
            piece_event_id: eventId.value,
            date: props.requestUserInfo.selectDate,
            buyer_id: props.requestUserInfo.requestUser?.buyer_id
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          buyerRequestList.value = response.data.p_schedule_list; // 스케줄 데이터를 반환

        } else {
          console.log("바이어 스케줄 로드 실패")
        }
      } catch (error) {
      }
    }

    watch(
      () => props.requestUserInfo.requestUser?.buyer_id, // buyer_id 값만 감시
      async (newBuyerId, oldBuyerId) => {
        if (newBuyerId && newBuyerId !== oldBuyerId) {
          await fetchBuyerSchedule();
        }
      },
      { immediate: true }
    );


    const closeModals = () => {
      emit('close');
    }


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }

      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });

    return {
      closeModals,

    }
  },
});
</script>

<!-- 여기 해야함~!!!!!!!! -->
<template>
  <div id="preMatchScheduleRequestModal">
    <div class="overlay" v-if="requestUserModal"></div>
    <div class="modal width_1122" v-if="requestUserModal">
      <div class="modal_overflow">
        <p class="icon_box close" @click="closeModals()">
          <i class="icon-delete sub_text"></i>
        </p>
        <div class="request_wrap">
          <p class="size_28 b_700">매칭 요청함</p>
          <div class="ds_flex al_center gap12 buyer_info">
            <p class="user_type_bdg BUYER size_12">바이어</p>
            <p class="b_600">{{ requestUserInfo.buyer_company }}</p>
          </div>
          <div class="ds_flex gap24">
            <p class="tab">상담 신청한 셀러</p>
            <p class="tab">관심있는 셀러</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
