<script>
import { ref, defineComponent, onMounted, inject, watch, computed } from 'vue';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';
import { useToast } from 'vue-toastification';
import field from '@/assets/js/bisatField'

export default defineComponent({
  props: {
    requestUserModal: Boolean,
    requestUserInfoId: Number,
    preMatchInfo: Object,
  },
  setup(props, { emit }) {
    // Axios 인스턴스와 Vuex 스토어 사용
    const axios = inject('axios');
    const store = useStore();

    // 페이지 상태 및 토큰 관리
    const isAuthenticated = ref(false);
    const token = ref('');
    const toast = useToast(); // Toast 호출 함수

    const eventId = computed(() => store.state.eventStore.eventId);
    const eventDateList = computed(() => props.preMatchInfo.p_ptd_list);

    const mode = ref('read')

    const presignedUrl = ref([])
    async function getPresignedUrl(filePath) {
      try {
        // axios를 사용하여 이벤트 상세 정보를 가져옵니다.
        const response = await axios.get('/file/presigned_url', {
          params: {
            file_path: filePath
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });
        if (response.data.result === true) {
          presignedUrl.value = response.data.file_info
        }

      } catch (error) {
        console.error('파일 경로를 가져오지 못했습니다.:', error);
      }
    }
    watch(
      () => props.requestUserInfoId,
      async (newValue) => {
        if (newValue) {  // newValue가 존재하고 userId가 있을 때만 실행
          await fetchUserData();
        }
      },
      { immediate: true }
    );


    const requestUserInfo = ref({})
    const originalData = ref({}); // 초기 데이터를 저장할 변수
    async function fetchUserData() {
      try {
        // axios를 사용하여 이벤트 리스트를 가져옵니다.
        const response = await axios.get('/prematching/read_pre_account', {
          params: {
            p_account_id: props.requestUserInfoId
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          if (response.data.p_account.company_file) {
            getPresignedUrl(response.data.p_account.company_file)
          }
          requestUserInfo.value = response.data.p_account
          originalData.value = JSON.parse(JSON.stringify(response.data.p_account));

          selectedPosition.value = response.data.p_account.position_en
          readPiece()
        } else {
          console.log("이벤트 로드 실패")
        }
      } catch (error) {
      }
    }
    const productList = ref([])
    const readPiece = async () => {
      try {
        // axios를 사용하여 이벤트 상세 정보를 가져옵니다.
        const response = await axios.get('/event/read_piece', {
          params: {
            piece_event_id: eventId.value
          },
          headers: {
            Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
          },
        });

        if (response.data.result === true) {
          productList.value = response.data.piece_event.product_list;
        }
      } catch (error) {
        console.error('이벤트 데이터를 가져오는 중에 오류가 발생했습니다:', error);
        alert('이벤트를 불러오지 못했습니다.');
      }
    }

    const handleApproval = async (action) => {
      if (confirm("상태를 변경하시겠습니까?")) {
        const newStatus = action === 'approve' ? 1 : 2; // 승인: 1, 반려: 2
        try {
          const response = await axios.put('/prematching/update_pre_account_approval_status',
            {
              piece_event_id: eventId.value,
              p_account_id_list: [requestUserInfo.value.p_account_id],
              approval_status: newStatus,
            },
            {
              headers: {
                Authorization: `Bearer ${token.value}`, // 헤더에 토큰 추가
              },
            }
          );
          if (response.data.result === true) {
            toast.success('상태가 성공적으로 변경되었습니다.');
            requestUserInfo.value.approval_status = newStatus; // 로컬 상태 업데이트
            emit('data-saved', requestUserInfo.value); // 부모에게 이벤트 전송
          } else {
            console.error('상태 업데이트 실패', response);
          }
        } catch (error) {
          console.error('API 요청 중 오류 발생', error);
        }
      }
    };

    const fieldSelect = field.map(item => ({
      label: item.label,
      value: item.code
    }))

    const getCategoryLabel = (value) => {
      const selectedField = field.find(item => item.code === value);
      return selectedField ? selectedField.label : value;
    };
    const positionSelect = [
      { label: '사원', value: 'staff' },
      { label: '대리', value: 'assistant_manager' },
      { label: '과장', value: 'manager' },
      { label: '차장', value: 'deputy_general_manager' },
      { label: '부장', value: 'general_manager' },
      { label: '이사', value: 'director' },
      { label: '상무', value: 'managing_director' },
      { label: '전무', value: 'executive_director' },
      { label: '부사장', value: 'vice_president' },
      { label: '사장 / 대표', value: 'president_ceo' },
      { label: '기타(직접 입력)', value: 'other' },
    ];
    const selectedPosition = ref('')
    // 선택한 값이 변경될 때 처리 로직
    const updatePositionInfo = computed(() => {
      // 선택한 포지션 데이터 찾기
      const selected = positionSelect.find((item) => item.value === selectedPosition.value);

      if (selected) {
        if (selected.value === 'other') {
          // '기타' 선택 시 초기화
          requestUserInfo.value.position = '';
          requestUserInfo.value.position_en = '';
        } else {
          // 국문과 영문 데이터를 각각 업데이트
          requestUserInfo.value.position = selected.label; // 국문
          requestUserInfo.value.position_en = selected.value; // 영문
        }
      } else {
        // 선택된 값이 없을 경우 초기화
        requestUserInfo.value.position = '';
        requestUserInfo.value.position_en = '';
      }
    });

    const toggleSelection = (item) => {
      const index = requestUserInfo.value.p_atd_list.findIndex(i => i.p_ptd_id === item.p_ptd_id);

      if (index === -1) {
        // 체크할 때 추가
        requestUserInfo.value.p_atd_list.push({ p_ptd_id: item.p_ptd_id, date: item.date });
      } else {
        // 체크 해제할 때 제거
        requestUserInfo.value.p_atd_list.splice(index, 1);
      }
    };

    const isChecked = (p_ptd_id) => {
      return requestUserInfo.value.p_atd_list.some(i => i.p_ptd_id === p_ptd_id);
    };

    const getModifiedFields = () => {
      const modifiedFields = {};

      for (const key in requestUserInfo.value) {
        console.log(originalData.value.p_atd_list)
        if (key === 'p_atd_list') {
          // 배열 비교 (JSON 문자열 변환 후 비교)
          if (JSON.stringify(requestUserInfo.value.p_atd_list) !== JSON.stringify(originalData.value.p_atd_list)) {
            modifiedFields[key] = requestUserInfo.value.p_atd_list;
          }
        } else {
          // 일반 값 비교
          if (requestUserInfo.value[key] !== originalData.value[key]) {
            modifiedFields[key] = requestUserInfo.value[key];
          }
        }
      }

      return modifiedFields;
    };


    const handleSave = async () => {
      const modifiedData = getModifiedFields(); // 변경된 데이터만 추출
      console.log(modifiedData)
      if (Object.keys(modifiedData).length === 0) {
        toast.info('변경된 데이터가 없습니다.');
        mode.value = 'read';
        return;
      }
      // p_atd_list가 변경된 경우 pta_id 값만 리스트로 변환
      if (modifiedData.p_atd_list) {
        modifiedData.p_ptd_id_list = modifiedData.p_atd_list.map(item => item.p_ptd_id);
        delete modifiedData.p_atd_list; // 원래의 p_atd_list는 삭제
      }
      try {
        const response = await axios.put(
          '/prematching/update_pre_account',
          {
            piece_event_id: Number(eventId.value),
            p_account_id: requestUserInfo.value.p_account_id,
            ...modifiedData, // 변경된 데이터만 전송
          },
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (response.data.result === true) {
          toast.success('데이터가 성공적으로 저장되었습니다.');
          originalData.value = { ...requestUserInfo.value }; // 저장 후 데이터 동기화
          mode.value = 'read';
          // emit('data-saved', originalData.value); // 부모에게 이벤트 전송
          router.go(0)
        } else {
          console.error('데이터 저장 실패', response);
        }
      } catch (error) {
        console.error('API 요청 중 오류 발생:', error);
      }
    };


    const closeModals = () => {
      emit('close');
    }


    // 컴포넌트 마운트 시 로직
    onMounted(async () => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }

      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });

    return {
      closeModals,
      mode,
      requestUserInfo,
      handleApproval,
      fetchUserData,
      handleSave,
      productList,
      fieldSelect,
      getCategoryLabel,
      positionSelect,
      selectedPosition,
      updatePositionInfo,
      eventDateList,
      toggleSelection,
      isChecked,
      originalData,
      presignedUrl
    }
  },
});
</script>

<template>
  <div id="preMatchScheduleRequestModal">
    <div class="overlay" v-if="requestUserModal"></div>
    <div class="modal narrow_width" v-if="requestUserModal">
      <div class="modal_overflow">
        <p class="icon_box close" @click="closeModals()">
          <i class="icon-delete sub_text"></i>
        </p>
        <div class="ds_flex gap20 al_center">
          <p class="size_28 b_700">셀러 신청서</p>
          <p class="inline_cont_btn cancel_btn" @click="mode = 'update'" v-if="mode === 'read'"><i
              class="icon-edit sub_text size_14"></i></p>
        </div>
        <div class="cont_wrap ds_flex dir_column gap24">
          <div class="ds_flex al_start">
            <label class="secondary_text b_700">참가신청일<b class="main_text">*</b></label>
            <template v-if="mode === 'read'">
              <div class="ds_flex al_center gap8 flex3">
                <p class="date_slot" v-for="item, index in requestUserInfo.p_atd_list" :key="index">{{ item.date }}</p>
              </div>
            </template>
            <template v-else-if="mode === 'update'">
              <div class="select_wrap flex3">
                <label class="ds_flex gap8 al_center" v-for="item in eventDateList" :key="item.p_ptd_id">
                  <label :for="`selectDate_${item.p_ptd_id}`" class="fake_checkbox"
                    :class="{ 'active': isChecked(item.p_ptd_id) }">
                    <i v-if="isChecked(item.p_ptd_id)" class="icon-checked"></i>
                  </label>
                  <input type="checkbox" :id="`selectDate_${item.p_ptd_id}`" :value="item.p_ptd_id"
                    @change="toggleSelection(item)" />
                  <label class="function_name b_700" :for="`selectDate_${item.p_ptd_id}`">{{ item.date }}</label>
                </label>
              </div>
            </template>
          </div>
          <hr />
          <div class="ds_flex al_start">
            <label class="secondary_text b_700">기업명<b class="main_text">*</b></label>
            <template v-if="mode === 'read'">
              <div class="flex3 ds_flex dir_column gap24">
                <div class="ds_flex gap10 al_center">
                  <p class="size_14 sub_text">국문</p>
                  <p class="size_14 b_700">{{ requestUserInfo.company }}</p>
                </div>
                <div class="ds_flex gap10 al_center">
                  <p class="size_14 sub_text">영문</p>
                  <p class="size_14 b_700">{{ requestUserInfo.company_en }}</p>
                </div>
              </div>
            </template>
            <template v-else-if="mode === 'update'">
              <div class="flex3 ds_flex dir_column gap24">
                <div class="ds_flex gap10 al_center fake_textbox jus_start">
                  <p class="size_14 sub_text">국문</p>
                  <input class="size_14" v-model="requestUserInfo.company" />
                </div>
                <div class="ds_flex gap10 al_center fake_textbox jus_start">
                  <p class="size_14 sub_text">영문</p>
                  <input class="size_14" v-model="requestUserInfo.company_en" />
                </div>
              </div>
            </template>
          </div>
          <div class="ds_flex al_center">
            <label class="secondary_text b_700 ">대표자명<b class="main_text">*</b></label>
            <template v-if="mode === 'read'">
              <p class="size_14 b_700 flex3">{{ requestUserInfo.ceo_name }}</p>
            </template>
            <template v-else-if="mode === 'update'">
              <input class="fake_textbox size_14 flex3" v-model="requestUserInfo.ceo_name" />
            </template>
          </div>
          <div class="ds_flex al_start">
            <label class="secondary_text b_700 ">주소<b class="main_text">*</b></label>
            <template v-if="mode === 'read'">
              <p class="size_14 b_700 flex3">{{ requestUserInfo.company_address }} {{
                requestUserInfo.company_address_detail }}
              </p>
            </template>
            <template v-else-if="mode === 'update'">
              <div class="ds_flex dir_column gap24 flex3">
                <input class="fake_textbox size_14 " v-model="requestUserInfo.company_address" />
                <input class="fake_textbox size_14 " v-model="requestUserInfo.company_address_detail"
                  placeholder="상세주소" />
              </div>
            </template>
          </div>
          <div class="ds_flex al_center">
            <label class="secondary_text b_700 ">사업자 번호<b class="main_text">*</b></label>
            <template v-if="mode === 'read'">
              <p class="size_14 b_700 flex3">{{ requestUserInfo.business_number }}</p>
            </template>
            <template v-else-if="mode === 'update'">
              <input class="fake_textbox size_14 flex3" v-model="requestUserInfo.business_number" />
            </template>
          </div>
          <div class="ds_flex al_center">
            <label class="secondary_text b_700 ">법인등록 번호<b class="main_text">*</b></label>
            <template v-if="mode === 'read'">
              <p class="size_14 b_700 flex3">{{ requestUserInfo.business_number }}</p>
            </template>
            <template v-else-if="mode === 'update'">
              <input class="fake_textbox size_14 flex3" v-model="requestUserInfo.business_number" />
            </template>
          </div>
          <div class="ds_flex al_center">
            <label class="secondary_text b_700 ">업종<b class="main_text">*</b></label>
            <template v-if="mode === 'read'">
              <p class="size_14 b_700 flex3">{{ requestUserInfo.company_industry }}</p>
            </template>
            <template v-else-if="mode === 'update'">
              <input class="fake_textbox size_14 flex3" v-model="requestUserInfo.company_industry" />
            </template>
          </div>
          <div class="ds_flex al_center">
            <label class="secondary_text b_700 ">분야<b class="main_text">*</b></label>
            <template v-if="mode === 'read'">
              <p class="size_14 b_700 flex3">{{ getCategoryLabel(requestUserInfo.category) }}</p>
            </template>
            <template v-else-if="mode === 'update'">
              <v-select :options="fieldSelect" label="label" :reduce="item => item.value" :clearable="false"
                class="flex3 size_14" v-model="requestUserInfo.category" placeholder="분야" />
            </template>
          </div>
          <hr />
          <div class="ds_flex al_center">
            <label class="secondary_text b_700">상담 품목군<b class="main_text">*</b></label>
            <template v-if="mode === 'read'">
              <p class="size_14 b_700 flex3">{{ requestUserInfo.product }}</p>
            </template>
            <template v-else-if="mode === 'update'">
              <select v-model="requestUserInfo.product" class="flex3">
                <option v-for="item in productList" :key="item.ptp_id">{{ item.product }}</option>
              </select>
            </template>
          </div>
          <div class="ds_flex al_start">
            <label class="secondary_text b_700 ">세부 상담 품목<b class="main_text">*</b></label>
            <template v-if="mode === 'read'">
              <div class="flex3 ds_flex dir_column gap24">
                <div class="ds_flex gap10 al_center">
                  <p class="size_14 sub_text">국문</p>
                  <p class="size_14 b_700">{{ requestUserInfo.product_detail }}</p>
                </div>
                <div class="ds_flex gap10 al_center">
                  <p class="size_14 sub_text">영문</p>
                  <p class="size_14 b_700">{{ requestUserInfo.product_detail_en }}</p>
                </div>
              </div>
            </template>
            <template v-else-if="mode === 'update'">
              <div class="flex3 ds_flex dir_column gap24">
                <div class="ds_flex gap10 al_center fake_textbox jus_start">
                  <p class="size_14 sub_text">국문</p>
                  <input class="size_14" v-model="requestUserInfo.product_detail" />
                </div>
                <div class="ds_flex gap10 al_center fake_textbox jus_start">
                  <p class="size_14 sub_text">영문</p>
                  <input class="size_14" v-model="requestUserInfo.product_detail_en" />
                </div>
              </div>
            </template>
          </div>
          <hr />
          <template v-if="mode === 'read'">
            <div class="ds_flex">
              <label class="secondary_text b_700">담당자 정보<b class="main_text">*</b></label>
              <div class="manager_info">
                <p class="b_600">{{ requestUserInfo.full_name }}</p>
                <p class="secondary_text">{{ requestUserInfo.full_name_en }}</p>
                <div class="ds_flex gap24">
                  <p class="size_14 secondary_text">직급</p>
                  <p class="size_14 secondary_text">{{ requestUserInfo.position }}({{ requestUserInfo.position_en }})
                  </p>
                </div>
                <div class="ds_flex gap24">
                  <p class="size_14 secondary_text">이메일</p>
                  <p class="size_14 secondary_text">{{ requestUserInfo.email }}</p>
                </div>
                <div class="ds_flex gap24">
                  <p class="size_14 secondary_text">연락처</p>
                  <p class="size_14 secondary_text">{{ requestUserInfo.mobile }}</p>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="mode === 'update'">
            <p class="size_18 b_700">담당자 정보<b class="main_text">*</b></p>
            <div class="ds_flex al_start">
              <label class="secondary_text b_700">이름</label>
              <div class="flex3 ds_flex dir_column gap12">
                <input class="fake_textbox size_14" v-model="requestUserInfo.full_name" />
                <input class="fake_textbox size_14" v-model="requestUserInfo.full_name_en" />
              </div>
            </div>
            <div class="ds_flex al_start">
              <label class="secondary_text b_700">직급</label>
              <div class="flex3 ds_flex dir_column gap12">
                <v-select :options="positionSelect" label="label" :reduce="item => item.value" :clearable="false"
                  @change="updatePositionInfo" class="w100" v-model="selectedPosition" placeholder="직책" />
                <template v-if="selectedPosition === 'other'">
                  <div class="fake_textbox w100">
                    <p class="secondary_text flex1">국문</p>
                    <input class="flex5" type="text" v-model="requestUserInfo.position" placeholder="국문직책" />
                    <p class="main_text size_14 b_700" @click="translateText('position', requestUserInfo.position)">
                      <span v-if="loading === 'position'">° ° °</span>
                      <span v-else>번역하기</span>
                    </p>
                  </div>
                  <div class="fake_textbox w100">
                    <p class="secondary_text flex1">영문</p>
                    <input class="flex6" type="text" v-model="requestUserInfo.position_en" placeholder="영문직책" />
                  </div>
                </template>
              </div>
            </div>
            <div class="ds_flex al_center">
              <label class="secondary_text b_700">이메일</label>
              <input class="fake_textbox size_14 flex3" v-model="requestUserInfo.email" />
            </div>
            <div class="ds_flex al_center">
              <label class="secondary_text b_700">연락처</label>
              <input class="fake_textbox size_14 flex3" v-model="requestUserInfo.mobile" />
            </div>
          </template>
        </div>
        <div class="btn_wrap ds_flex gap18 jus_between">
          <template v-if="mode === 'read'">
            <p class="block_sub_btn status_btn b_700"
              :class="{ 'return_active': requestUserInfo.approval_status === 2 }" @click="handleApproval('reject')"><img
                src="@/assets/img/survey/record_3.png" />반려</p>
            <p class="block_sub_btn status_btn b_700"
              :class="{ 'approved_active': requestUserInfo.approval_status === 1 }" @click="handleApproval('approve')">
              <img src="@/assets/img/survey/record_4.png" />승인
            </p>
          </template>
          <template v-if="mode === 'update'">
            <p class="block_sub_btn cancel_btn" @click="fetchUserData(); mode = 'read';">취소</p>
            <p class="block_sub_btn active_btn" @click="handleSave()">저장</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
