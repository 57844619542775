<script>
import { ref, defineComponent, onMounted, inject, watch, computed, nextTick } from 'vue';
import debounce from 'lodash/debounce';
import '@/assets/css/modal.css'
import { useStore } from 'vuex';
import router from '@/router';
import bisatStatus from '@/assets/js/bisatStatus.json';
import bisatLang from '@/assets/js/bisatLang.json';

export default defineComponent({
  props: {
    showMessageModal: Boolean,
    selectedUserData: Object,
  },
  setup(props, { emit }) {
    const axios = inject('axios');
    const store = useStore();

    const isAuthenticated = ref(false);
    const token = ref('');
    const eventId = computed(() => store.state.eventStore.eventId);
    const eventDate = computed(() => store.state.eventStore.eventDate);

    const userType = computed(() => props.selectedUserData.type);
    const attendListTotal = ref(0);
    const localAttendUserList = ref([]);
    const kakaoQueues = ref([]);
    const limit = 20;

    const userInfo = ref({});
    const closeModals = () => {
      emit('close');
      userInfo.value = {};
    };

    const getLanguage = (code) => {
      const languageLabel = bisatLang.find(item => item.code === code);
      return languageLabel ? languageLabel.label : '';
    };

    const searchKeyword = ref('');
    // const filteredAttendUserList = computed(() => {
    //   if (!searchKeyword.value) return localAttendUserList.value;
    //   const keyword = searchKeyword.value.toLowerCase();
    //   return localAttendUserList.value.filter(item => {
    //     const fullName = item.full_name?.toLowerCase() || '';
    //     const mobile = item.mobile || '';
    //     const lang1 = getLanguage(item.lang1).toLowerCase();
    //     const lang2 = getLanguage(item.lang2)?.toLowerCase() || '';
    //     return fullName.includes(keyword) || mobile.includes(keyword) || lang1.includes(keyword) || lang2.includes(keyword);
    //   });
    // });

    const selectDate = ref('');
    const selectAll = ref(false);
    const exceptNoShow = ref(false);
    const sendMessageDate = ref([{ date: selectDate, time: '00:00' }]);
    const masks_date = ref({ modelValue: 'YYYY-MM-DD' });
    const masks_time = ref({ modelValue: 'HH:mm' });
    const rules = ref({ minutes: { interval: 1 } });

    const isSelectAllChecked = computed(() => {
      return localAttendUserList.value.length > 0 && localAttendUserList.value.every(item => item.checked);
    });

    const isSelectAllPartial = computed(() => {
      return localAttendUserList.value.some(item => item.checked) && !isSelectAllChecked.value;
    });

    const toggleSelectAll = () => {
      const newValue = !isSelectAllChecked.value;
      localAttendUserList.value.forEach(item => item.checked = newValue);
      selectAll.value = isSelectAllChecked.value;
    };

    watch(isSelectAllChecked, (newVal) => {
      if (newVal) exceptNoShow.value = false;
    });

    watch(exceptNoShow, () => {
      if (exceptNoShow.value) {
        localAttendUserList.value.forEach(item => {
          if (item.code_name === 'NOSHOW') {
            item.checked = false;
          }
        });
      }
    });

    const getIconClass = (type, category, status) => {
      const categoryData = bisatStatus[type]?.[category] || [];
      const statusItem = categoryData.find(item => item.status === status);
      return statusItem ? statusItem.icon : '';
    };

    const add_time = () => {
      sendMessageDate.value.push({ date: selectDate.value, time: '00:00' });
    };

    const delete_time = (index) => {
      if (index >= 0 && index < sendMessageDate.value.length) {
        sendMessageDate.value.splice(index, 1);
      }
    };

    const createKakaoQueue = async () => {
      if (localAttendUserList.value.filter(item => item.checked).length === 0) {
        alert('최소 한명의 사용자가 선택되어야 합니다.');
      } else {
        try {
          const datetimeList = sendMessageDate.value.map(item => ({
            date: item.date,
            send_time: item.time
          }));
          const toAtIdList = localAttendUserList.value
            .filter(item => item.checked)
            .map(item => item.at_id);

          const response = await axios.post('/attendance/create_kakao_queue', {
            piece_event_id: eventId.value,
            to_type: userType.value,
            datetime_list: datetimeList,
            to_at_id_list: toAtIdList
          }, {
            headers: {
              Authorization: `Bearer ${token.value}`
            },
          });

          if (response.data.result === true) {
            alert("저장했습니다.");
            router.go(0);
          } else {
            alert("저장하지 못했습니다.");
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    const deleteKakaoQueue = async (queue_id) => {
      if (confirm("삭제하시겠습니까?")) {
        try {
          const response = await axios.delete('/attendance/delete_kakao_queue', {
            headers: {
              Authorization: `Bearer ${token.value}`
            },
            data: { kq_id: queue_id }
          });
          if (response.data.result === true) {
            alert("삭제되었습니다.");
            router.go(0);
          }
        } catch (error) {
          console.error('Delete failed:', error);
        }
      }
    };

    const pageUserList = ref(1);
    const isFetchingUserList = ref(false);
    const observerTargetUserList = ref(null);
    const hasMoreData = ref(true);

    async function fetchAttendUserList() {
      if (isFetchingUserList.value || !hasMoreData.value) return;

      isFetchingUserList.value = true;
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          date: eventDate.value,
          account_type: props.selectedUserData.type,
          page: pageUserList.value,
          limit: limit,
          search_by: 'full_name',
        });
        if (searchKeyword.value) {
          queryParams.append('search_text', searchKeyword.value);
        }

        const url = `/attendance/read_list_by_date?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${token.value}` },
        });

        if (response.data.result === true) {
          const fetchedList = response.data.ar_list;
          attendListTotal.value = response.data.all_total;
          localAttendUserList.value.push(...fetchedList);

          // 데이터가 더 없는 경우
          if (fetchedList.length < limit) {
            hasMoreData.value = false;
          } else {
            pageUserList.value++;
          }
        } else {
          alert('에러');
        }
      } catch (error) {
        console.error('스케줄 일시 불러올 때 오류:', error);
      } finally {
        isFetchingUserList.value = false;
      }
    }

    const selectedUserCount = computed(() => {
      return localAttendUserList.value.filter(item => item.checked).length || 0;
    });
    const setupObserver = async () => {
      await nextTick();
      if (observerTargetUserList.value) {
        const observerUserList = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            console.log("✅ 리스트 무한 스크롤 감지됨!");
            fetchAttendUserList();
          }
        });
        observerUserList.observe(observerTargetUserList.value);
      }
    };

    const onSearchInput = debounce(() => {
      pageUserList.value = 1;
      hasMoreData.value = true;
      localAttendUserList.value = [];
      fetchAttendUserList();
    }, 300);

    async function fetchKakaoQueues() {
      try {
        const queryParams = new URLSearchParams({
          piece_event_id: eventId.value,
          to_type: userType.value,
        });

        const url = `/attendance/read_kakao_queues?${queryParams.toString()}`;
        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${token.value}` },
        });

        if (response.data.result === true) {
          kakaoQueues.value = response.data.kakao_queues.map(item => {
            let scheduled_date = '', scheduled_time = '';
            if (item.scheduled_datetime) {
              [scheduled_date, scheduled_time] = item.scheduled_datetime.split(' ');
            }
            return { ...item, scheduled_date, scheduled_time };
          });
        } else {
          alert('예약내역 불러오기 오류');
        }
      } catch (error) {
        console.error('예약내역 불러오기 오류:', error);
      }
    }

    watch(() => props.showMessageModal, async (newVal) => {
      if (newVal) {
        pageUserList.value = 1;
        hasMoreData.value = true;
        selectAll.value = true;
        localAttendUserList.value = [];
        await fetchAttendUserList();
        setupObserver();
        await fetchKakaoQueues();
      }
    }, { immediate: true });

    // 예약 리스트 관련 상태
    const reserveUserList = ref([]);
    const reserveUserListCount = ref(0);
    const pageReserveUserList = ref(1);
    const isFetchingReserveUserList = ref(false);
    const hasMoreDataForReserveUserList = ref(true);
    const currentKqId = ref(null);

    // kq_id별로 observer 타겟 DOM을 저장할 객체
    const observerTargetReserveMap = ref({});

    // 옵저버 인스턴스 (단일)
    let observerReserveUserList = null;

    // ref 함수로 DOM을 수집하여 맵에 저장
    function setObserverRef(el) {
      if (el && el.dataset.kqid) {
        observerTargetReserveMap.value[el.dataset.kqid] = el;
      }
    }

    // 예약 수신인 목록 불러오기
    const fetchReserveUserList = async (kq_id) => {
      if (isFetchingReserveUserList.value || !hasMoreDataForReserveUserList.value) return;

      isFetchingReserveUserList.value = true;
      try {
        const response = await axios.get('/attendance/read_kakao_queue_detail', {
          params: { kq_id, page: pageReserveUserList.value, limit: limit },
          headers: {
            Authorization: `Bearer ${token.value}`
          }
        });

        if (response.data.result === true) {
          const fetchedList = response.data.kakao_queue_info.queue_detail;
          reserveUserListCount.value = response.data.kakao_queue_info.total;
          reserveUserList.value.push(...fetchedList);

          if (fetchedList.length < limit) {
            hasMoreDataForReserveUserList.value = false;
          } else {
            pageReserveUserList.value++;
          }
        } else {
          console.warn("응답 실패:", response.data);
        }
      } catch (error) {
        console.error("카카오 큐 디테일 조회 실패:", error);
      } finally {
        isFetchingReserveUserList.value = false;
      }
    };

    // 예약 수신인 리스트용 무한 스크롤 옵저버 설정
    const setupObserverForReserve = async () => {
      await nextTick(); // DOM 렌더링 완료 대기

      const el = observerTargetReserveMap.value[currentKqId.value];

      if (el && el instanceof Element) {
        if (observerReserveUserList) {
          observerReserveUserList.disconnect(); // 기존 옵저버 해제
        }

        observerReserveUserList = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            fetchReserveUserList(currentKqId.value);
          }
        });

        observerReserveUserList.observe(el); // 대상 DOM 감시 시작
      } else {
        console.warn("감시할 DOM 요소를 찾을 수 없습니다.");
      }
    };

    // 예약 대상 리스트 열기
    const openUserList = async (kq_id) => {
      reserveUserList.value = [];
      reserveUserListCount.value = 0;
      pageReserveUserList.value = 1;
      hasMoreDataForReserveUserList.value = true;

      currentKqId.value = kq_id; // 대상 kq_id 변경 (watch에서 observer 설정됨)

      await fetchReserveUserList(kq_id); // 첫 페이지 데이터 로딩
    };

    // currentKqId가 변경되면 observer 등록
    watch(currentKqId, async (newVal) => {
      if (newVal) {
        await setupObserverForReserve();
      }
    });

    const isBeforeNow = (dateStr, timeStr) => {
      if (!dateStr || !timeStr) return false;

      const scheduled = new Date(`${dateStr}T${timeStr}:00`);
      const now = new Date();

      return scheduled > now;
    };
    const deleteUsersFromKakaoQueue = async (at_id) => {
      try {
        const at_id_list = Array.isArray(at_id) ? at_id : [at_id]; // 항상 배열로 변환

        const response = await axios.delete('/attendance/delete_accounts_to_kakao_queue', {
          headers: {
            Authorization: `Bearer ${token.value}`
          },
          data: {
            kq_id: currentKqId.value,
            at_id_list
          }
        });

        if (response.data.result === true) {
          alert("사용자가 성공적으로 제거되었습니다.");
          // 시각적으로 리스트에서 제거
          reserveUserList.value = reserveUserList.value.filter(
            user => !at_id_list.includes(user.at_id)
          );

          // 개수도 다시 계산
          reserveUserListCount.value = reserveUserList.value.length;
        } else {
          alert("사용자 제거에 실패했습니다.");
        }
      } catch (error) {
        console.error('사용자 제거 중 오류 발생:', error);
        alert("요청 처리 중 오류가 발생했습니다.");
      }
    };

    onMounted(() => {
      isAuthenticated.value = store.state.userStore.isAuthenticated;
      if (!isAuthenticated.value) {
        router.push({ name: 'home' });
      } else {
        token.value = store.state.userStore.token;
      }

      window.addEventListener("keyup", (event) => {
        if (event.code === "Escape") {
          closeModals();
        }
      });
    });

    return {
      closeModals,
      userType,
      userInfo,
      getLanguage,
      selectAll,
      exceptNoShow,
      localAttendUserList,
      toggleSelectAll,
      isSelectAllChecked,
      isSelectAllPartial,
      getIconClass,
      sendMessageDate,
      masks_date,
      masks_time,
      rules,
      add_time,
      delete_time,
      createKakaoQueue,
      kakaoQueues,
      deleteKakaoQueue,
      fetchAttendUserList,
      searchKeyword,
      onSearchInput,
      // filteredAttendUserList,
      observerTargetUserList,
      attendListTotal,
      selectedUserCount,
      openUserList,
      reserveUserList,
      currentKqId,
      reserveUserListCount,
      setObserverRef,
      isBeforeNow,
      deleteUsersFromKakaoQueue

    };
  }
});
</script>

<template>
  <div id="operateAttendTableModal">
    <transition name="slide-fade">
      <div class="sidemodal" v-if="showMessageModal">
        <!-- 모달 상단 닫기 버튼 -->
        <div class="top">
          <p class="sub_text" @click="closeModals"><i class="icon-delete"></i></p>
        </div>

        <!-- 타이틀 -->
        <p class="size_26 b_700">메시지 전송 예약</p>
        <p class="description">메시지를 보낼 대상과 시간을 선택하세요.</p>

        <div class="cont_wrap">
          <!-- 대상 선택 섹션 -->
          <div class="cont">
            <p class="secondary_text b_700">보낼 대상</p>
            <div class="ds_flex dir_column gap12 w100">
              <!-- 전체 선택 / 불참 제외 -->
              <div class="ds_flex jus_between">
                <div class="ds_flex al_center">
                  <label for="selectAll" class="fake_checkbox"
                    :class="{ 'active': isSelectAllChecked || isSelectAllPartial }">
                    <i v-if="isSelectAllChecked" class="icon-checked"></i>
                    <i v-else-if="isSelectAllPartial" class="icon-minus"></i>
                  </label>
                  <input type="checkbox" id="selectAll" v-model="selectAll" @change="toggleSelectAll" />
                  <label class="function_name" for="selectAll">전체 선택</label>
                </div>
                <div class="ds_flex al_center gap4">
                  <label class="except_noshow" for="exceptNoShow" :class="{ 'active': exceptNoShow }">불참 제외</label>
                  <i v-if="exceptNoShow" class="icon-checked"></i>
                  <input type="checkbox" id="exceptNoShow" v-model="exceptNoShow" />
                </div>
              </div>

              <!-- 검색창 -->
              <p class="fake_textbox">
                <input type="text" class="flex6" placeholder="보낼 대상의 이름을 검색하세요." v-model="searchKeyword"
                  @keyup="onSearchInput()" />
                <i class="icon-search"></i>
              </p>
              <p class="size_12 secondary_text"><b class="main_text">{{ selectedUserCount }}</b>/{{ attendListTotal }}명
              </p>

              <!-- 참석자 목록 -->
              <div class="user_list ds_flex dir_column gap16">
                <template v-if="localAttendUserList.length === 0">
                  <p class="no_data size_14 secondary_text">검색된 사용자가 없습니다.</p>
                </template>
                <template v-else>
                  <div v-for="item in localAttendUserList" :key="item.at_id" class="ds_flex user_list_cont">
                    <label :for="'checkbox-' + item.at_id" class="fake_checkbox" :class="{ 'active': item.checked }">
                      <i v-if="item.checked" class="icon-checked"></i>
                    </label>
                    <input type="checkbox" :id="'checkbox-' + item.at_id" v-model="item.checked" />
                    <div>
                      <i v-if="item.code_name === 'NOSHOW'"
                        :class="`icon-${getIconClass(userType, 'attended', item.code_name)}`"></i>
                      <label class="function_name" :for="'checkbox-' + item.at_id">
                        {{ item.full_name }}
                        <span>(
                          {{ getLanguage(item.lang1) }}
                          <template v-if="item.lang2">, {{ getLanguage(item.lang2) }}</template>
                          )</span>
                      </label>
                      <p class="sub_text">{{ item.mobile }}</p>
                    </div>
                  </div>
                  <div ref="observerTargetUserList" style="height: 10px"></div>
                </template>
              </div>
            </div>
          </div>

          <!-- 예약된 시간 섹션 -->
          <div class="cont">
            <!-- 기존 예약된 시간 표시 -->
            <div class="ds_flex dir_column send_time_wrap gap8">
              <p class="secondary_text b_700">예약한 시간</p>
              <div class="send_time_cont reserve ds_flex al_center jus_between" v-for="(item, index) in kakaoQueues"
                :key="index">
                <div class="ds_flex al_center jus_between">
                  <p class="size_14 b_700 secondary_text">{{ item.scheduled_date }}</p>
                  <p class="size_14 b_700 secondary_text">{{ item.scheduled_time }}</p>
                </div>
                <!-- 시간 삭제 버튼 -->
                <p class="icon_box" @click="openUserList(item.kq_id)"><i class="icon-people sub_text"></i></p>
                <p class="icon_box" @click="deleteKakaoQueue(item.kq_id)"><i class="icon-trash sub_text"></i></p>
                <div v-show="currentKqId === item.kq_id" class="reserve_user_list w100">
                  <p class="size_14 b_600 secondary_text">보낼대상 <span class="main_text">{{ reserveUserListCount }}</span>
                  </p>
                  <div class="reserve_user_wrap"
                    :class="{ 'gap8': !isBeforeNow(item.scheduled_date, item.scheduled_time) }">
                    <div v-for="user in reserveUserList" :key="user.at_id" class="ds_flex jus_between al_center">
                      <p class="size_12">{{ user.full_name }} <span class="secondary_text">({{ getLanguage(user.lang1)
                          }}
                          <template v-if="user.lang2">, {{ getLanguage(user.lang2) }}</template>)</span></p>
                      <p class="icon_box" v-if="isBeforeNow(item.scheduled_date, item.scheduled_time)"
                        @click="deleteUsersFromKakaoQueue(user.at_id)">
                        <i class="icon-delete size_12"></i>
                      </p>
                    </div>
                    <div :ref="setObserverRef" :data-kqid="item.kq_id" style="height: 0;"></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 보낼 시간 설정 -->
            <div class="ds_flex dir_column send_time_wrap gap8">
              <p class="secondary_text b_700">보낼 시간</p>
              <div class="send_time_cont ds_flex al_center jus_between" v-for="(item, index) in sendMessageDate"
                :key="index">
                <div class="ds_flex al_center jus_between">
                  <!-- 날짜 선택 -->
                  <VDatePicker v-model.string="item.date" mode="date" :masks="masks_date">
                    <template #default="{ togglePopover }">
                      <p @click="togglePopover">{{ item.date ? item.date : 'YYYY-MM-DD' }}</p>
                    </template>
                  </VDatePicker>

                  <!-- 시간 선택 -->
                  <VDatePicker v-model.string="item.time" mode="time" is24hr :rules="rules" :masks="masks_time"
                    hide-time-header>
                    <template #default="{ togglePopover }">
                      <p class="time" @click="togglePopover">{{ item.time ? item.time : 'HH:MM' }}</p>
                    </template>
                  </VDatePicker>
                </div>
                <!-- 시간 삭제 버튼 -->
                <p class="icon_box" @click="delete_time(index)">
                  <i v-if="index > 0" class="icon-trash sub_text"></i>
                </p>
              </div>

              <!-- 시간 추가 버튼 -->
              <div class="add_time_table_body b_700" @click="add_time">+ 추가</div>
            </div>
          </div>
        </div>

        <!-- 하단 버튼 -->
        <div class="btn_wrap bottom">
          <p class="block_main_btn cancel_btn b_700" @click="closeModals">취소</p>
          <p class="block_main_btn active_btn b_700" @click="createKakaoQueue">저장하기</p>
        </div>
      </div>
    </transition>
  </div>
</template>